import { HandledError, handleQueryError } from '@/shared/utils/helpers/handle-query-error';
import { PasswordResetByPhoneConfirmFormError } from '../components/password-reset-by-phone-confirm-form/password-reset-by-phone-confirm-form';


export const handleResetPassByPhoneSmsError = (error?: HandledError): PasswordResetByPhoneConfirmFormError | undefined => {
  const queryError = handleQueryError(error);


  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;

    if (typeof detail === 'string') {
      const errors: PasswordResetByPhoneConfirmFormError = {};
      switch (detail) {
        default:
          errors.root = detail;
      }

      return errors;
    }

    if (Array.isArray(detail)) {
      const errors: PasswordResetByPhoneConfirmFormError = {};
      detail.forEach((err) => {
        const location = err.loc?.[1];
        const message = err.msg;

        switch (location) {
          case 'sms_code':
            errors.smsCode = message;
            break;
          default:
            errors.root = message;
        }
      });

      return errors;
    }
  }
};
