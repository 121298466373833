import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg, { AvailableIcon } from '@/shared/components/icon-svg/icon-svg';
import GradientIcon, { ColorName } from '@shared/components/gradient-icon/gradient-icon';
import { useAppSelector, useDispatchedActions } from '@shared/hooks';
import { getIsMenuOpened } from '@shared/store/app/selectors';
import {
  useDebounceValue,
  // useOnClickOutside,
  useScrollLock
} from 'usehooks-ts';
import { useEffect, useRef } from 'react';
import { checkIsMobileDevice, extractInitials, scrollToTop } from '@shared/utils/helpers/common';
import { getUserData } from '@/modules/user';
import SocialLinks from '@/widgets/core/components/social-links/social-links';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { getIsAuth, useLogoutMutation } from '@/modules/auth';
import { useTranslation } from 'react-i18next';
import { getNewNotificationsCount } from '@/modules/notifications';
import { AppFeature } from '@/app-config';
import Button from '@/shared/components/button/button';
import './sidebar-menu.scss';

type SidebarMenuProps = {
  className?: string;
}

const b = bemCn('sidebar-menu');

type SidebarItem = {
  id: string;
  i18nKey?: string;
  name: string;
  link: string;
  icon?: AvailableIcon;
  iconColor?: ColorName;
  disabled?: boolean;
}

const items: SidebarItem[] = [
  {
    // Для кенийцев
    id: 'home',
    i18nKey: 'main-nav.home',
    name: 'Главная',
    link: '/',
    icon: 'home',
    iconColor: 'blue',
    disabled: !AppFeature.KES_PROJECT,
  },
  {
    // Для кенийцев
    id: 'crash-games',
    i18nKey: '',
    name: 'Crash Games',
    link: '/crash-games',
    icon: 'rocket',
    iconColor: 'green',
    disabled: !AppFeature.KES_PROJECT,
  },
  {
    // Для кенийцев
    id: 'highlights',
    i18nKey: '',
    name: 'Highlights',
    link: '/highlights',
    icon: 'fireball',
    iconColor: 'light-blue',
    disabled: !AppFeature.KES_PROJECT || AppFeature.DISABLE_SPORTBOOK,
  },
  {
    id: 'live',
    i18nKey: 'main-nav.live',
    name: 'Live',
    link: '/live',
    icon: 'live',
    iconColor: 'purple',
    disabled: AppFeature.DISABLE_SPORTBOOK,
  },
  {
    id: 'line',
    i18nKey: 'main-nav.line',
    name: 'Линия',
    link: '/line',
    icon: 'desktop',
    disabled: AppFeature.DISABLE_SPORTBOOK,
  },
  {
    // Для кенийцев
    id: 'bonuses',
    i18nKey: '',
    name: 'Promotions',
    link: '/bonuses',
    icon: 'fire',
    iconColor: 'pink',
    disabled: !AppFeature.KES_PROJECT,
  },
  {
    id: 'casino',
    i18nKey: 'main-nav.casino',
    name: 'Казино',
    link: '/games',
    icon: 'coin',
    iconColor: 'green',
  },
  {
    id: 'results',
    i18nKey: 'main-nav.results',
    name: 'Результаты',
    link: '/results',
    icon: 'cup',
    iconColor: 'green',
    disabled: AppFeature.DISABLE_SPORTBOOK,
  },
  {
    // Для кенийцев
    id: 'aviator',
    i18nKey: '',
    name: 'Aviator',
    link: '/games/vegas/aviator_spribe',
    icon: 'aviator',
    iconColor: 'primary',
    disabled: !AppFeature.KES_PROJECT,
  },
  {
    // Для кенийцев
    id: 'download-app',
    i18nKey: '',
    name: 'Download app',
    link: '#',
    icon: 'iphone',
    iconColor: 'green',
    disabled: !AppFeature.KES_PROJECT,
  },
  {
    id: 'bet-history',
    i18nKey: 'profile.nav.bet-history',
    name: 'История ставок',
    link: '/bets-history',
    icon: 'coin-flat',
    iconColor: 'pink',
    disabled: AppFeature.DISABLE_SPORTBOOK,
  },
];

const SidebarMenu = ({ className }: SidebarMenuProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();
  const { setMenuOpened, logout, changeAppModal, changeAuthModal } = useDispatchedActions();
  const isAuth = useAppSelector(getIsAuth);
  const { name, id, kycVerification } = useAppSelector(getUserData);
  const isMenuOpened = useAppSelector(getIsMenuOpened);
  const [debouncedIsOpen] = useDebounceValue(isMenuOpened, 200);
  const [serverLogout] = useLogoutMutation();
  const newNotificationsCount = useAppSelector(getNewNotificationsCount);
  const menuRef = useRef<HTMLDivElement>(null);
  const isMobileDevice = checkIsMobileDevice();
  const showKyc = isAuth && AppFeature.KYC_VERIFICATION
    && isMobileDevice
    && kycVerification === false;

  useScrollLock({ autoLock: isMenuOpened, });

  // !!! отключил закрытие меню при клике в другие облати изза бага: при клике на кнопку меню оно переоткрывается.
  // useOnClickOutside(menuRef, () => {
  //   if (isMenuOpened) {
  //     setMenuOpened(false);
  //   }
  // });

  useEffect(() => {
    scrollToTop();
  }, [isMenuOpened]);

  if (!debouncedIsOpen || isDesktop) {
    return null;
  }

  const handleItemClick = () => setMenuOpened(false);

  const handleLogoutClick = async () => {
    await serverLogout();
    logout();
    setMenuOpened(false);
  };

  const handleLoginClick = () => {
    changeAuthModal('login');
    setMenuOpened(false);
  };

  const handleRegisterClick = () => {
    changeAuthModal('registration');
    setMenuOpened(false);
  };

  const handleKycClick = () => {
    changeAppModal('kyc-methods');
    setMenuOpened(false);
  };

  return (
    <div className={b({ 'fade-in': isMenuOpened, 'fade-out': !isMenuOpened }, className)} ref={menuRef}>

      {!isAuth && (
        <div className={b('auth-btns')}>
          <Button className={b('auth-btn')} variant="secondary" onClick={handleLoginClick}>
            {t('user-nav.login', 'Вход')}
          </Button>
          <Button className={b('auth-btn')} onClick={handleRegisterClick}>
            {t('user-nav.registration', 'Регистрация')}
          </Button>
        </div>
      )}

      {isAuth && (
        <div className={b('user')}>
          <Link className={b('user-info')} to="/profile" onClick={handleItemClick}>
            <p className={b('user-icon')}>{extractInitials(name ?? 'User')}</p>
            <div className={b('user-wrapper')}>
              <p className={b('user-label')}>
                {t('main-nav.profile', 'Личный кабинет')}
              </p>
              <p className={b('user-name')}>{name ?? `User ID: ${id}`}</p>
            </div>
            <IconSvg className={b('user-arrow')} name="chevron-right" />
          </Link>
        </div>
      )}

      {isAuth && (
        <div className={b('list')}>
          {/* <Link className={b('item')} to="/favorites" onClick={handleItemClick}>
          <GradientIcon className={b('item-icon')} iconName='star' color='transparent' />
          <span className={b('item-name')}>
            {t('common.favorite', 'Избранное')}
          </span>
          <IconSvg className={b('item-arrow')} name="chevron-right" />
        </Link> */}
          <Link className={b('item')} to="/profile/notifications" onClick={handleItemClick}>
            <GradientIcon className={b('item-icon')} iconName='bell' color='transparent' />
            <span className={b('item-name')}>
              {t('user-nav.notifications', 'Уведомления')}
            </span>
            {!!newNotificationsCount && (
              <span className={b('counter-number')}>{newNotificationsCount}</span>
            )}
            <IconSvg className={b('item-arrow')} name="chevron-right" />
          </Link>
          {showKyc && (
            <div className={b('item')} onClick={handleKycClick} >
              <GradientIcon className={b('item-icon')} iconName='user' color='transparent' />
              <span className={b('item-name')}>
                {t('kyc-methods-modal.title', 'KYC верификация')}
              </span>
              <IconSvg className={b('item-arrow')} name="chevron-right" />
            </div>
          )}
        </div>
      )}

      <div className={b('list')}>
        {items.filter((item) => !item.disabled).map((item) => (
          <Link key={item.id} className={b('item')} to={item.link} onClick={handleItemClick}>
            <GradientIcon className={b('item-icon')}
              iconName={item.icon}
              color={item.iconColor}
            />
            <span className={b('item-name')}>
              {item.i18nKey ? t(item.i18nKey, item.name) : item.name}
            </span>
            <IconSvg className={b('item-arrow')} name="chevron-right" />
          </Link>
        ))}


        {isAuth && (
          <button className={b('item')} type='button'
            onClick={handleLogoutClick}
          >
            <GradientIcon className={b('item-icon')} iconName='exit' color='transparent' />
            <span className={b('item-name')}>
              {t('user-nav.logout', 'Выйти')}
            </span>
            <IconSvg className={b('item-arrow')} name="chevron-right" />
          </button>
        )}
      </div>

      <SocialLinks className={b('social')} />
    </div>
  );
};


export default SidebarMenu;
