import React from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';

import './notification-text.scss';

const b = bemCn('notification-text');
const pattern = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/gm;

const renderLink = (word: string) => {
  const url = word.match(pattern)?.[0];
  const punctuation = word.slice(url?.length);

  return (
    <React.Fragment key={word}>
      <a
        href={url}
        className={b('link')}
        target="_blank"
        rel="noreferrer"
      >
        {url}
      </a>
      {punctuation}
      &nbsp;
    </React.Fragment>
  );
};

type Props = {
  text: string;
};

const NotificationText = ({ text }: Props) => {
  const words = text.split(' ');

  return (
    <>
      {words.map((word) => word.includes('http') ? renderLink(word) : `${word} `)}
    </>
  );
};

export default NotificationText;
