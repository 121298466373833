import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { PropsWithCN } from '@shared/types/common';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import { useAppSelector } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';
import Button from '@/shared/components/button/button';
import CustomDatePicker from '@/shared/components/custom-datepicker/custom-datepicker';

import './results-controls-mobile.scss';

import type { Dayjs } from 'dayjs';

const b = bemCn('resutls-controls-mobile');

type ControlsActiveType = 'today' | 'yesterday' | 'calendar' | 'clock';

type ResultsControlsMobileProps = PropsWithCN & {
  onFavoriteClick?: () => void;
  onDateChange?: (date: Dayjs | 0) => void;
}

const ResultsControlsMobile = ({
  className,
  onFavoriteClick,
  onDateChange,
}: ResultsControlsMobileProps) => {
  const { t } = useTranslation();
  const isAuth = useAppSelector(getIsAuth);
  const [active, setActive] = useState<ControlsActiveType>('today');
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleClick = (selectedActive: ControlsActiveType, date: Dayjs | 0) => {
    if (selectedActive !== active) {
      setActive(selectedActive);
    }

    if (date === 0) {
      setSelectedDate(new Date());
    } else {
      setSelectedDate(new Date(date.toISOString()));
    }

    onDateChange?.(date);
  };

  return (
    <div className={b(null, className)}>
      <div className={b('wrapper')}>
        <Button className={b('btn-text')}
          type="button"
          variant={active === 'today' ? 'primary' : 'secondary'}
          onClick={() => handleClick('today', 0)}
        >
          {t('results.today', 'Сегодня')}
        </Button>
        <Button className={b('btn-text')}
          type="button"
          variant={active === 'yesterday' ? 'primary' : 'secondary'}
          onClick={() => handleClick('yesterday', dayjsCustom().subtract(1, 'day'))}
        >
          {t('results.yesterday', 'Вчера')}
        </Button>
        <CustomDatePicker
          onDateChange={(date) => handleClick('calendar', date)}
          btnClassName={b('btn-text')}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          variant={active === 'calendar' ? 'primary' : 'secondary'}
          isHideValue
          icon="calendar"
          isPortal
        />
        <ButtonIcon className={b('btn')}
          icon="clock"
          variant="secondary"
        />
        <ButtonIcon className={b('btn')}
          icon="heart"
          variant="secondary"
          onClick={onFavoriteClick}
          to="/results/favorites"
          disabled={!isAuth}
        />
      </div>
    </div>
  );
};

export default ResultsControlsMobile;
