import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '../icon-svg/icon-svg';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import './system-warning.scss';

type SystemWarningProps = {
  className?: string;
}

const b = bemCn('system-warning');

const SystemWarning = ({ className }: SystemWarningProps) => {
  const { data, isLoading } = useGetStatusQuery();
  const isEnabled = data?.statusMessage.enable;
  const message = data?.statusMessage.message;

  if (isLoading || !isEnabled || !message) {
    return null;
  }

  return (
    <div className={b(null, className)}>
      <IconSvg className={b('icon')} name='bell' />
      <p className={b('message')}>
        {message}
      </p>
    </div>
  );
};

export default SystemWarning;
