import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { PhoneSelectorValue } from '@/shared/components/phone-selector/phone-selector';
import * as yup from 'yup';
import i18n from '@/shared/i18n/i18n';
import { defaultCountryCode } from '@/shared/utils/constants/phone';
import { useTranslation } from 'react-i18next';
import Form from '@/shared/components/form/form';
import { FC } from 'react';
import Button from '@/shared/components/button/button';
import { SUPPORT_LINK } from '@/app-config';
import './password-reset-by-phone-form.scss';

const b = bemCn('password-reset-by-phone-form');

export type FormData = {
  phone: Omit<PhoneSelectorValue, 'phoneCode'>;
}

export type PasswordResetByPhoneFormError = {
  root?: string;
  phone?: string;
}

const phoneRegExp = /^\+?\d{6,24}$/;

const schema = yup
  .object({
    phone: yup.object({
      phone: yup.string()
        .required(i18n.t('reg.modal.errors.empty-phone', 'Введите номер телефона'))
        .matches(phoneRegExp, i18n.t('reg.modal.errors.wrong-phone', 'Введите корректный телефон')),
      countryCode: yup.string()
        .required('Введите код страны'),
    }).required(),
  })
  .required();


const defaultValues: FormData = {
  phone: {
    phone: `+${defaultCountryCode.phoneCode}`,
    countryCode: defaultCountryCode.countryCode,
  },
};

type Props = {
  classNName?: string;
  onSubmit?: (data: FormData) => void;
  isLoading?: boolean;
  error?: PasswordResetByPhoneFormError;
}

const PasswordResetByPhoneForm: FC<Props> = ({
  classNName,
  onSubmit,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <Form<FormData> className={b(null, classNName)}
      onSubmit={onSubmit}
      form={{ defaultValues, schema, error }}
    >
      <Form.RootError className={b('error')} />
      <Form.PhoneSelector
        className={b('field')}
        name="phone"
        disabled={isLoading}
      />
      <Button className={b('button', { forget: true })}
        variant="text"
      >
        <a href={SUPPORT_LINK} target="_blank" rel="noreferrer" >
          {t('reset-pass.cant-reset-pass', 'Не могу восстановить пароль')}
        </a>
      </Button>
      <Button className={b('button')}
        type="submit"
        disabled={isLoading}
      >
        {t('reset-pass.send-request', 'Отправить')}
      </Button>
    </Form>
  );
};

export default PasswordResetByPhoneForm;
