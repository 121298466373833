import { useAppSelector } from '@shared/hooks';
import { selectTaxes } from '@shared/store/app/selectors';
import { roundToCent } from '../utils/helpers/common';

export type BettingTaxInfo = {
  exciseTax: number;
  possibleWin: number;
  winnings: number;
  withHoldingTax: number;
  finalPay: number;
  stakeAfterTax: number;
}

// Старые значения, могут пригодиться для тестов
// const EXCISE_TAX_PERCENT = 13.04; // налог в процентах
// const EXCISE_TAX = EXCISE_TAX_PERCENT / 100; // налог в коэффиценте
// const WITHHOLDING_TAX_RATE = 0.2;

export const useBettingTaxInfo = (value: number, coef: number): BettingTaxInfo => {
  // Значения, которые пришли с сервера
  const taxesServer = useAppSelector(selectTaxes);
  const EXCISE_TAX = taxesServer?.excise ?? 0; // налог в коэффиценте
  const WITHHOLDING_TAX_RATE = taxesServer?.withhold ?? 0;

  // Акцизный налог (Excise Tax)
  const exciseTax = roundToCent(value * EXCISE_TAX); // % от ставки
  const stakeAfterTax = roundToCent(value * (1 - EXCISE_TAX)); // ставка после вычета акциза

  // Налог на выигрыш (Withholding Tax)
  const possibleWin = roundToCent(stakeAfterTax * coef); // возможный выигрыш (до вычета налогов)
  const winnings = roundToCent(possibleWin - stakeAfterTax); // чистая прибыль
  const withHoldingTax = roundToCent(winnings * WITHHOLDING_TAX_RATE); // 20% налог на прибыль
  const finalPay = roundToCent(possibleWin - withHoldingTax); // итоговая выплата

  return ({
    exciseTax,
    stakeAfterTax,
    possibleWin,
    winnings,
    withHoldingTax,
    finalPay
  });
};
