import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { type Wallet } from '@/modules/user/@x/user-wallet';
import { roundNumber } from '@/shared/utils/helpers/common';
import { useState } from 'react';
import ExtraWalletManageModal from '../../extra-wallet-manage-modal/extra-wallet-manage-modal';
import './extra-wallet-manage-item.scss';

const b = bemCn('extra-wallet-manage-item');

type ExtraWalletManageItemProps = {
  className?: string;
  wallet: Wallet & {
    label: string;
    symbol: string;
  };
}

const ExtraWalletManageItem = ({
  className,
  wallet
}: ExtraWalletManageItemProps) => {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <>
      {/* <button className={b(null, className)} */}
      <div className={b(null, className)}
        key={wallet.currency}
      // type='button'
      // onClick={() => setModalOpened(true)}
      >
        <span className={b('icon')}>{wallet.symbol}</span>
        <p className={b('title')}>{wallet.label}</p>
        <p className={b('amount')}>
          {roundNumber(wallet.amount).toFixed(2)}
        </p>
        {/* <button className={b('wallet-topup-btn')}>+</button> */}
        {/* </button> */}
      </div>
      <ExtraWalletManageModal isOpen={modalOpened} onClose={() => setModalOpened(false)}
        wallet={wallet}
      />
    </>
  );
};

export default ExtraWalletManageItem;
