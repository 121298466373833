import type { UserData, Wallet } from '@/modules/user/@x/profile';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { extractInitials, roundNumber } from '@shared/utils/helpers/common';
// import Button from '@shared/components/button/button';
import { FC } from 'react';
import { MainWalletInfo } from '@/modules/user-wallet';
// import { useDispatchedActions } from '@/shared/hooks';
// import { useTranslation } from 'react-i18next';
import { useThemeContext } from '@/modules/theme/@x/profile';
import './profile-card.scss';

const b = bemCn('profile-card');

type ProfileCardProps = {
  className?: string;
  userData: UserData & {
    wallet: Wallet;
  };
  extraWallets: Array<Wallet & {
    label: string;
    symbol: string;
  }>;
}

const ProfileCard: FC<ProfileCardProps> = ({
  className,
  extraWallets,
  userData,
}) => {
  const { theme } = useThemeContext();
  // const { t } = useTranslation();
  const { name, id, wallet, } = userData;
  const {
    currency,
    amount,
    bonusAmount,
    cashbackLine,
    cashbackCasino,
    casinoBonus,
    wagerScaleCurrentAmount,
    wagerScaleTargetAmount,
  } = wallet;

  const isLightTheme = theme === 'light-blue';

  // const { changeAppModal } = useDispatchedActions();

  return (
    <div className={b(null, className)}>
      <div className={b('user-info')}>
        <p className={b('user-icon')}>{extractInitials(name ?? 'User')}</p>
        <p className={b('user-name')}>{name ?? `User ID: ${id}`}</p>
        <p className={b('user-id')}>ID: {id}</p>
      </div>
      <MainWalletInfo className={b('main-wallet-info')}
        mainAmount={amount}
        bonusAmount={bonusAmount}
        cashbackLine={cashbackLine}
        cashbackCasino={cashbackCasino}
        casinoBonus={casinoBonus}
        wagerAmount={`${wagerScaleCurrentAmount}/${wagerScaleTargetAmount}`}
        currency={currency}
        style={isLightTheme ? 'light' : undefined}
      />
      {extraWallets.length > 0 && (
        <div className={b('wallets')}>
          {extraWallets.map((item) => (
            <div className={b('wallet')} key={item.currency}>
              <span className={b('wallet-icon')}>{item.symbol}</span>
              <p className={b('wallet-title')}>{item.label}</p>
              <p className={b('wallet-amount')}>
                {roundNumber(item.amount).toFixed(2)}
              </p>
              {/* <button className={b('wallet-topup-btn')}>+</button> */}
            </div>
          ))}
        </div>
      )}
      {/* <Button className={b('manage-btn')} onClick={() => changeAppModal('wallet-manage')}>
        {t('user-nav.wallets-manage', 'Управление счетами')}
      </Button> */}
    </div>
  );
};

export default ProfileCard;
