import { useEffect, useState } from 'react';
import Loader from '@shared/components/loader/loader';
import { useParams } from 'react-router-dom';
import { useScrollToTop } from '@shared/hooks/use-scrolltotop';
import { useAddToBasketMutation } from '@/modules/basket';
import { getBasketCount } from '@/modules/basket';
import { useDispatchedActions, useAppSelector } from '@shared/hooks';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { EventCardBig } from '@/modules/line';
import { useGetBetapiEventQuery } from '../model/betapi';
import { getEventDetailed } from '../model/selectors';
import { DataType } from '../model/types';
import { useTranslation } from 'react-i18next';

import type { TEventBetItem } from '../model/line';
import { isoToTimestamp } from '@/shared/utils/helpers/date';

type EventCardBigContainerProps = {
  className?: string;
  dataType: DataType;
}

const EventCardBigContainer = ({
  className,
  dataType,
}: EventCardBigContainerProps) => {
  useScrollToTop();
  const { i18n: { resolvedLanguage: dataLang } } = useTranslation();
  const { eventId = '' } = useParams();
  const [subgameId, setSubgameId] = useState<string>(eventId);
  const [isFetching, setFetchin] = useState(false);
  const [addToBasket] = useAddToBasketMutation();

  const { isMobile } = useResponsive();
  const { changeAppModal } = useDispatchedActions();
  const basketCount = useAppSelector(getBasketCount);

  const maingameParams = { gameId: eventId, dataType, dataLang };
  const sugameParams = { gameId: subgameId, dataType, dataLang };
  const pollingInterval = dataType === 'live' ? 4000 : 0;

  const { isLoading: isMaingameLoading } =
    useGetBetapiEventQuery(maingameParams, { pollingInterval });

  const { isFetching: isSubgameFetching, } =
    useGetBetapiEventQuery(sugameParams, {
      skip: eventId === subgameId || !subgameId,
      pollingInterval,
    });

  const event = useAppSelector(getEventDetailed(maingameParams, sugameParams));

  useEffect(() => {
    if (!isSubgameFetching) {
      setFetchin(false);
    }
  }, [isSubgameFetching]);

  const handleSubgameChange = (nextSubgameId: string) => {
    setSubgameId(nextSubgameId);
    if (nextSubgameId !== eventId) {
      setFetchin(true);
    }
  };

  const handleBetCLick = async (bet: TEventBetItem) => {
    if (!event) {
      return;
    }

    await addToBasket({
      lang: dataLang,
      dataType,
      betId: bet.id,
      gameId: Number(event.id),
      gameMid: Number(event.mainId),
      eventDate: isoToTimestamp(event.startDate),
      team1: event.team1.name,
      team2: event.team2.name,
      subGameName: event.gameDopName,
      subGameNameRu: event.gameDopNameRu,
      tournamentName: event.tournament.name,
      groupName: bet.groupName,
      outcomeName: bet.name,
      sportId: Number(event.sport.id),
      sportName: event.sport.name,
      sportNameRu: event.sport.nameRu ?? '',
      rate: bet.rate,
    });

    if (isMobile && basketCount === 0) {
      changeAppModal('basket');
    }
  };

  if (isMaingameLoading) {
    return <Loader />;
  }

  if (!event) {
    return null;
  }

  return (
    <EventCardBig
      className={className}
      event={event}
      onSubgameChange={handleSubgameChange}
      isBesLoading={isFetching}
      activeSugbameId={subgameId}
      onBetCLick={handleBetCLick}
      currentEventId={eventId}
    />
  );
};

export default EventCardBigContainer;
