import { AVAILABLE_THEMES, DEFAULT_THEME } from '@/app-config';

const themeStorageKey = 'theme-color';

export const saveTheme = (theme: string) => {
  localStorage.setItem(themeStorageKey, theme);
};

export const getTheme = () => {
  const theme = localStorage.getItem(themeStorageKey);
  const isThemeValid = !!theme && AVAILABLE_THEMES.includes(theme);

  if (!isThemeValid) {
    saveTheme(DEFAULT_THEME);
    return DEFAULT_THEME;
  }

  return theme;
};
