import { forwardRef } from 'react';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import DatePicker, { registerLocale } from 'react-datepicker';
import DatePickerBtn from './datepicker-btn';
import { ru } from 'date-fns/locale/ru';
import { uk } from 'date-fns/locale/uk';
import { kk } from 'date-fns/locale/kk';
import { az } from 'date-fns/locale/az';
import { uz } from 'date-fns/locale/uz';
import { da } from 'date-fns/locale/da';
import { es } from 'date-fns/locale/es';
import { pt } from 'date-fns/locale/pt';

import 'react-datepicker/dist/react-datepicker.css';
import './custom-datepicker.scss';

import type { Dayjs } from 'dayjs';
import type { AvailableIcon } from '../icon-svg/icon-svg';
import { useTranslation } from 'react-i18next';

registerLocale('ru', ru);
registerLocale('uk', uk);
registerLocale('kk', kk);
registerLocale('az', az);
registerLocale('uz', uz);
registerLocale('da', da);
registerLocale('es', es);
registerLocale('pt', pt);

type CustomDatePickerProps = {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  onDateChange?: (date: Dayjs | 0) => void;
  btnClassName?: string;
  variant?: 'primary' | 'secondary' | 'bordered' | 'text';
  isHideValue?: boolean;
  icon?: AvailableIcon;
  isPortal?: boolean;
}

const CustomDatePicker = (props: CustomDatePickerProps) => {
  const {
    selectedDate,
    setSelectedDate,
    onDateChange,
    btnClassName,
    variant = 'secondary',
    isHideValue,
    icon,
    isPortal,
  } = props;
  const { i18n } = useTranslation();

  const CustomInput = forwardRef(
    DatePickerBtn,
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onDateChange?.(dayjsCustom(date));
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      customInput={
        <CustomInput
          className={btnClassName}
          value={selectedDate?.toString()}
          isHideValue={isHideValue}
          onClick={() => { }}
          variant={variant}
          icon={icon}
        />
      }
      dateFormat={'dd/MM/yyyy'}
      locale={i18n.resolvedLanguage}
      calendarStartDay={1}
      maxDate={new Date()}
      withPortal={isPortal}
    />
  );
};

export default CustomDatePicker;
