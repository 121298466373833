import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getTimezoneNumber } from './timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

// TODO: Перенести в shared так как используется и в modules и в widgets
export const dayjsCustom = (date?: dayjs.ConfigType, tz?: number) =>
  dayjs(date).utcOffset(tz ?? getTimezoneNumber());

export const dayjsFromUnix = (date: number, tz?: number) =>
  dayjs.unix(date).utcOffset(tz ?? getTimezoneNumber());

export const dayjsToUnix = (date: dayjs.Dayjs): number =>
  dayjs(date).unix();

// Предыдущая реализация с учётом таймзон
// export const transformDateFilter = (date?: dayjs.ConfigType) => {
//   const dateFrom = dayjs(date).startOf('day').utcOffset(getTimezoneNumber(), true).toISOString();
//   const dateTo = dayjs(date).endOf('day').utcOffset(getTimezoneNumber(), true).toISOString();

//   return { dateFrom, dateTo };
// };

export const transformDateFilter = (date?: dayjs.ConfigType) => {
  const dateFrom = dayjs(date).format('YYYY-MM-DD');
  const dateTo = dayjs(date).format('YYYY-MM-DD');

  return { dateFrom, dateTo };
};
