import { FC } from 'react';
import Container from '@shared/components/container/container';
import { bemCn } from '@shared/utils/helpers/bem-cn';

import Loader from '@/shared/components/loader/loader';
import './game-screen.scss';

const b = bemCn('game-screen');

type GameScreenProps = {
  src?: string;
  title?: string;
  isError?: boolean;
  errorMessage?: string;
  loading?: boolean;
  scrolling?: 'auto' | 'no' | 'yes';
}

const GameScreen: FC<GameScreenProps> = ({
  src,
  title,
  isError,
  errorMessage,
  loading,
  scrolling = 'no',
}) => (
  <div className={b({ loading })}>
    <Container className={b('container')}>
      {isError && errorMessage && (
        <div className={b('error-message')}>{errorMessage}</div>
      )}
      {loading && (
        <Loader className={b('loader')} />
      )}
      <iframe className={b('frame')}
        title={title}
        scrolling={scrolling}
        allow="fullscreen"
        frameBorder="0"
        src={src}
      />
    </Container>
  </div>
);

export default GameScreen;
