import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { KYCMethod } from '@/shared/store/app/types';
import AppModal from '@/shared/components/app-modal/app-modal';
import * as yup from 'yup';
import i18n from '@/shared/i18n/i18n';
import CameraInput from '@/shared/components/CameraInput/CameraInput';
import Button from '@/shared/components/button/button';
import { useUploadKycMutation } from '@/modules/user';
import Form from '@/shared/components/form/form';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './kyc-modal.scss';

const b = bemCn('kyc-modal');

type FormData = {
  file: File;
};

const defaultValues = {} as FormData;

const schema = yup.object({
  file: yup
    .mixed<File>()
    .required(`${i18n.t('kyc-fyle-modal.errors.require-file')}`)
    .test('fileSize', i18n.t('kyc-fyle-modal.errors.file-size'), (file: File) =>
      file && file.size <= 30 * 1024 * 1024 * 1024
    )
    .test('fileType', 'Допустимые форматы: jpeg, PNG, MP4, WEBM', (file) =>
      file && (
        file.type === 'image/jpeg'
        || file.type === 'image/png'
        || file.type === 'video/mp4'
        || file.type === 'video/webm'
      )
    ),
});

type KYCModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  method: KYCMethod | null;
}

const KYCModal: FC<KYCModalProps> = ({ isOpen, onClose, method }) => {
  const { t } = useTranslation();
  const [upload, {
    isSuccess,
    isLoading,
  }] = useUploadKycMutation();

  const onSubmit = (data: FormData) => {
    if (data.file) {
      const body = new FormData();
      body.append('file', data.file);

      if (method) {
        upload({
          method: method?.name,
          body: body
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onClose?.();
    }
  }, [isSuccess]);

  if (!method) {
    return null;
  }
  const methodMessage = method?.messages
    .find((item) => item.lang.toLowerCase() === i18n.resolvedLanguage
      || item.lang.toLowerCase() === 'en')?.text;


  return (
    <AppModal className={b()}
      isOpen={isOpen}
      onClose={onClose}
      title={`${method.name}`}
      description={methodMessage}
      classNameTitle={b('title')}
      classNameDesc={b('desc')}
    >
      <Form<FormData>
        onSubmit={onSubmit}
        form={{
          defaultValues,
          schema,
          disabled: isLoading,
        }}
      >
        <Form.RootError className={b('error')} />
        <CameraInput<FormData>
          className={b('input')}
          type="file"
          name="file"
          mediaType={method.mediaType}
          disabled={isLoading}
        />
        <Button className={b('button')} type='submit' loading={isLoading}>
          {t('kyc-fyle-modal.send-fyle')}
        </Button>
      </Form>

    </AppModal>
  );
};

export default KYCModal;
