import { useGetPromocodesQuery } from '@/modules/user';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { memo, useMemo, useState } from 'react';
import type { GetPromocodesParams } from '@/modules/user';
import Container from '@shared/components/container/container';
import { Link } from 'react-router-dom';
import { PromocodeCard } from '@/modules/promocodes';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import Button from '@shared/components/button/button';
import Loader from '@/shared/components/loader/loader';
import { useTranslation } from 'react-i18next';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import { EmptyPlaceholder } from '@/shared/components/empty-placeholder/empty-placeholder';
import './promocodes-screen.scss';

const MemoizedPromocodeCard = memo(PromocodeCard);

const b = bemCn('promocodes-screen');

const PAGE_SIZE = 15;

const initParams: GetPromocodesParams = {
  pageSize: PAGE_SIZE,
  page: 1,
  ascending: false
};

const PromocodesScreen = () => {
  useScrollToTop();
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);
  const { data, isLoading, isFetching } =
    useGetPromocodesQuery(params);

  const handleShowMore = () => {
    setParams((prev) => ({
      ...prev,
      pageSize: prev.pageSize && prev.pageSize + PAGE_SIZE,
    }));
  };

  const isBtnShow = Number(data?.count) >= Number(params.pageSize);
  const isEmpty = !isFetching && Number(data?.count) <= 0;

  const memoizedPromocodes = useMemo(() => data?.items.map((item) => (
    <MemoizedPromocodeCard
      key={item.name + item.usedDate}
      promocode={item}
      inverted
    />
  )), [data?.items]);

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        <div className={b('header')}>
          <h1 className={b('title')}>
            {t('profile.nav.promo-codes', 'Промокоды')}
          </h1>
          <Link className={b('header-link')} to="/profile/promocodes/activate">
            {t('promocodes.activate.title', 'Активация промокода')}
          </Link>
        </div>
        {isLoading && <div className={b('loading')}><Loader /></div>}
        {!isLoading && memoizedPromocodes && memoizedPromocodes?.length > 0 && (
          <div className={b('list')}>
            {memoizedPromocodes}
          </div>
        )}
        {isEmpty && (
          <EmptyPlaceholder className={b('empty')}
            title={t('empty-message.promocodes.title')}
            description={t('empty-message.promocodes.desc')}
          />
        )}
        {isBtnShow && (
          <Button className={b('show-more')} variant='secondary' onClick={handleShowMore}>
            {t('common.load-more', 'Загрузить ещё')}
          </Button>
        )}
      </Container>
    </div>
  );

};


export default PromocodesScreen;
