import i18n from '@/shared/i18n/i18n';
import { type IWithdrawalError } from '@/modules/user/@x/user-wallet';
import { type HandledError, handleQueryError } from '@/shared/utils/helpers/handle-query-error';

export const handleWithdrawalError = (error?: HandledError): IWithdrawalError | undefined => {
  const queryError = handleQueryError(error);
  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;

    if (typeof detail === 'string') {
      const errors: IWithdrawalError = {};
      switch (detail) {
        case 'Not enough money':
          errors.withdrawal = i18n.t('withdrawals.modal.errors.Not enough money', 'Недостаточно средств.');
          break;
        case 'Withdrawal method not supported':
          errors.withdrawal = i18n.t('withdrawals.modal.errors.Withdrawal method not supported', 'Метод вывода не поддерживается.');
          break;
        case 'Withdrawal method not active':
          errors.withdrawal = i18n.t('withdrawals.modal.errors.Withdrawal method not active', 'Метод вывода не активен.');
          break;
        case 'Currency not supported':
          errors.withdrawal = i18n.t('withdrawals.modal.errors.Currency not supported', 'Валюта не поддерживается.');
          break;
        case 'Broken limits':
          errors.withdrawal = i18n.t('withdrawals.modal.errors.Broken limits', 'Превышен лимит вывода.');
          break;
        case 'Withdrawal limits count exceeded for the current period':
          errors.withdrawal = i18n.t('withdrawals.modal.errors.Withdrawal limits count exceeded for the current period', 'Вы исчерпали лимит на кол-во заявок.');
          break;
        case 'Withdrawal limits ammount exceeded for the current period':
          errors.withdrawal = i18n.t('withdrawals.modal.errors.Withdrawal limits ammount exceeded for the current period', 'Вы исчерпали лимит на вывод средств.');
          break;
        case 'Withdrawal limits are set as a ban on any withdrawal':
          errors.withdrawal = i18n.t('withdrawals.modal.errors.Withdrawal limits are set as a ban on any withdrawal', 'Вы превысили лимит на вывод средств, попробуйте позднее.');
          break;
        default:
          errors.root = detail;
      }

      return errors;
    }

    if (Array.isArray(detail)) {
      const errors: IWithdrawalError = {};
      detail.forEach((err) => {
        const location = err.loc?.[1];
        const message = err.msg;
        switch (location) {
          case 'login':
            errors.withdrawal = message;
            break;
          default:
            errors.root = message;
        }
      });

      return errors;
    }

    return {
      root: 'Unknown error',
    };
  }
};
