import GameScreen from '../game-screen';
import { useParams } from 'react-router-dom';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { SiteMaintenance, Maintenance } from '@/widgets/site-maintenance';
import { useTranslation } from 'react-i18next';
import { useCreateRplSessionQuery } from '@/modules/casino';
import { DEFAULT_LANG } from '@/app-config';


const RplGames = () => {
  const { t, i18n } = useTranslation();
  const { data: appStatuses } = useGetStatusQuery();
  const isInbetActive = appStatuses?.inbet ?? false;
  const { gameId = '' } = useParams();
  const { data: rplSession, isLoading, isError } = useCreateRplSessionQuery(undefined, { skip: !isInbetActive });
  const customerId = rplSession?.customerId ?? '';
  const session = rplSession?.session ?? '';
  const currency = rplSession?.currency ?? '';
  const lang = i18n.resolvedLanguage ?? DEFAULT_LANG;

  const sessionUrl = `/rplgames.html?customer_id=${customerId}&session=${session}&game_id=${gameId}&currency=${currency}&lang=&lang=${lang}`;

  if (!isInbetActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <GameScreen
      title="Rpl Games"
      src={sessionUrl}
      loading={isLoading}
      isError={isError}
      errorMessage={t('common.unknown-error', 'Неизвестная ошибка')}
    />
  );
};

export default RplGames;
