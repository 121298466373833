import { bemCn } from '@shared/utils/helpers/bem-cn';
import { bonusessListByLang } from './bonuses-list';
import { Link } from 'react-router-dom';
import Container from '@shared/components/container/container';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '@/app-config';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import { EmptyPlaceholder } from '@/shared/components/empty-placeholder/empty-placeholder';
import './bonuses-screen.scss';

const b = bemCn('bonuses-screen');

const BonusesScreen = () => {
  useScrollToTop();
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage ?? DEFAULT_LANG;
  const bonuses = bonusessListByLang[lang.toLowerCase()] ?? bonusessListByLang['default'];

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs />
        <h1 className={b('title')}>
          {t('footer-nav.item.Bonus and actions', 'Бонусы и Акции')}
        </h1>
        {bonuses.length <= 0 && (
          <EmptyPlaceholder className={b('empty')}
            title={t('empty-message.bonuses.title')}
            description={t('empty-message.bonuses.desc')}
          />
        )}
        {bonuses.length > 0 && (
          <div className={b('list')}>
            {bonuses.map((item) => (
              <Link className={b('item')} key={item.id} to={item.id}>
                <div className={b('item-info')}>
                  <h2 className={b('item-title')}>{item.title}</h2>
                  <p className={b('item-desc')}>{item.description}</p>
                </div>
                {item.preview && (
                  <img className={b('item-preview')}
                    src={item.preview}
                    alt=""
                  />
                )}
              </Link>
            ))}
          </div>
        )}
      </Container>
    </div>
  );
};

export default BonusesScreen;
