import { createSelector } from '@reduxjs/toolkit';
import basketApi from '../api/basket-api';
import { getPrimaryWallet } from '../../user/store/selectors';
import { DEFAULT_CURRENCY } from '@/app-config';

// const getAPiBasket = (state: State) => {
//   const { data = [] } = basketApi.endpoints.getBasket.select()(state);

//   return data ?? [];
// };

export const getBasketList = createSelector(
  basketApi.endpoints.getBasket.select(),
  ({ data = [] }) => data,
);

export const getBasketItemsIds = createSelector(
  getBasketList,
  (basketItems) => basketItems.map((item) => item.id),
);

export const getBasketCount = createSelector(
  getBasketItemsIds,
  (items) => items.length,
);

export const getBasket = createSelector(
  getBasketList,
  getPrimaryWallet,
  (basketItems, primaryWallet) => {
    const totalRate = basketItems.reduce((acc, bet) => acc * bet.rate, 1);
    const isEmpty = basketItems.length === 0;

    return {
      items: basketItems,
      isEmpty,
      totalRate,
      currency: primaryWallet?.currency ?? DEFAULT_CURRENCY,
      minBet: 1,
      maxBet: 100000,
      walletAmount: primaryWallet?.amount ?? 0,
      bonusAmount: primaryWallet?.bonusAmount ?? 0,
    };
  }
);

export const getBasketPlaceError = () => 'Неизвестная ошибка постановки ставки';
