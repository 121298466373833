import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { useResultsFilterContext } from '../../model/results-filter-context';
import Button from '@/shared/components/button/button';
import CustomDatePicker from '@/shared/components/custom-datepicker/custom-datepicker';

import './results-filter-desktop.scss';

import type { Dayjs } from 'dayjs';

const b = bemCn('results-filter-desktop');

type ControlsActiveType = 'today' | 'yesterday' | 'calendar' | 'clock';

const ResultsSportControls = () => {
  const { t } = useTranslation();
  const { onDateChange } = useResultsFilterContext();
  const [active, setActive] = useState<ControlsActiveType>('today');
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleClick = (selectedActive: ControlsActiveType, date: Dayjs | 0) => {
    if (selectedActive !== active) {
      setActive(selectedActive);
    }

    if (date === 0) {
      setSelectedDate(new Date());
    } else {
      setSelectedDate(new Date(date.toISOString()));
    }

    onDateChange?.(date);
  };

  return (
    <div className={b('sport-btn', b('sport-controls'))}>
      <Button className={b('sport-controls-btn')}
        type="button"
        onClick={() => handleClick('today', 0)}
        variant={active === 'today' ? 'primary' : 'secondary'}
      >
        {t('results.today', 'Сегодня')}
      </Button>
      <Button className={b('sport-controls-btn')}
        type="button"
        variant={active === 'yesterday' ? 'primary' : 'secondary'}
        onClick={() => handleClick('yesterday', dayjsCustom().subtract(1, 'day'))}
      >
        {t('results.yesterday', 'Вчера')}
      </Button>
      <CustomDatePicker
        onDateChange={(date) => handleClick('calendar', date)}
        btnClassName={b('sport-controls-btn')}
        variant={active === 'calendar' ? 'primary' : 'secondary'}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </div>
  );
};

export default ResultsSportControls;
