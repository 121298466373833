import { useState } from 'react';
import { useGetWithdrawalMethodsQuery } from '@shared/store/app/app-api';
import { getWithdrawalMethods } from '@shared/store/app/selectors';
import { getUserModal, getUserData } from '@/modules/user/@x/user-wallet';
import { useAppSelector, useDispatchedActions } from '@/shared/hooks';
import type { WithdrawalMethodsItem } from '@/shared/store/app/types';

export const useWithdrawalMethods = () => {
  const [selectedMethod, setSelectedMethod] = useState<WithdrawalMethodsItem | null>(null);
  useGetWithdrawalMethodsQuery();
  const methods = useAppSelector(getWithdrawalMethods);
  const { id } = useAppSelector(getUserData);
  const activeModal = useAppSelector(getUserModal);
  const isListEmpty = methods.length === 0;
  const { changeUserModal, closeUserModal, closeAppModal } = useDispatchedActions();

  const handleMethodClick = (method: WithdrawalMethodsItem) => {
    setSelectedMethod(method);
    changeUserModal('withdrawal-modal');
  };

  const handleModalClose = () => {
    if (activeModal === 'withdrawal-success') {
      closeAppModal();
    }
    closeUserModal();
  };

  return {
    selectedMethod,
    setSelectedMethod,
    methods,
    id,
    activeModal,
    isListEmpty,
    changeUserModal,
    handleMethodClick,
    handleModalClose,
  };
};
