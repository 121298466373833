
// ? Сделал по ссылкам на скорую руку как уникальный идентификатор
export const topCrashGames = [
  '/games/vegas/aviator_spribe',
  '/games/aviatrix',
  '/games/vegas/rocketman_elbet',
  '/games/smartsoft/JetX/JetX',
  '/games/betgames/27',
  '/games/mascot/mines_blast'
];

/*

{
    "id": "aviator_spribe",
    "gameId": "aviator_spribe",
    "name": "Aviator",
    "link": "/games/vegas/aviator_spribe",
    "isFavorite": false,
    "preview": "/img/games/vegas/aviator_spribe.jpg",
    "category": [],
    "type": [
        "slots",
        "crash games"
    ],
    "provaider": "spribe",
    "mainProvider": "vegas",
    "hasFreeSpins": true
}

{
    "id": "aviatrix",
    "gameId": "aviatrix",
    "name": "Aviatrix",
    "link": "/games/aviatrix",
    "isFavorite": false,
    "preview": "/img/games/vegas/aviatrix_ax.jpg",
    "category": [],
    "type": [
        "crash games"
    ],
    "provaider": "aviatrix",
    "mainProvider": "aviatrix",
    "hasFreeSpins": false
}

    {
    "id": "rocketman_elbet",
    "gameId": "rocketman_elbet",
    "name": "Rocketman",
    "link": "/games/vegas/rocketman_elbet",
    "isFavorite": false,
    "preview": "/img/games/vegas/rocketman_elbet.jpg",
    "category": [],
    "type": [
        "slots",
        "crash games"
    ],
    "provaider": "elbet",
    "mainProvider": "vegas",
    "hasFreeSpins": true
}

{
    "id": "13",
    "gameId": "13",
    "name": "JetX",
    "link": "/games/smartsoft/JetX/JetX",
    "isFavorite": false,
    "preview": "/img/games/smartsoft/JetX.jpg",
    "category": [],
    "type": [
        "JetX",
        "crash games"
    ],
    "provaider": "SmartSoft",
    "mainProvider": "smartsoft",
    "hasFreeSpins": true
}

{
    "id": "27",
    "gameId": "27",
    "name": "Skyward",
    "link": "/games/betgames/27",
    "isFavorite": false,
    "preview": "/img/games/betgames/card-skyward.jpg",
    "category": [],
    "type": [
        "crash games"
    ],
    "provaider": "betgames",
    "mainProvider": "betgames",
    "hasFreeSpins": false
}

{
    "id": "mines_blast",
    "gameId": "mines_blast",
    "name": "Mines Blast",
    "link": "/games/mascot/mines_blast",
    "isFavorite": false,
    "preview": "/img/games/mascot/mines_blast.png",
    "category": [],
    "type": [
        "casino",
        "crash games"
    ],
    "provaider": "ttt",
    "mainProvider": "mascot",
    "hasFreeSpins": null
}

*/
