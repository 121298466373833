import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import Dropdown from '@shared/components/dropdown/dropdown';
import { useAppSelector, useDispatchedActions } from '@shared/hooks';
import { useLogoutMutation } from '@/modules/auth';
import { getUseRole } from '@/modules/user';
import { useTranslation } from 'react-i18next';
import Desktop from '@/shared/components/responsive/desktop';
import { AppFeature } from '@/app-config';
import './user-menu-dropdown.scss';

type UserDropdownProps = {
  className?: string;
};

const UserMenuDropdown = ({ className }: UserDropdownProps) => {
  const { t } = useTranslation();
  const [serverLogout] = useLogoutMutation();
  const { logout, changeAppModal } = useDispatchedActions();
  const userRole = useAppSelector(getUseRole);

  const isManager = userRole === 'manager';
  const isPartner = userRole === 'partner';

  const handleLogoutClick = async () => {
    await serverLogout();
    logout();
  };


  const b = bemCn('user-menu-dropdown');

  return (
    <Dropdown
      className={b(null)}
      classNameContent={b('content')}
      renderTriger={({ toggel }) => (
        <ButtonIcon className={b('button', className)}
          icon="user"
          variant="secondary"
          onClick={toggel}
        />
      )}
      renderContent={({ close }) => (
        <ul className={b('list')}>
          <li className={b('item')}>
            <button type='button' className={b('link')}
              onClick={() => {
                changeAppModal('withdrawal');
                close();
              }}
            >
              {t('wallets.withdrawal', 'Вывод')}
            </button>
          </li>
          {!AppFeature.DISABLE_SPORTBOOK && (
            <li className={b('item')}>
              <Link className={b('link')} to="bets-history" onClick={close}>
                {t('profile.nav.bet-history', 'История ставок')}
              </Link>
            </li>
          )}
          <li className={b('item')}>
            <button type='button' className={b('link')}
              onClick={() => {
                changeAppModal('withdrawals-list');
                close();
              }}
            >
              {t('profile.nav.withdrawal-requests', 'Заявки на вывод')}
            </button>
          </li>
          <li className={b('item')}>
            <button type='button' className={b('link')}
              onClick={() => {
                changeAppModal('transactions');
                close();
              }}
            >
              {t('profile.nav.transactions', 'Детализация')}
            </button>
          </li>
          <li className={b('item')}>
            <button type='button' className={b('link')}
              onClick={() => {
                changeAppModal('promocodes');
                close();
              }}
            >
              {t('profile.nav.promo-codes', 'Промокоды')}
            </button>
          </li>
          <li className={b('item')}>
            <button type='button' className={b('link')}
              onClick={() => {
                changeAppModal('promocode-activate');
                close();
              }}
            >
              {t('promocodes.activate.btn', 'Активировать промокод')}
            </button>
          </li>
          {isPartner && (
            <li className={b('item')}>
              <Link className={b('link')} to="/partner-program/partner/stats" onClick={close}>
                {t('profile.nav.partner-program', 'Партнёрская программа')}
              </Link>
            </li>
          )}
          {isManager && (
            <li className={b('item')}>
              <Link className={b('link')} to="/partner-program/manager/stats" onClick={close}>
                {t('profile.nav.partner-program', 'Партнёрская программа')}
              </Link>
            </li>
          )}
          <li className={b('item')}>
            <button className={b('link')}
              type='button'
              onClick={() => {
                changeAppModal('user-settings');
                close();
              }}
            >
              {t('profile.nav.settings', 'Настройки')}
            </button>
          </li>
          <Desktop>
            <li className={b('item')}>
              <button type='button' className={b('link')}
                onClick={handleLogoutClick}
              >
                {t('user-nav.logout', 'Выйти')}
              </button>
            </li>
          </Desktop>
        </ul>
      )}
    />
  );
};

export default UserMenuDropdown;
