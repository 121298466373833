import { getTopupMethods } from '@shared/store/app/selectors';
import { useAppSelector } from '../../../../shared/hooks';
import { getUserData } from '../../../user/store/selectors';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import AppModal from '@shared/components/app-modal/app-modal';
import { useGetPaymentsMethodsQuery } from '@shared/store/app/app-api';
import { ITopupMethodsItem } from '@shared/store/app/types';
import { useState } from 'react';
import TopUpModal from '../topup-modal/topup-modal';
import { getIsAuth } from '@/modules/auth';
import PaymentMethod from '@/modules/user-wallet/components/payment-method/payment-method';
import { useTranslation } from 'react-i18next';
import './topup-methods-modal.scss';
import { SUPPORT_LINK } from '@/app-config';
import { EmptyPlaceholder } from '@/shared/components/empty-placeholder/empty-placeholder';

type TopupMethodsModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const b = bemCn('topup-methods-modal');

const TopupMethodsModal = ({ isOpen, onClose }: TopupMethodsModalProps) => {
  const { t } = useTranslation();
  const [selectedMethod, setSelectedMethod] = useState<ITopupMethodsItem | null>(null);
  const { id } = useAppSelector(getUserData);
  const isAuth = useAppSelector(getIsAuth);
  useGetPaymentsMethodsQuery(undefined, { skip: !isAuth });
  const methods = useAppSelector(getTopupMethods);
  const isEmpty = methods.length === 0;

  return (
    <AppModal className={b()}
      isOpen={isOpen}
      onClose={onClose}
      title={
        <>
          {t('topups.modal.title', 'Пополнить')}
          <span className={b('id')}>ID: {id}</span>
        </>
      }
      description={t('topups.description', 'Выберите метод оплаты')}
      classNameTitle={b('title')}
      classNameDesc={b('desc')}
    >
      <div className={b('list')}>
        {isEmpty && (
          <EmptyPlaceholder className={b('empty')}
            title={t('empty-message.topup-methods.title')}
            description={t('empty-message.topup-methods.desc')}
          />
        )}
        {methods.map((item) => (
          <PaymentMethod className={b('item')}
            key={item.id}
            title={item.title}
            icons={item.icons}
            href={item.id === 'support'
              ? SUPPORT_LINK : undefined}
            label={item.id === 'support' ? '+5%' : undefined}
            onClick={() => item.id !== 'support' && setSelectedMethod(item)}
          />
        ))}
      </div>
      <TopUpModal
        isOpen={!!selectedMethod}
        onClose={() => setSelectedMethod(null)}
        method={selectedMethod}
      />
    </AppModal>
  );

};

export default TopupMethodsModal;
