import { handleQueryError, type HandledError } from '@/shared/utils/helpers/handle-query-error';
import { LoginFormError } from '../components/login-form/login-form';
import i18n from '@/shared/i18n/i18n';
import { LoginFormByPhoneError } from '../components/login-form-by-phone/login-form-by-phone';

export const handleLoginError = (error?: HandledError): LoginFormError | undefined => {
  const queryError = handleQueryError(error);
  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;

    if (typeof detail === 'string') {
      const errors: LoginFormError = {};
      switch (detail) {
        case 'Password incorrect':
          errors.password = i18n.t('backend-errors.auth.password-incorrect', 'Неверный пароль');
          break;
        case 'User not found':
          errors.login = i18n.t('backend-errors.auth.user-not-found', 'Пользователь не найден');
          break;
        default:
          errors.root = detail;
      }

      return errors;
    }

    if (Array.isArray(detail)) {
      const errors: LoginFormError = {};
      detail.forEach((err) => {
        const location = err.loc?.[1];
        const message = err.msg;

        switch (location) {
          case 'login':
            errors.login = message;
            break;
          case 'password':
            errors.password = message;
            break;
          default:
            errors.root = message;
        }
      });

      return errors;
    }
  }
};

export const handleLoginByPhoneError = (error?: HandledError): LoginFormByPhoneError | undefined => {
  const queryError = handleQueryError(error);
  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;

    if (typeof detail === 'string') {
      const errors: LoginFormByPhoneError = {};
      switch (detail) {
        case 'Password incorrect':
          errors.password = i18n.t('backend-errors.auth.password-incorrect', 'Неверный пароль');
          break;
        case 'User not found':
          errors.phone = i18n.t('backend-errors.auth.user-not-found', 'Пользователь не найден');
          break;
        default:
          errors.root = detail;
      }

      return errors;
    }

    if (Array.isArray(detail)) {
      const errors: LoginFormByPhoneError = {};
      detail.forEach((err) => {
        const location = err.loc?.[1];
        const message = err.msg;

        switch (location) {
          case 'login':
            errors.phone = message;
            break;
          case 'password':
            errors.password = message;
            break;
          default:
            errors.root = message;
        }
      });

      return errors;
    }
  }
};
