import { getPaymentMethodIcons } from '@shared/utils/helpers/common';
import type {
  Appsport,
  AppSports,
  AppsportServer,
  AppStatus,
  AppStatusRes,
  BanksList,
  BanksListRes,
  HotEvent,
  HotEventServer,
  ITopupMethodsByCurrency,
  ITopupMethodsServer,
  KYCMethod,
  KYCMethodServer,
  RegisterBonus,
  RegisterBonusServer,
  TBanner,
  TBannerServer,
  WithdrawalMethodsByCurrency,
  WithdrawalMethodsServer,
} from './types';
import { AppFeature, EXCLUDED_CURRENCY, ONLY_CURRENCY } from '@/app-config';

export const adapteStatus = (res: AppStatusRes): AppStatus => {
  const { status_message: statusMessage, ...status } = res;
  return {
    ...status,
    statusMessage,
  };
};

export const adapteCurrencies = (res: string[]): string[] => {
  let result = res;
  if (EXCLUDED_CURRENCY) {
    const exclude = new Set(EXCLUDED_CURRENCY);

    result = result.filter((currency) => !exclude.has(currency));
  }

  if (ONLY_CURRENCY) {
    result = result.filter((currency) => ONLY_CURRENCY === currency);
  }

  return result;
};

export const adapteBanksList = (res: BanksListRes): BanksList => {
  const { dictionary = [] } = res;

  return dictionary.map(({
    schema: id,
    bankName: name,
    logoURL,
  }) => ({ id, name, logoURL, }));
};


export const adapteWithdrawalMethods = (res: WithdrawalMethodsServer): WithdrawalMethodsByCurrency => {
  if (!res) {
    return {};
  }

  const methods: WithdrawalMethodsByCurrency = {};

  Object.entries(res)
    .forEach(([methodName, methodData]) => {
      Object.entries(methodData.limits)
        .forEach(([currency, [minAmount, maxAmount]]) => {
          methods[currency] = methods[currency] ?? [];
          if (methodData.is_active) {
            methods[currency].push({
              id: methodName,
              name: methodName,
              title: methodData.displayed_name,
              isActive: methodData.is_active,
              currency,
              minAmount,
              maxAmount,
              priority: methodData.priority ?? 0,
              icons: getPaymentMethodIcons(methodName),
            });
          }
        });
    });

  for (const currency in methods) {
    methods[currency] = methods[currency]
      .sort((a, b) => b.priority - a.priority);
  }

  return methods;
};


export const adapteTupupMethods = (res: ITopupMethodsServer): ITopupMethodsByCurrency => {
  if (!res) {
    return {};
  }

  const methods: ITopupMethodsByCurrency = {};

  Object.entries(res)
    .forEach(([system, systemMethods]) => ({
      id: system,
      methods: Object.entries(systemMethods)
        .forEach(([methodName, methodData]) => {
          Object.entries(methodData.limits)
            .forEach(([currency, [minAmount, maxAmount]]) => {
              methods[currency] = methods[currency] ?? [];
              if (methodData.is_active) {
                methods[currency].push({
                  id: `${system}:${methodName}`,
                  name: methodName,
                  title: methodData.displayed_name,
                  system: system,
                  currency,
                  isActive: methodData.is_active,
                  minAmount,
                  maxAmount,
                  priority: methodData.priority ?? 0,
                  icons: getPaymentMethodIcons(methodName),
                });
              }
            });
        })
    }));

  for (const currency in methods) {
    methods[currency] = methods[currency]
      .sort((a, b) => b.priority - a.priority);
  }

  return methods;
};

const isBannerRoute = (link: string) => link.slice(0, 4) === 'http';

const getBannerUUID = (fileName: string) => {
  const parts = fileName.split('.');

  return parts[0] ?? '';
};

const getBannerUrl = (banner: TBannerServer): string => {
  if (AppFeature.ADAPTIVE_BANNERS) {
    return `/adaptive-image/img/${getBannerUUID(banner.filename)}`;
  }

  return banner.url;
};

export const adapteBanners = (res: TBannerServer[]): TBanner[] => (
  res.map((banner) => ({
    pages: banner.pages,
    id: banner.id,
    filename: banner.filename,
    name: banner.name,
    isDesktop: banner.is_desktop,
    currency: banner.currency,
    priority: banner.priority,
    url: getBannerUrl(banner),
    title: banner.title,
    subtitle: banner.subtitle,
    buttonText: banner.button_text,
    [!isBannerRoute(banner.link) ? 'link' : 'href']: banner.link,
  }))
);

const adapteAppSport = (sport: AppsportServer): Appsport => ({
  name: sport.name,
  blacklisted: sport.blacklisted,
  subGamesBlackList: sport.sub_games_black_list,
  tournamentsBlackList: sport.tournaments_black_list,
  tournamentsPriority: Object.fromEntries(
    Object.entries(sport.tournaments_priority)
      .map(([pLevel, pValue]) => ([Number(pLevel), pValue]))
  )
});

export const adapteAppSports = (sports: AppsportServer[]): AppSports => {
  const tournamentsBlackList: string[] = [];
  const sportsBlacklist: string[] = [];
  const subGamesBlackList: string[] = [];

  let tournamentsPriority: string[] = [];
  const tournamentsByPriorityLevel: {
    [key: number]: string[];
  } = {};
  const items: Appsport[] = [];

  for (const sport of sports) {
    if (sport.blacklisted) {
      sportsBlacklist.push(sport.name.toLowerCase());
    }
    items.push(adapteAppSport(sport));

    subGamesBlackList.push(...sport.sub_games_black_list.map((item) => item.toLowerCase()));
    tournamentsBlackList.push(...sport.tournaments_black_list.map((item) => item.toLowerCase()));

    Object.entries(sport.tournaments_priority)
      .forEach(([pLevel, pValue]) => {
        const lvl = parseInt(pLevel, 10);

        if (!tournamentsByPriorityLevel[lvl]) {
          tournamentsByPriorityLevel[lvl] = [];
        }
        tournamentsByPriorityLevel[lvl] = [
          ...tournamentsByPriorityLevel[lvl],
          ...pValue,
        ];
      });
  }

  Object.entries(tournamentsByPriorityLevel)
    .sort(([lvlA], [lvlB]) => parseInt(lvlA, 10) - parseInt(lvlB, 10))
    .forEach(([, pValue]) => {
      tournamentsPriority = [
        ...tournamentsPriority,
        ...pValue,
      ];
    });

  return {
    items: items,
    tournamentsPriority: tournamentsPriority
      .map((item) => item.toLowerCase()),
    tournamentsBlackList: tournamentsBlackList,
    sportsBlacklist: sportsBlacklist,
    subGamesBlackList: subGamesBlackList,
  };
};

export const adapteKYCMethods = (methods: KYCMethodServer[]): KYCMethod[] => methods.map((method) => ({
  id: method.id,
  mediaType: method.media_type,
  messages: method.messages,
  name: method.name
}));

const adapteHotEvent = (item: HotEventServer): HotEvent => ({
  id: item.id,
  gameId: item.game_id,
  gameMid: item.game_mid,
  gameNum: item.game_num,
  gameStart: item.game_start,
  countryId: item.country_id,
  countryName: item.country_name,
  tournamentId: item.tournament_id,
  tournamentName: item.tournament_name,
  opp1Icon: item.opp_1_icon,
  opp1Id: item.opp_1_id,
  opp1Name: item.opp_1_name,
  opp2Icon: item.opp_2_icon,
  opp2Id: item.opp_2_id,
  opp2Name: item.opp_2_name,
  sportId: item.sport_id,
  sportName: item.sport_name,
  dataType: item.data_type,
});

export const adapteHotEvents = (events: HotEventServer[]): HotEvent[] =>
  events.map(adapteHotEvent);

export const adapteRegisterBonus = (bonus: RegisterBonusServer): RegisterBonus => ({
  id: bonus.id,
  description: bonus.description,
  currencies: bonus.currencies,
});

export const adapteRegisterBonuses = (bonuses: RegisterBonusServer[]): RegisterBonus[] => bonuses.map(adapteRegisterBonus);
