import { ONLY_CURRENCY } from '@/app-config';
import RegisterForm, { baseSchema, RegisterFormData, RegisterFormError, RegisterFormProps } from './register-form';
import * as yup from 'yup';
import i18n from '@/shared/i18n/i18n';

type RegisterFormDataEmail = RegisterFormData<{
  email: string;
  name: string;
}>

export type RegisterErrorEmail = RegisterFormError<RegisterFormDataEmail>

export const schema: yup.ObjectSchema<RegisterFormDataEmail> = baseSchema.shape({
  email: yup.string()
    .required(i18n.t('reg.modal.errors.empty-email', 'Введите E-mail'))
    .email(i18n.t('reg.modal.errors.wrong-email', 'Неверный формат')),
  name: yup.string()
    .required('Введите имя')
    .test(
      'is-two-words',
      'Имя должно состоять как минимум из двух слов',
      (value) => value.trim().split(/\s+/).length >= 2
    ),
});

const defaultValues: RegisterFormDataEmail = {
  email: '',
  name: '',
  password: '',
  confirmPassword: '',
  currency: ONLY_CURRENCY ?? '',
  agree: false,
};

type RegisterFormByEmailProps = Omit<RegisterFormProps<RegisterFormDataEmail>, 'form'>

const RegisterFormByEmail = (props: RegisterFormByEmailProps) => (
  <RegisterForm
    {...props}
    form={{ defaultValues, schema }}
  >
    <RegisterForm.Bonus />
    <RegisterForm.Email />
    <RegisterForm.Name />
  </RegisterForm>
);

export default RegisterFormByEmail;
