import Select from '@/shared/components/select/select';
import { useAppSelector } from '@/shared/hooks';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useDispatchedActions } from '@/shared/hooks';
import { getUserData, getUserCurrencies } from '@/modules/user/@x/partner-program';
import Button from '@/shared/components/button/button';
import { getAmountString, roundNumber } from '@/shared/utils/helpers/common';
import './pp-wallet-controls.scss';

const b = bemCn('pp-wallet-controls');

const PPWalletControls = () => {
  const userCurrencies = useAppSelector(getUserCurrencies);
  const options = userCurrencies?.map((currency) => ({
    value: currency,
    label: currency,
  })) ?? [];

  const { wallet: { amount, currency, } } = useAppSelector(getUserData);
  const { changeSelectedCurrency } = useDispatchedActions();

  const changePPCurrency = (selectedCurrency: string | null) => {
    changeSelectedCurrency(selectedCurrency ?? '');
  };

  return (
    <div className={b()}>
      <Button
        className={b('amount')}
        variant="secondary"
        leftIcon='coin-colored'
      >
        {getAmountString(roundNumber(amount), currency)}
      </Button>
      <Select
        classNameField={b('currency')}
        options={options}
        inverted
        value={currency ?? ''}
        onChange={changePPCurrency}
      />
    </div>
  );
};

export { PPWalletControls };
