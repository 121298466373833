import { getBasketCount } from '@/modules/basket';
import { useDispatchedActions, useAppSelector } from '@shared/hooks';
import EventCardDesktop, { EventCardDesktopProps } from './event-card-desktop/event-card-desktop';
import EventCardMobile, { EventCardMobileProps } from './event-card-mobile/event-card-mobile';
import Adaptive from '@/shared/components/responsive/adaptive';
import { useAddToBasketMutation } from '@/modules/basket';
import { useTranslation } from 'react-i18next';

import { TEventBetItem } from '../../model/line';
import { isoToTimestamp } from '@/shared/utils/helpers/date';

type EventCardProps = EventCardMobileProps | EventCardDesktopProps

const EventCard = (props: EventCardProps) => {
  const { event } = props;
  const { i18n } = useTranslation();
  const [addToBasket] = useAddToBasketMutation();
  const { changeAppModal } = useDispatchedActions();
  const basketCount = useAppSelector(getBasketCount);

  const handleBetClick = async (bet: TEventBetItem) => {
    await addToBasket({
      lang: i18n.resolvedLanguage,
      dataType: event.dataType,
      betId: bet.id,

      gameId: Number(event.id),
      gameMid: Number(event.mainId),
      eventDate: isoToTimestamp(event.startDate),
      team1: event.team1.name,
      team2: event.team2.name,
      subGameName: event.gameDopName,
      subGameNameRu: event.gameDopNameRu,
      tournamentName: event.tournament.name,
      groupName: bet.groupName,
      outcomeName: bet.name,
      sportId: Number(event.sport.id),
      sportName: event.sport.name,
      sportNameRu: event.sport.nameRu ?? '',
      rate: bet.rate,
    });
  };

  const handleBetClickMobile = (bet: TEventBetItem) => {
    handleBetClick(bet);

    if (basketCount === 0) {
      changeAppModal('basket');
    }
  };

  return (
    <Adaptive
      mobile={(
        // <EventCardMobile {...props} onBetClick={handleBetClick} />
        <EventCardMobile {...props} onBetClick={handleBetClickMobile} />
      )}
      desktop={(
        <EventCardDesktop {...props} onBetClick={handleBetClick} />
      )}
    />
  );
};

export default EventCard;
