import { store } from '@/app/app-store';
import { betapi, TournamentEventsList, useTournamentEventsToggler, type TTournamentEvents } from '@/modules/line';
import Container from '@/shared/components/container/container';
import { useGetHotEventsQuery } from '@/shared/store/app/app-api';
import { HotEvent } from '@/shared/store/app/types';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useEffect, useMemo, useState } from 'react';

import Breadcrumbs from '@/shared/components/breadcrumbs/breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/shared/hooks';
import { getBasketItemsIds } from '@/modules/basket';
import { EmptyPlaceholder } from '@/shared/components/empty-placeholder/empty-placeholder';
import './hot-events-page.scss';

const b = bemCn('hot-events-page');

const getGameIds = (hotEvents: HotEvent[]) =>
  new Set(hotEvents.map((item) => `${item.gameId}`));

const getSportsIds = (hotEvents: HotEvent[]) =>
  new Set<string>(hotEvents.map((item) => `${item.sportId}`));

const fetchAllevents = async (sportsIds: Set<string>, gamesIds: Set<string>) => {
  const sportsIdsList = Array.from(sportsIds);

  return Promise.all(sportsIdsList.map((sportId) =>
    store.dispatch(betapi.endpoints.getBetapiEventsByTourney.initiate({
      sportId,
      dataType: 'line',
      pageLength: 5000,
    }))
  )).then((res) => {
    const result: TTournamentEvents[] = [];
    const games = res.map(({ data = [] }) =>
      data.map((item) => ({
        ...item,
        events: item.events.filter((event) => gamesIds.has(event.id))
      })).filter((item) => item.events.length > 0)
    );

    for (const innerArr of games) {
      for (const item of innerArr) {
        result.push(item);
      }
    }

    return result;
  });
};

const HotEventsPage = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const { closedItems, handleToggleItem } = useTournamentEventsToggler();
  const [eventsList, setEventsList] = useState<TTournamentEvents[]>([]);
  const { data: hotEvents = [], isLoading: isHotEventsLoading } = useGetHotEventsQuery();
  const basketItemsIds = useAppSelector(getBasketItemsIds);

  useEffect(() => {
    if (hotEvents.length > 0) {
      const gamesIds = getGameIds(hotEvents);
      const sportsIds = getSportsIds(hotEvents);
      fetchAllevents(sportsIds, gamesIds)
        .then((res) => {
          setEventsList(res);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [hotEvents]);

  const isEmpty = !isHotEventsLoading && hotEvents.length <= 0
    || eventsList.length <= 0 && !isLoading;

  const eventsListWithBasket = useMemo(() => eventsList.map((tournament) => {
    if (basketItemsIds.length <= 0) {
      return tournament;
    }

    return ({
      ...tournament,
      events: tournament.events.map((event) => ({
        ...event,
        bets: event.bets.map((bet) => ({
          ...bet,
          isActive: basketItemsIds.includes(bet.id)
        })),
        mainBets: event.mainBets.map((bet) => ({
          ...bet,
          isActive: basketItemsIds.includes(bet.id)
        })),
      }))
    });

  }), [basketItemsIds, eventsList]);

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        {isEmpty && (
          <EmptyPlaceholder className={b('empty-message')}
            title={t('empty-message.events.title')}
            description={t('empty-message.events.desc')}
          />
        )}
        {!isEmpty && (
          <TournamentEventsList className={b('list')}
            events={eventsListWithBasket}
            closedItems={closedItems}
            onToggle={handleToggleItem}
            loading={isLoading || isHotEventsLoading}
          />
        )}
      </Container>
    </div>
  );
};

export default HotEventsPage;
