import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NameSpace } from '@shared/utils/constants/common';
import { AppState, AppModal } from './types';
import { getHiddenBalance, setHiddenBalance } from '@/shared/utils/helpers/hidden-balance';
import { SOCIAL_LINK_FACEBOOK, SOCIAL_LINK_INST, SOCIAL_LINK_TG, SOCIAL_LINK_VK, SOCIAL_LINK_TWITTER_X, SOCIAL_LINK_TIKTOK } from '@/app-config';
import appApi from './app-api';
import { dropStoredCurrencies, saveCurrencies } from './currencies-localstorage';
import { dropStoredBanners, saveBanners } from './banners-localstorage';
import { getCouponLogoByTheme, getMainLogoByTheme } from '@/shared/utils/helpers/get-logotype-image';

const initialMainLogotype = getMainLogoByTheme();
const initialCouponLogotype = getCouponLogoByTheme();

const initialState: AppState = {
  activeModal: null,
  isMenuOpened: false,
  balanceHidden: getHiddenBalance(),
  mainLogotype: initialMainLogotype,
  couponLogotype: initialCouponLogotype,
  socialLinks: [
    {
      id: 'telegram',
      title: 'Telegram',
      link: SOCIAL_LINK_TG,
      icon: 'telegram',
    },
    {
      id: 'vk',
      title: 'Vkontakte',
      link: SOCIAL_LINK_VK,
      icon: 'vkontakte',
    },
    {
      id: 'instagram',
      title: 'Instagram',
      link: SOCIAL_LINK_INST,
      icon: 'instagram',
    },
    {
      id: 'facebook',
      title: 'Facebook',
      link: SOCIAL_LINK_FACEBOOK,
      icon: 'facebook',
    },
    {
      id: 'twitter-x',
      title: 'X',
      link: SOCIAL_LINK_TWITTER_X,
      icon: 'twitter-x',
    },
    {
      id: 'tiktok',
      title: 'TikTok',
      link: SOCIAL_LINK_TIKTOK,
      icon: 'tiktok',
    },
  ]
};

const appSlice = createSlice({
  name: NameSpace.App,
  initialState,
  reducers: {
    changeAppModal: (state, action: PayloadAction<AppModal>) => {
      state.activeModal = action.payload;
    },
    closeAppModal: (state) => {
      state.activeModal = null;
    },
    toggleMenu: (state) => {
      state.isMenuOpened = !state.isMenuOpened;
    },
    setMenuOpened: (state, action: PayloadAction<boolean>) => {
      state.isMenuOpened = action.payload;
    },
    hideBalance: (state) => {
      state.balanceHidden = true;
      setHiddenBalance(true);
    },
    showBalance: (state) => {
      state.balanceHidden = false;
      setHiddenBalance(false);
    },
    toggleBalanceHidden: (state) => {
      setHiddenBalance(!state.balanceHidden);
      state.balanceHidden = !state.balanceHidden;
    },
    changeLogotypesByTheme: (state, action: PayloadAction<string>) => {
      const newTheme = action.payload;
      state.mainLogotype = getMainLogoByTheme(newTheme);
      state.couponLogotype = getCouponLogoByTheme(newTheme);
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(appApi.endpoints.getCurrencies.matchFulfilled, (_, action) => {
        saveCurrencies(action.payload);
      })
      .addMatcher(appApi.endpoints.getCurrencies.matchRejected, () => {
        dropStoredCurrencies();
      })
      .addMatcher(appApi.endpoints.getBanners.matchFulfilled, (_, action) => {
        saveBanners(action.payload);
      })
      .addMatcher(appApi.endpoints.getBanners.matchRejected, () => {
        dropStoredBanners();
      });
  }
});


export default appSlice;
