import { bemCn } from '@/shared/utils/helpers/bem-cn';
import Flag from '../flag/falg';
import { useOnClickOutside, useToggle } from 'usehooks-ts';
import { FC, FocusEventHandler, useEffect, useRef, useState } from 'react';
import { countryCodes, CountryPhone, getPhoneCodeByCountry, } from '@/shared/utils/constants/phone';
import { AppFeature } from '@/app-config';

import './phone-selector.scss';

const hasOnlyDigits = (str: string) => /^\d+$/.test(str);

const b = bemCn('phone-selector');

export type PhoneSelectorValue = {
  phone: string;
  countryCode: string;
  phoneCode: string;
}

export type PhoneSelectorProps = {
  label?: string;
  placeholder?: string;
  name?: string;
  id?: string;
  value?: PhoneSelectorValue;
  className?: string;
  onChange?: (newValue: PhoneSelectorValue) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  status?: 'error' | 'success';
  error?: string;
  disabled?: boolean;
  inverted?: boolean;
}

const PhoneSelector: FC<PhoneSelectorProps> = ({
  className,
  id,
  name,
  label,
  placeholder,
  onChange,
  onBlur,
  status,
  error,
  disabled,
  inverted,
  value
}) => {

  const [opened, toggleOpened, setOpened] = useToggle(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<CountryPhone | null>(
    getPhoneCodeByCountry(value?.countryCode ?? '')
  );
  const [phone, setPhone] = useState(value?.phone ?? '');
  const isError = status === 'error' && error;


  useOnClickOutside(selectRef, () => setOpened(false));

  const handleOptionClick = (option: CountryPhone) => {
    setSelectedOption((prevOption) => {
      if (prevOption && phone) {
        const code = `+${prevOption.phoneCode}`;
        const codeLength = code.length;

        if (phone.startsWith(code)) {
          setPhone(`+${option.phoneCode}${phone.slice(codeLength)}`);
        } else {
          setPhone(`+${option.phoneCode}`);
        }
      } else {
        setPhone(`+${option.phoneCode}`);
      }

      return option;
    });

    setOpened(false);
  };

  const hadlePhoneChange = (newValue: string) => {
    if (!selectedOption) {
      return;
    }

    const baseCode = `+${selectedOption.phoneCode}`;
    const baseCodeLength = baseCode.length;

    if (!newValue.startsWith(baseCode)) {
      setPhone(baseCode);
      return;
    }

    const input = newValue.slice(baseCodeLength);

    if (hasOnlyDigits(input) || input === '') {
      setPhone(baseCode + input);
    }
  };

  useEffect(() => {
    if (selectedOption?.countryCode && selectedOption.phoneCode) {
      onChange?.({
        phone,
        countryCode: selectedOption.countryCode,
        phoneCode: selectedOption.phoneCode
      });
    }

  }, [phone, onChange, selectedOption?.countryCode, selectedOption?.phoneCode]);

  useEffect(() => {
    if (value) {
      setSelectedOption(getPhoneCodeByCountry(value.countryCode));
      setPhone(value.phone);
    }
  }, []);


  return (
    <div className={b({ opened, status, inverted }, className)} ref={selectRef}>
      {label && (
        <label htmlFor={id} className={b('label')}>{label}</label>
      )}
      <div className={b('input-wrapper')}>
        <button className={b('select-btn')}
          type='button'
          onClick={toggleOpened}
          disabled={disabled || AppFeature.KES_PROJECT}
        >
          <Flag className={b('flag-icon')} countryCode={selectedOption?.countryCode} />
        </button>
        <input className={b('input')}
          type='tel'
          id={id}
          name={name}
          value={phone}
          placeholder={placeholder}
          onChange={(e) => hadlePhoneChange(e.target.value)}
          onBlur={onBlur}
          disabled={disabled}
        />

        <ul className={b('select-list')}>
          {countryCodes.map((option) => {
            const isSelected = selectedOption?.countryCode === option.countryCode
              && selectedOption?.phoneCode === option.phoneCode;
            return (
              <li className={b('select-option', { selected: isSelected })}
                key={`${option.countryCode}-${option.phoneCode}`}
                onClick={() => handleOptionClick(option)}
              >
                <Flag className={b('flag-icon')}
                  countryCode={option.countryCode}
                />
                {option.label} (+{option.phoneCode})
              </li>
            );
          })}
        </ul>
      </div>
      {isError && <span className={b('error')}>{error}</span>}
    </div>
  );
};

export default PhoneSelector;
