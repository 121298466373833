import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { memo, useMemo, useState } from 'react';
import { useGetWithdrawalsQuery } from '@/modules/user';
import type { GetWithdrawalsParams } from '@/modules/user';
import WithdrawalCard from '../withdrawal-card/withdrawal-card';
import Pagination from '@/shared/components/pagination/pagination';
import Loader from '@/shared/components/loader/loader';
import { useTranslation } from 'react-i18next';
import { EmptyPlaceholder } from '@/shared/components/empty-placeholder/empty-placeholder';
import './withdrawals-list.scss';

const MemoizedWithdrawalCard = memo(WithdrawalCard);

const b = bemCn('withdrawals-list');

const initParams: GetWithdrawalsParams = {
  page: 1,
  pageSize: 10,
};

type WithdrawalsListProps = {
  className?: string;
  inverted?: boolean;
}

const WithdrawalsList = ({ className, inverted }: WithdrawalsListProps) => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);
  const { data: withdrawals, isFetching } = useGetWithdrawalsQuery(params);

  const handleChangeParams = (newParams: Partial<GetWithdrawalsParams>) => {
    setParams({ ...params, ...newParams });
  };

  const isEmpty = !isFetching && !withdrawals?.count;

  const memoizedItems = useMemo(() => withdrawals?.items.map((item) => (
    <MemoizedWithdrawalCard
      className={b('item')}
      key={item.id}
      item={item}
      inverted={inverted}
    />
  )), [withdrawals?.items, inverted]);

  return (
    <div className={b(null, className)}>
      <div className={b('items', { loading: isFetching })}>
        {isEmpty && (
          <EmptyPlaceholder className={b('empty')}
            title={t('empty-message.withdrawals.title')}
            description={t('empty-message.withdrawals.desc')}
          />
        )}
        {isFetching && <Loader className={b('spiner')} />}
        {memoizedItems}
      </div>
      <Pagination className={b('pagination')}
        page={params.page}
        pageSize={params.pageSize}
        totalItems={withdrawals?.count}
        onChange={(page) => handleChangeParams({ page })}
      />
    </div>
  );
};

export default WithdrawalsList;
