import { CouponLogotype, DEFAULT_LOGOTYPE, MainLogotype } from '@/app-config';
import { getTheme } from './theme';

const MainLogoByTheme: Record<string, string> = {
  'dark-purple': MainLogotype.DARK_PURPLE,
  'blue': MainLogotype.BLUE,
  'dark-blue': MainLogotype.DARK_BLUE,
  'light-blue': MainLogotype.LIGHT_BLUE,
};

const CouponLogoByTheme: Record<string, string> = {
  'dark-purple': CouponLogotype.DARK_PURPLE,
  'blue': CouponLogotype.BLUE,
  'dark-blue': CouponLogotype.DARK_BLUE,
  'light-blue': CouponLogotype.LIGHT_BLUE,
};

export const getMainLogoByTheme = (theme: string | undefined = getTheme()) =>
  MainLogoByTheme[theme] ?? DEFAULT_LOGOTYPE;

export const getCouponLogoByTheme = (theme: string | undefined = getTheme()) =>
  CouponLogoByTheme[theme] ?? DEFAULT_LOGOTYPE;
