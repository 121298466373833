import { getUseExtraWallets, getUserData } from '@/modules/user/@x/user-wallet';
import AppModal from '@/shared/components/app-modal/app-modal';
import { useAppSelector } from '@/shared/hooks';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import MainWalletInfo from '../main-wallet-info/main-wallet-info';
import ExtraWalletManageItem from './extra-wallet-manage-item/extra-wallet-manage-item';
// import CreateWallet from './create-wallet/create-wallet';
import './wallet-manage-modal.scss';

const b = bemCn('wallet-manage-modal');

type WalletManageModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const WalletManageModal = ({ isOpen, onClose }: WalletManageModalProps) => {
  const userData = useAppSelector(getUserData);
  const extraWallets = useAppSelector(getUseExtraWallets);

  const { wallet, } = userData;
  const {
    currency,
    amount,
    bonusAmount,
    cashbackLine,
    cashbackCasino,
    casinoBonus,
    wagerScaleCurrentAmount,
    wagerScaleTargetAmount,
  } = wallet;

  return (
    <AppModal className={b()}
      isOpen={isOpen}
      style="dark"
      onClose={onClose}
    >
      <MainWalletInfo
        mainAmount={amount}
        bonusAmount={bonusAmount}
        cashbackLine={cashbackLine}
        cashbackCasino={cashbackCasino}
        casinoBonus={casinoBonus}
        wagerAmount={`${wagerScaleCurrentAmount}/${wagerScaleTargetAmount}`}
        currency={currency}
      />
      {extraWallets.length > 0 && (
        <div className={b('wallets')}>
          {extraWallets.map((item) => (
            <ExtraWalletManageItem className={b('wallet')}
              key={item.currency}
              wallet={item}
            />
          ))}
        </div>
      )}
      {/* <CreateWallet /> */}
    </AppModal>
  );
};
export default WalletManageModal;
