import { MouseEventHandler, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import i18n from '@/shared/i18n/i18n';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { getAmountString } from '@shared/utils/helpers/common';
import IconSvg from '@/shared/components/icon-svg/icon-svg';

import './tax-info-tooltip.scss';

import type { BettingTaxInfo } from '@/shared/hooks/use-betting-tax-info';

interface Props extends BettingTaxInfo {
  currency: string;
  type?: 'win' | 'loss' | 'refund' | 'placed' | 'recalc' | 'default';
  className?: string;
  position?: 'left-bottom' | 'right-bottom';
}

const statusItemTitles: Record<string, string> = {
  win: i18n.t('bet-history.prize', 'Выигрыш'),
  loss: i18n.t('bet-history.loss', 'Выигрыш'),
  placed: i18n.t('bet-history.possible-prize', 'Возможный выигрыш'),
  refund: i18n.t('bet-history.refund', 'Возврат'),
  'default': 'Final pay with tax'
};

const b = bemCn('tax-info-tooltip');

function TaxInfoTooltip({
  exciseTax,
  possibleWin,
  winnings,
  withHoldingTax,
  finalPay,
  currency,
  stakeAfterTax,
  type = 'default',
  className,
  position,
}: Props) {
  const [isTaxInfoOpen, setIsTaxInfoOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsTaxInfoOpen(false));

  const handleClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setIsTaxInfoOpen(!isTaxInfoOpen);
  };

  return (
    <div className={b({ position }, className)}>
      <div className={b('wrapper')}>
        <button className={b('btn', { type })} type='button' onClick={handleClick} >
          <IconSvg className={b('btn-icon')}
            name='info'
            width={15}
            height={15}
          />
          {statusItemTitles[type]}
        </button >

        {isTaxInfoOpen && (
          <div className={b('popup')} ref={ref}>
            <p className={b('text')}>
              Excise tax: {getAmountString(exciseTax.toFixed(2), currency)}
            </p>
            <p className={b('text')}>
              Stake after excise tax: {getAmountString(stakeAfterTax.toFixed(2), currency)}
            </p>
            <p className={b('text')}>
              Possible win: {getAmountString(possibleWin.toFixed(2), currency)}
            </p>
            <p className={b('text')}>
              Prize: {getAmountString(winnings.toFixed(2), currency)}
            </p>
            <p className={b('text')}>
              Withholding tax: {getAmountString(withHoldingTax.toFixed(2), currency)}
            </p>
            <p className={b('text')}>
              Final pay: {getAmountString(finalPay.toFixed(2), currency)}
            </p>
          </div>
        )}

      </div>
    </div>
  );
}

export default TaxInfoTooltip;
