import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './empty-placeholder.scss';
import IconSvg from '../icon-svg/icon-svg';
import { FC } from 'react';

const b = bemCn('empty-placeholder');

type EmptyPlaceholderProps = {
  className?: string;
  title?: string;
  description?: string;
  inverted?: boolean;
}

export const EmptyPlaceholder: FC<EmptyPlaceholderProps> = ({
  className,
  title = 'Nothing...',
  description,
  inverted,
}) => (
  <div className={b({ inverted }, className)}>
    <div className={b('wrapper')}>
      <div className={b('icon-wrapper')}>
        <IconSvg className={b('icon')}
          name='search'
          width={30}
          height={30}
        />
      </div>
      <p className={b('title')}>{title}</p>
      {description && (
        <p className={b('desc')}>{description}</p>
      )}
    </div>
  </div>
);
