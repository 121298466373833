
export const clearPhoneNumber = (phone: string, countryCode: string) => {
  let cleared = phone.replace('+', '');
  if (countryCode.toLowerCase() === 'ke'
    && cleared
    && cleared.length > 3
    && cleared[3] === '0'
  ) {
    cleared = cleared.slice(0, 3) + cleared.slice(4);
  }

  return cleared;
};
