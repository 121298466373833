import { useState } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { dayjsFromUnix } from '@/widgets/settings/utils/datetime-format';
import GradientIcon, { GRADIENT_COLORS } from '@shared/components/gradient-icon/gradient-icon';
import { getSportIconName } from '@/shared/utils/helpers/common';
import IconSvg from '@shared/components/icon-svg/icon-svg';
import LoaderCenter from '@/shared/components/loader-center/loader-center';
import ResultsScore from '../resutls-score/resutls-score';
import { ResultGameName } from '../results-game-name/results-game-name';

import './results-game-desktop.scss';

import type { TResultsGame } from '../../model/types';

const b = bemCn('results-game-desktop');

type GameProps = {
  sportId: string;
  game?: TResultsGame;
};

const ResultsGameDesktop = (props: GameProps) => {
  const { sportId, game } = props;
  const [active, setActive] = useState(true);
  const toggleClass = game && game.ext.length > 0 ? '' : b('game-toggle-icon--hide');

  if (!game) {
    return <LoaderCenter />;
  }

  return (
    <div className={b()}>
      <div className={b('header', { active })}
        onClick={() => setActive(!active)}
      >
        <p className={b('title')}>{game.name}</p>
        <p className={b('date')}>{dayjsFromUnix(game.start).format('DD.MM.YYYY / HH:mm')}</p>

        <div className={b('header-right')}>
          <ResultsScore score={game.score} variant='game' />
          <IconSvg className={b('game-toggle-icon', toggleClass)} name="chevron-down" />
        </div>

      </div>

      {active && game.ext.map((item) => (
        <div className={b('item-wrap')} key={item.id}>
          <div className={b('item')}>
            <div className={b('item-title')}>
              <GradientIcon className={b('sport-icon')}
                iconName={getSportIconName(sportId)}
                color={GRADIENT_COLORS[2]}
              />
              <ResultGameName gameName={item.name} className='item-title-text' />
            </div>

            <p className={b('date')}>{dayjsFromUnix(item.start).format('DD.MM.YYYY / HH:mm')}</p>

            <div className={b('header-right')}>
              <ResultsScore score={item.score} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResultsGameDesktop;
