import ResultModal from '@/shared/components/result-modal/result-modal';
import { useTranslation } from 'react-i18next';


type PromocodeSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const PromocodeSuccessModal = ({ isOpen, onClose }: PromocodeSuccessModalProps) => {
  const { t } = useTranslation();
  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.promocode.success.title', 'Промокод активирован')}
      description={t('result-modal.promocode.success.description', 'Промокод активирован')}
      status='success'
    />
  );
};

export default PromocodeSuccessModal;
