
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import {
  type IFavoriteMatch,
  useDeleteFavoriteMatchMutation,
} from '@/modules/user/@x/line';
import GradientIcon from '@/shared/components/gradient-icon/gradient-icon';
import { useThemeContext } from '@/modules/theme/@x/line';
import { couponIconColorByTheme } from '@/modules/coupons/@x/line';
import { getSportIconName } from '@/shared/utils/helpers/common';
import ButtonIcon from '@/shared/components/button-icon/button-icon';
import './favorite-event-card.scss';
import { Link } from 'react-router-dom';

const b = bemCn('favorite-event-card');

type FavoriteEventCardProps = {
  className?: string;
  item: IFavoriteMatch;
}

const FavoriteEventCard = ({ className, item }: FavoriteEventCardProps) => {
  const { theme } = useThemeContext();
  const iconColor = couponIconColorByTheme[theme] ?? couponIconColorByTheme.default;
  const [deleteFromFavorite, {
    isLoading: isDeleting
  }] = useDeleteFavoriteMatchMutation();

  const handleButtonClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    deleteFromFavorite(item.id);
  };

  const isLive = item.dataType === 'live';
  const link = `/${item.dataType}/${item.matchId}`;

  return (
    <Link className={b(null, className)} key={item.id} to={link}>
      <div className={b('info')}>
        <p className={b('tournament')}>
          {item.sportName} / {item.tournamentName}
        </p>
        {isLive && (
          <p className={b('live')}>Live</p>
        )}
      </div>
      <div className={b('wrapper')}>
        <GradientIcon className={b('icon')}
          color={iconColor}
          iconName={getSportIconName(String(item.sportId))}
        />
        <p className={b('teams')}>
          <span className={b('team')}>{item.team1}</span>
          <span className={b('team')}>{item.team2}</span>
        </p>
        <ButtonIcon className={b('favorite-btn')}
          icon='heart'
          variant='secondary'
          onClick={handleButtonClick}
          disabled={isDeleting}
        />
      </div>
    </Link>
  );
};

export default FavoriteEventCard;
