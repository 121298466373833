import { useTranslation } from 'react-i18next';
import AppModal from '@/shared/components/app-modal/app-modal';
import { useResetPaswordSmsCodeMutation } from '../../model/auth-api';
import PasswordResetByPhoneConfirmForm from '../password-reset-by-phone-confirm-form/password-reset-by-phone-confirm-form';
import { FormData } from '../password-reset-by-phone-confirm-form/password-reset-by-phone-confirm-form';
import { handleResetPassByPhoneSmsError } from '../../utils/handle-reset-pass-by-phone-sms-error';

type PasswordResetByPhoneConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const PasswordResetByPhoneConfirmModal = ({ isOpen, onClose }: PasswordResetByPhoneConfirmModalProps) => {
  const { t } = useTranslation();
  const [confirmSmsCode, { isLoading, confirmError }] = useResetPaswordSmsCodeMutation({
    selectFromResult: (result) => ({
      ...result,
      confirmError: handleResetPassByPhoneSmsError(result.error)
    })
  });

  const onFormSubmit = async (formData: FormData) => {
    console.log(formData);
    await confirmSmsCode(formData.smsCode);
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('reset-pass.title', 'Восстановление пароля')}
      description={t('reset-pass.sms-confirm-description', 'Введите код из смс')}
    >
      <PasswordResetByPhoneConfirmForm
        onFormSubmit={onFormSubmit}
        isLoading={isLoading}
        error={confirmError}
      />
    </AppModal>
  );
};

export default PasswordResetByPhoneConfirmModal;
