import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './basket-popup-button.scss';
import { useAppSelector, useDispatchedActions } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { getBasketCount } from '../../models/selectors';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { AppFeature } from '@/app-config';

const b = bemCn('basket-popup-button');

const BasketPopupButton = () => {
  const { changeAppModal } = useDispatchedActions();
  const basketCount = useAppSelector(getBasketCount);
  const isAuth = useAppSelector(getIsAuth);
  const { isMobile } = useResponsive();

  const handleClick = () => {
    changeAppModal('basket');
  };

  if (AppFeature.DISABLE_SPORTBOOK || isMobile || !isAuth || basketCount <= 0) {
    return null;
  }

  return (
    <button className={b()} type="button" onClick={handleClick}>
      {!!basketCount && (
        <span className={b('counter')}>{basketCount}</span>
      )}
      <IconSvg className={b('icon')} name='cart' />
      <span className='visually-hidden'>Open basket</span>
    </button>
  );
};


export default BasketPopupButton;
