import { SubmitHandler } from 'react-hook-form';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Button from '@shared/components/button/button';
import * as yup from 'yup';
import Form from '@shared/components/form/form';
import { useTranslation } from 'react-i18next';
import './login-form.scss';
import i18n from '@/shared/i18n/i18n';

const b = bemCn('login-form');

export type LoginFormData = {
  login: string;
  password: string;
}

export type LoginFormError = {
  root?: string;
  login?: string;
  password?: string;
}

const schema = yup
  .object({
    login: yup.string().required(i18n.t('auth.modal.errors.empty-login', 'Введите логин')),
    password: yup.string().required(i18n.t('auth.modal.errors.empty-pass', 'Введите пароль')),
  })
  .required();

const defaultValues: LoginFormData = {
  login: '',
  password: '',
};

type LoginFormProps = {
  className?: string;
  onFormSubmit?: SubmitHandler<LoginFormData>;
  onRegisterClick?: () => void;
  onResetPasswordClick?: () => void;
  error?: LoginFormError;
  loading?: boolean;
}

const LoginForm = (props: LoginFormProps) => {
  const {
    className,
    onFormSubmit = () => undefined,
    onRegisterClick,
    onResetPasswordClick,
    error,
    loading,
  } = props;
  const { t } = useTranslation();

  return (
    <Form<LoginFormData> className={b(null, className)}
      onSubmit={onFormSubmit}
      form={{
        disabled: loading,
        defaultValues,
        schema,
        error,
      }}
    >
      <Form.RootError className={b('error')} />
      <Form.TextField<LoginFormData>
        className={b('field')}
        name="login"
        // placeholder="Телефон или e-mail"
        placeholder={t('auth.modal.login', 'Логин')}
        disabled={loading}
      />
      <Form.TextField<LoginFormData>
        className={b('field')}
        type='password'
        name="password"
        // placeholder="Ваш пароль"
        placeholder={t('auth.modal.password', 'Пароль')}
        disabled={loading}
      />
      <Button className={b('button', { forget: true })}
        variant="text"
        onClick={onResetPasswordClick}
        disabled={loading}
      >
        {t('auth.modal.forget-pass', 'Забыли пароль?')}
      </Button>
      <Button className={b('button')}
        type="submit"
        disabled={loading}
      >
        {t('auth.modal.sign-in', 'Войти')}
      </Button>
      <Button className={b('button')}
        variant="bordered"
        onClick={onRegisterClick}
        disabled={loading}
      >
        {t('auth.modal.registration', 'Зарегистрироваться')}
      </Button>
    </Form>
  );
};

export default LoginForm;
