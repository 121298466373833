import { useTranslation } from 'react-i18next';


import { type Wallet, useUpdateWalletMutation } from '@/modules/user/@x/user-wallet';
import { bemCn } from '@/shared/utils/helpers/bem-cn';

import Button from '@/shared/components/button/button';
import AppModal from '@/shared/components/app-modal/app-modal';
import './extra-wallet-manage-modal.scss';

const b = bemCn('extra-wallet-manage-modal');

type Props = {
  isOpen: boolean;
  onClose: () => void;
  wallet: Wallet & {
    label: string;
    symbol: string;
  };
}

const ExtraWalletManageModal = ({ wallet, isOpen, onClose }: Props) => {
  const [changeWallet] = useUpdateWalletMutation();
  const { t } = useTranslation();

  const updateWallet = async () => {
    await changeWallet(wallet.currency).then(() => onClose());
  };


  return (
    <AppModal className={b()}
      isOpen={isOpen}
      onClose={onClose}
      title={t('wallets.actions.title', 'Операции со счетом')}
      description={t('wallets.actions.descriptions', 'Вы можете сделать')}
    >
      <div className={b('main')}>
        <p className={b('symbol')}>{wallet.symbol}</p>
        <div>
          <p className={b('name')}>{wallet.label}</p>
          <p className={b('amount')}>{wallet.amount}</p>
        </div>
      </div>
      <Button className={b('btn')} onClick={updateWallet}>
        {t('wallets.actions.set-main', 'Сделать основным')}
      </Button>
    </AppModal>
  );
};

export default ExtraWalletManageModal;
