import { bemCn } from '@shared/utils/helpers/bem-cn';
import type { Coupon } from '../../model/types';
import { CouponCard } from '../coupon-card/coupon-card';
import { memo, useMemo } from 'react';
import Loader from '@/shared/components/loader/loader';
import { useTranslation } from 'react-i18next';
import { EmptyPlaceholder } from '@/shared/components/empty-placeholder/empty-placeholder';
import './coupon-list.scss';

const MemoizedCouponCard = memo(CouponCard);

const b = bemCn('coupon-list');

type CouponListProps = {
  className?: string;
  coupons?: Coupon[];
  loading?: boolean;
}

const CouponList = ({ className, coupons = [], loading }: CouponListProps) => {
  const { t } = useTranslation();
  const isEmpty = coupons.length === 0 && !loading;

  const memoizedCoupons = useMemo(() => coupons.map((coupon) => (
    <MemoizedCouponCard className={b('item')}
      key={coupon.id}
      coupon={coupon}
    />
  )), [coupons]);


  return (
    <div className={b({ loading, empty: isEmpty }, className)}>
      {!loading && isEmpty && (
        <EmptyPlaceholder className={b('empty-message')}
          title={t('empty-message.coupons.title')}
          description={t('empty-message.coupons.desc')}
        />
      )}
      {!loading && memoizedCoupons}
      {loading && <Loader className={b('loader')} />}
    </div>
  );
};

export { CouponList };
