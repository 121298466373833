import { useRef, useState } from 'react';
import { CasinoCategoryItem, ProviderItem } from '../../model/selectors';
import { PropsWithCN } from '@shared/types/common';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { SwiperSlide, Swiper, SwiperClass } from 'swiper/react';
import { Controller } from 'swiper/modules';
import Desktop from '@/shared/components/responsive/desktop';
import Mobile from '@/shared/components/responsive/mobile';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import Button from '@shared/components/button/button';
import SearchFilter from '@/shared/components/search-filter/search-filter';
import ProviderBtn from './casino-filter-provider/casino-filter-provider';
import CasinoFilterSort from './casino-filter-sort/casino-filter-sort';
import CasinoFilterSortMobile from './casino-filter-sort-mobile/casino-filter-sort-mobile';

import type { CasinoSortType } from '@/modules/casino/model/types';
import { useScrollToView } from '@/shared/hooks/use-scroll-to-view';
import { useThemeContext } from '@/modules/theme/@x/casino';
import './casino-filter.scss';

type CasinoFilterProps = PropsWithCN & {
  providers: ProviderItem[];
  categories: CasinoCategoryItem[];
  onProviderSelect: (id: string) => void;
  onCategorySelect: (id: string) => void;
  onFavoritesChange: (favorites: boolean) => void;
  onSearch: (search: string) => void;
  selectedProvider: string;
  selectedCategory: string;
  search?: string;
  favorites?: boolean;
  onSort: (sort: CasinoSortType) => void;
  sort?: CasinoSortType;
}

const CasinoFilter = ({
  className,
  providers,
  categories,
  onProviderSelect,
  onCategorySelect,
  selectedProvider,
  selectedCategory,
  onSearch,
  search,
  onSort,
  sort,
  favorites,
  onFavoritesChange,
}: CasinoFilterProps) => {
  const b = bemCn('casino-filter');
  const { isDark } = useThemeContext();
  const filterRef = useRef<HTMLDivElement>(null);
  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null);


  useScrollToView(filterRef, {
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  });

  return (
    <div className={b(null, className)} ref={filterRef}>
      <div className={b('top-wrapper')}>
        <ButtonIcon className={b('btn', { prev: true })}
          variant='secondary'
          icon='arrow-right'
          onClick={() => controlledSwiper?.slidePrev()}
        />
        <ButtonIcon className={b('btn', { next: true })}
          variant='secondary'
          icon='arrow-right'
          onClick={() => controlledSwiper?.slideNext()}
        />

        <SearchFilter className={b('search')}
          search={search}
          onInput={onSearch}
          clearable
          inverted={isDark}
        />
        <ButtonIcon className={b('btn', { favorites: true })}
          variant={favorites ? 'primary' : 'secondary'}
          icon='heart'
          onClick={() => onFavoritesChange(!favorites)}
        />
        {/* <ButtonIcon className={b('btn', { options: true })}
          variant='secondary'
        /> */}

        <Mobile>
          <CasinoFilterSortMobile
            className={b('btn', { options: true })}
            onSort={onSort}
            sort={sort}
          />
        </Mobile>

        <Desktop>
          <CasinoFilterSort
            className={b('btn', { options: true })}
            onSort={onSort}
            sort={sort}
          />
        </Desktop>

      </div>

      <Mobile>
        <div className={b('categories')}>
          {categories.map((item) => (
            <Button className={b('category')}
              key={item.id}
              variant={item.id === selectedCategory ? 'primary' : 'secondary'}
              onClick={() => onCategorySelect(item.id)}
            >
              {item.name}
            </Button>
          ))}
        </div>
      </Mobile>

      <Desktop>
        <div className={b('categories')}>
          <Swiper
            className={b('categories-swiper')}
            // spaceBetween={10}
            slidesPerView="auto"
            modules={[Controller]}
            onSwiper={setControlledSwiper}
            controller={{ control: controlledSwiper }}
          >
            {categories.map((item) => (
              <SwiperSlide key={item.id} className={b('category')}>
                <Button className={b('category-btn')}
                  key={item.id}
                  variant={item.id === selectedCategory ? 'primary' : 'secondary'}
                  onClick={() => onCategorySelect(item.id)}
                >
                  {item.name}
                </Button>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Desktop>

      {/* <Button className={b('category')} variant='secondary'>
          Популярные
        </Button>
        <Button className={b('category')} variant='secondary'>
          New
        </Button> */}
      {/* </div> */}
      {selectedCategory === 'all-providers' && (
        <div className={b('providers')}>
          <div className={b('providers-wrapepr')}>
            {providers.map(({ id, iconUrl: icon, name, counter }) => (
              <ProviderBtn className={b('provider-btn')}
                key={id}
                icon={icon}
                name={name}
                counter={counter}
                active={id === selectedProvider}
                onClick={() => onProviderSelect(id)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CasinoFilter;
