import { Link, useLocation, useNavigate } from 'react-router-dom';
import i18n from '@/shared/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '../icon-svg/icon-svg';
import './breadcrumbs.scss';

type LinkItem = {
  link: string;
  title: string;
  current?: boolean;
};

type BreadcrumbsProps = {
  className?: string;
  linkList?: LinkItem[];
  isGoBack?: boolean;
}

const pathItems: LinkItem[] = [
  { link: '', title: i18n.t('main-nav.home', 'Главная') },
  { link: 'line', title: i18n.t('main-nav.line', 'Линия') },
  { link: 'live', title: i18n.t('main-nav.live', 'Live') },
  { link: 'games', title: i18n.t('main-nav.casino', 'Казино') },
  { link: 'profile', title: i18n.t('main-nav.profile', 'Личный кабинет') },
  { link: 'events', title: i18n.t('main-nav.event', 'События') },
  { link: 'bets-history', title: i18n.t('profile.nav.bet-history', 'История ставок') },
  { link: 'transactions', title: i18n.t('profile.nav.transactions', 'Детализация') },
  { link: 'withdrawals', title: i18n.t('profile.nav.withdrawals', 'Заявки на вывод') },
  { link: 'promocodes', title: i18n.t('profile.nav.promo-codes', 'Промокоды') },
  { link: 'settings', title: i18n.t('profile.nav.settings', 'Настройки') },
  { link: 'rules', title: i18n.t('footer-nav.item.Rules', 'Правила') },
  { link: 'bonuses', title: i18n.t('footer-nav.item.Bonus and actions', 'Бонусы и Акции') },
  { link: 'results', title: i18n.t('main-nav.results', 'Результаты') },
  { link: 'highlights', title: 'Highlights' },
];

const getBreadcrumbsItems = (pathnames: string[], linkList?: LinkItem[]): LinkItem[] => {
  const pathList: LinkItem[] = [];

  pathnames.forEach((path) => {
    const title = pathItems.find((item) => item.link === path)?.title;

    if (title) {
      pathList.push(
        {
          link: path ? `/${path}` : '/',
          title,
        }
      );
    }
  });

  return linkList ? linkList : pathList;
};

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const {
    className,
    linkList,
    isGoBack = false,
  } = props;

  const b = bemCn('breadcrumbs');
  const { t } = useTranslation();
  const location = useLocation();
  const pathnames = location.pathname.split('/');
  const navigate = useNavigate();

  const renderLinks = () => {
    if (isGoBack) {
      return (
        <li key="go-back" className={b('item')} onClick={() => navigate(-1)}>
          <span className={b('item-back')}>{t('go-back', 'Назад')}</span>
        </li>
      );
    }

    const breadcrumbsItems = getBreadcrumbsItems(pathnames, linkList).map((link, index, linkItems) => {
      const isCurrent = link.current || index === linkItems.length - 1;

      return (
        <li key={link.link} className={b('item', { current: isCurrent })}>
          {isCurrent
            ? link.title
            : <Link to={link.link}>{link.title}</Link>}
        </li>
      );
    });

    return breadcrumbsItems;
  };

  return (
    <div className={b(null, className)}>
      <IconSvg className={b('icon')} name="chevron-right" />
      <ul className={b('list')}>
        {renderLinks()}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
