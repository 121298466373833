import { PhoneSelectorValue } from '@/shared/components/phone-selector/phone-selector';
import RegisterForm, { baseSchema, RegisterFormData, RegisterFormError, RegisterFormProps } from './register-form';
import * as yup from 'yup';
import { defaultCountryCode } from '@/shared/utils/constants/phone';
import { AppFeature, ONLY_CURRENCY } from '@/app-config';
import i18n from '@/shared/i18n/i18n';

type RegisterFormDataPhone = RegisterFormData<{
  phone: Omit<PhoneSelectorValue, 'phoneCode'>;
  name?: string;
}>

export type RegisterErrorPhone = RegisterFormError<RegisterFormDataPhone>

const phoneRegExp = /^\+?\d{6,24}$/;

export const schema: yup.ObjectSchema<RegisterFormDataPhone> = baseSchema.shape({
  phone: yup.object({
    phone: yup.string()
      .required(i18n.t('reg.modal.errors.empty-phone', 'Введите номер телефона'))
      .matches(phoneRegExp, i18n.t('reg.modal.errors.wrong-phone', 'Введите корректный телефон')),
    countryCode: yup.string()
      .required('Введите код страны'),
  }).required(),
  name: yup.string().when('$isNameRequired', ([isNameRequired], nameShema) => !isNameRequired
    ? nameShema.optional()
    : nameShema.required('Введите имя')
      .test(
        'is-two-words',
        'Имя должно состоять как минимум из двух слов',
        (value) => value.trim().split(/\s+/).length >= 2
      )),
});

const defaultValues: RegisterFormDataPhone = {
  phone: {
    phone: `+${defaultCountryCode.phoneCode}`,
    countryCode: defaultCountryCode.countryCode,
  },
  name: !AppFeature.KES_PROJECT ? '' : undefined,
  password: '',
  confirmPassword: '',
  currency: ONLY_CURRENCY ?? '',
  agree: false,
};

type RegisterFormByPhoneProps = Omit<RegisterFormProps<RegisterFormDataPhone>, 'form'>

const RegisterFormByPhone = (props: RegisterFormByPhoneProps) => (
  <RegisterForm
    {...props}
    form={{
      defaultValues,
      schema,
      context: { isNameRequired: !AppFeature.KES_PROJECT, }
    }}
  >
    <RegisterForm.Bonus />
    <RegisterForm.Phone />
    {!AppFeature.KES_PROJECT && <RegisterForm.Name />}
  </RegisterForm>
);

export default RegisterFormByPhone;
