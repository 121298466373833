import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
// import IconSvg from '../../../ui/icons/icon-svg/icon-svg';
import { IEventItem, TEventBetItem } from '../../../model/line';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { useAppSelector, useDispatchedActions } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';
import './event-card-desktop.scss';


const b = bemCn('event-card-desktop');

export type EventCardDesktopProps = {
  className?: string;
  event: IEventItem;
  onBetClick?: (bet: TEventBetItem) => void;
}

const EventCardDesktop = (props: EventCardDesktopProps) => {
  const { className, event, onBetClick } = props;
  const {
    id,
    team1,
    team2,
    startDate,
    mainBets,
    dataType,
  } = event;

  const isAuth = useAppSelector(getIsAuth);
  const { changeAuthModal } = useDispatchedActions();
  const date = dayjsCustom(startDate).format('DD.MM.YYYY');
  const dateTime = dayjsCustom(startDate).format('HH:mm');

  const handleBetClick = (bet: TEventBetItem, e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (isAuth) {
      onBetClick?.(bet);
    } else {
      changeAuthModal('login');
    }
  };

  return (
    <div className={b(null, className)}>
      <Link className={b('wrapper')} to={`/${dataType}/${id}`} >
        <p className={b('date')}>
          {date}
          <span className={b('date-time')}>{dateTime}</span>
        </p>
        <div className={b('teams')}>
          <p className={b('team')}>{team1.name}</p>
          <p className={b('team')}>{team2.name}</p>
        </div>
        <div className={b('buttons')}>
          {mainBets.map((item) => (
            <button className={b('button', { bet: true, blocked: item.blocked, active: item.isActive })}
              type='button'
              key={item.id}
              onClick={(e) => handleBetClick(item, e)}
              disabled={item.blocked || item.isActive}
            >
              <span className={b('button-label')}>{item.name}</span>
              <span className={b('button-value')}>{item.rate}</span>
            </button>
          ))}
          {/* <button className={b('button', { stream: true })} >
            <IconSvg className={b('button-icon')} name='live-tv' />
          </button> */}
        </div>
      </Link>
    </div>
  );
};

export default EventCardDesktop;
