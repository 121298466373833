import Button from '@/shared/components/button/button';
import ResultModal from '@/shared/components/result-modal/result-modal';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendEmailVerificationMutation } from '../../model/auth-api';
import { useAppSelector } from '@/shared/hooks';
import { selectVerifyEmail } from '../../model/selectors';
import Loader from '@/shared/components/loader/loader';
import './email-verification-modal.scss';

const b = bemCn('email-verification-modal');

type EmailVerificationModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const EmailVerificationModal: FC<EmailVerificationModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [send, { isLoading }] = useSendEmailVerificationMutation();
  const email = useAppSelector(selectVerifyEmail);

  const handleSendClick = async () => {
    if (email) {
      await send(email);
    }
  };

  return (
    <ResultModal className={b()}
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.email-verification.title')}
      description={t('result-modal.email-verification.description')}
      status='error'
    >
      {isLoading && (
        <Loader className={b('loader')} />
      )}
      <Button className={b('btn')}
        onClick={handleSendClick}
        loading={isLoading}
      >
        {t('result-modal.email-verification.send-button')}
      </Button>
    </ResultModal>
  );
};

export default EmailVerificationModal;
