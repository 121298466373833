import { useAppSelector } from '@/shared/hooks';
import CasinoGamesSection, { CasinoGamesSectionProps } from '../casino-games-section/casino-section';
import { getCasinoGamesByCategory, getCasinoGamesByProvider } from '../../model/selectors';


type CasinoGamesSectionByCategoryProps = Omit<CasinoGamesSectionProps, 'items'> & {
  category?: string;
  provider?: string;
};

const CasinoGamesSectionByCategory = ({ category, provider, link, ...props }: CasinoGamesSectionByCategoryProps) => {
  const gamesByCategory = useAppSelector((state) => getCasinoGamesByCategory(state, category ?? ''));
  const gamesByProvider = useAppSelector((state) => getCasinoGamesByProvider(state, provider ?? ''));

  const games = category ? gamesByCategory : gamesByProvider;

  if (games.length <= 0) {
    return null;
  }

  return (
    <CasinoGamesSection
      {...props}
      items={games}
      link={link ?? `/games?category=${category}`}
    />
  );
};

export default CasinoGamesSectionByCategory;
