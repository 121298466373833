import { PartnerStats } from '../model/types';
import { statsTitle } from './const';

type StatItem = {
  label: string;
  value: string;
}

// type ProviderStat = {
//   name: string;
//   placed: number | null;
//   income: number | null;
//   winnings: number | null;
// }


const mainStatsKeys: (keyof PartnerStats)[] = [
  'income',
  'revshare'
];

const commonStatsKeys: (keyof PartnerStats)[] = [
  'deposits',
  'adminDeposits',
  'betsPlaced',
  'betsWinnings',
  'betsIncome',
  'numClicks',
  'numRegistrations',
  'firstDeposits',
  'currentIncome',
];

// const inbetStatsKeys: (keyof PartnerStats)[] = [
//   'inbetPlaced',
//   'inbetWinnings',
//   'inbetIncome',
// ];

// const vegasStatsKeys: (keyof PartnerStats)[] = [
//   'vegasPlaced',
//   'vegasWinnings',
//   'vegasIncome',
// ];

// const tvbetStatsKeys: (keyof PartnerStats)[] = [
//   'tvbetPlaced',
//   'tvbetWinnings',
//   'tvbetIncome',
// ];

// const pragmaticStatsKeys: (keyof PartnerStats)[] = [
//   'pragmaticPlaced',
//   'pragmaticWinnings',
//   'pragmaticIncome',
// ];

// const mascotStatsKeys: (keyof PartnerStats)[] = [
//   'mascotPlaced',
//   'mascotWinnings',
//   'mascotIncome',
// ];

// const betgamesStatsKeys: (keyof PartnerStats)[] = [
//   'betgamesPlaced',
//   'betgamesWinnings',
//   'betgamesIncome',
// ];

// const agtStatsKeys: (keyof PartnerStats)[] = [
//   'agtPlaced',
//   'agtWinnings',
//   'agtIncome',
// ];

// const casinoStatsKeyss: (keyof PartnerStats)[] = [
//   ...inbetStatsKeys,
//   ...vegasStatsKeys,
//   ...tvbetStatsKeys,
//   ...pragmaticStatsKeys,
//   ...mascotStatsKeys,
//   ...betgamesStatsKeys,
//   ...agtStatsKeys
// ];

const providers = [
  'inbet',
  // 'vegas',
  'tvbet',
  'pragmatic',
  'mascot',
  'betgames',
  'smartsoft',
  'agt',
  'aviatrix',
  'fivemen',
  'endorphina',
  'gosh',
] as const;

// const getMainFromStats = (stats: PartnerStats) => {
//   const temp: Partial<PartnerStats> = {};
//   mainStatsKeys.forEach((key) => {
//     temp[key] = stats[key];
//   });

//   return temp;
// };

// const getCommonFromStats = (stats: PartnerStats) => {
//   const temp: Partial<PartnerStats> = {};
//   commonStatsKeys.forEach((key) => {
//     temp[key] = stats[key];
//   });

//   return temp;
// };

// const getCasinoFromStats = (stats: PartnerStats) => {
//   const temp: Partial<PartnerStats> = {};
//   casinoStatsKeyss.forEach((key) => {
//     temp[key] = stats[key];
//   });

//   return temp;
// };


const getGroupedStats = (
  stats: PartnerStats,
  keys: (keyof PartnerStats)[]
): StatItem[] => stats && keys.map((item) => {
  const val = stats[item] ?? 0;

  return {
    label: statsTitle[item] ?? item,
    value: `${Math.floor(val * 100) / 100}`
  };
});

export const parseTotalStats = (stats: PartnerStats) => {

  const mainStats: StatItem[] = getGroupedStats(stats, mainStatsKeys);
  const commonStats: StatItem[] = getGroupedStats(stats, commonStatsKeys);
  const providersStats = providers.map((provider) => ({
    name: provider,
    placed: stats[`${provider}Placed`],
    income: stats[`${provider}Income`],
    winnings: stats[`${provider}Winnings`]
  }));


  return {
    mainStats,
    commonStats,
    providersStats,
  };
};
