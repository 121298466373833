
// import  {  getCountries, getCountryCallingCode } from 'libphonenumber-js';
// import countryList from 'react-select-country-list';

import { AppFeature } from '@/app-config';

// const phoneCountries = getCountries();

// const countriesPhonName = phoneCountries.map((countryCode) => ({
//   phoneCode: getCountryCallingCode(countryCode),
//   countryCode: countryCode,
//   label: countryList().getLabel(countryCode),
// })).sort((a, b) => a.phoneCode.localeCompare(b.phoneCode));

type PhoneCountryCode = {
  code: string;
  country: string;
}

export const defaultRuPhoneCode: PhoneCountryCode = {
  code: '+7',
  country: 'RU',
};

export const phoneCountryCodes: PhoneCountryCode[] = [
  {
    code: '+1',
    country: 'US',
  },
  {
    code: '+1',
    country: 'CA',
  },
  {
    code: '+1242',
    country: 'BS',
  },
  {
    code: '+1246',
    country: 'BB',
  },
  {
    code: '+1264',
    country: 'AI',
  },
  {
    code: '+1268',
    country: 'AG',
  },
  {
    code: '+1284',
    country: 'VG',
  },
  {
    code: '+1340',
    country: 'VI',
  },
  {
    code: '+1345',
    country: 'KY',
  },
  {
    code: '+1441',
    country: 'BM',
  },
  {
    code: '+1473',
    country: 'GD',
  },
  {
    code: '+1649',
    country: 'TC',
  },
  {
    code: '+1658',
    country: 'JM',
  },
  {
    code: '+1664',
    country: 'MS',
  },
  {
    code: '+1670',
    country: 'MP',
  },
  {
    code: '+1671',
    country: 'GU',
  },
  {
    code: '+1684',
    country: 'AS',
  },
  {
    code: '+1721',
    country: 'SX',
  },
  {
    code: '+1758',
    country: 'LC',
  },
  {
    code: '+1767',
    country: 'DM',
  },
  {
    code: '+1784',
    country: 'VC',
  },
  {
    code: '+1787',
    country: 'PR',
  },
  {
    code: '+1809',
    country: 'DO',
  },
  {
    code: '+1829',
    country: 'DO',
  },
  {
    code: '+1849',
    country: 'DO',
  },
  {
    code: '+1868',
    country: 'TT',
  },
  {
    code: '+1869',
    country: 'KN',
  },
  {
    code: '+1876',
    country: 'JM',
  },
  {
    code: '+1939',
    country: 'PR',
  },
  {
    code: '+20',
    country: 'EG',
  },
  {
    code: '+211',
    country: 'SS',
  },
  {
    code: '+212',
    country: 'MA',
  },
  {
    code: '+212',
    country: 'EH',
  },
  {
    code: '+213',
    country: 'DZ',
  },
  {
    code: '+216',
    country: 'TN',
  },
  {
    code: '+218',
    country: 'LY',
  },
  {
    code: '+220',
    country: 'GM',
  },
  {
    code: '+221',
    country: 'SN',
  },
  {
    code: '+222',
    country: 'MR',
  },
  {
    code: '+223',
    country: 'ML',
  },
  {
    code: '+224',
    country: 'GN',
  },
  {
    code: '+225',
    country: 'CI',
  },
  {
    code: '+226',
    country: 'BF',
  },
  {
    code: '+227',
    country: 'NE',
  },
  {
    code: '+228',
    country: 'TG',
  },
  {
    code: '+229',
    country: 'BJ',
  },
  {
    code: '+230',
    country: 'MU',
  },
  {
    code: '+231',
    country: 'LR',
  },
  {
    code: '+232',
    country: 'SL',
  },
  {
    code: '+233',
    country: 'GH',
  },
  {
    code: '+234',
    country: 'NG',
  },
  {
    code: '+235',
    country: 'TD',
  },
  {
    code: '+236',
    country: 'CF',
  },
  {
    code: '+237',
    country: 'CM',
  },
  {
    code: '+238',
    country: 'CV',
  },
  {
    code: '+239',
    country: 'ST',
  },
  {
    code: '+240',
    country: 'GQ',
  },
  {
    code: '+241',
    country: 'GA',
  },
  {
    code: '+242',
    country: 'CG',
  },
  {
    code: '+243',
    country: 'CD',
  },
  {
    code: '+244',
    country: 'AO',
  },
  {
    code: '+245',
    country: 'GW',
  },
  {
    code: '+246',
    country: 'IO',
  },
  {
    code: '+247',
    country: 'AC', // Не страна
  },
  {
    code: '+248',
    country: 'SC',
  },
  {
    code: '+249',
    country: 'SD',
  },
  {
    code: '+250',
    country: 'RW',
  },
  {
    code: '+251',
    country: 'ET',
  },
  {
    code: '+252',
    country: 'SO',
  },
  {
    code: '+253',
    country: 'DJ',
  },
  {
    code: '+254',
    country: 'KE',
  },
  {
    code: '+255',
    country: 'TZ',
  },
  {
    code: '+256',
    country: 'UG',
  },
  {
    code: '+257',
    country: 'BI',
  },
  {
    code: '+258',
    country: 'MZ',
  },
  {
    code: '+260',
    country: 'ZM',
  },
  {
    code: '+261',
    country: 'MG',
  },
  {
    code: '+262',
    country: 'RE',
  },
  {
    code: '+262',
    country: 'YT',
  },
  {
    code: '+262',
    country: 'TF',
  },
  {
    code: '+263',
    country: 'ZW',
  },
  {
    code: '+264',
    country: 'NA',
  },
  {
    code: '+265',
    country: 'MW',
  },
  {
    code: '+266',
    country: 'LS',
  },
  {
    code: '+267',
    country: 'BW',
  },
  {
    code: '+268',
    country: 'SZ',
  },
  {
    code: '+269',
    country: 'KM',
  },
  {
    code: '+27',
    country: 'ZA',
  },
  {
    code: '+290',
    country: 'SH',
  },
  {
    code: '+290',
    country: 'TA',
  },
  {
    code: '+291',
    country: 'ER',
  },
  {
    code: '+297',
    country: 'AW',
  },
  {
    code: '+298',
    country: 'FO',
  },
  {
    code: '+299',
    country: 'GL',
  },
  {
    code: '+30',
    country: 'GR',
  },
  {
    code: '+31',
    country: 'NL',
  },
  {
    code: '+32',
    country: 'BE',
  },
  {
    code: '+33',
    country: 'FR',
  },
  {
    code: '+34',
    country: 'ES',
  },
  {
    code: '+350',
    country: 'GI',
  },
  {
    code: '+351',
    country: 'PT',
  },
  {
    code: '+352',
    country: 'LU',
  },
  {
    code: '+353',
    country: 'IE',
  },
  {
    code: '+354',
    country: 'IS',
  },
  {
    code: '+355',
    country: 'AL',
  },
  {
    code: '+356',
    country: 'MT',
  },
  {
    code: '+357',
    country: 'CY',
  },
  {
    code: '+358',
    country: 'FI',
  },
  {
    code: '+358',
    country: 'AX',
  },
  {
    code: '+359',
    country: 'BG',
  },
  {
    code: '+36',
    country: 'HU',
  },
  {
    code: '+370',
    country: 'LT',
  },
  {
    code: '+371',
    country: 'LV',
  },
  {
    code: '+372',
    country: 'EE',
  },
  {
    code: '+373',
    country: 'MD',
  },
  {
    code: '+374',
    country: 'AM',
  },
  {
    code: '+375',
    country: 'BY',
  },
  {
    code: '+376',
    country: 'AD',
  },
  {
    code: '+377',
    country: 'MC',
  },
  {
    code: '+378',
    country: 'SM',
  },
  {
    code: '+379',
    country: 'VA',
  },
  {
    code: '+380',
    country: 'UA',
  },
  {
    code: '+381',
    country: 'RS',
  },
  {
    code: '+382',
    country: 'ME',
  },
  {
    code: '+383',
    country: 'XK',
  },
  {
    code: '+385',
    country: 'HR',
  },
  {
    code: '+386',
    country: 'SI',
  }, {
    code: '+387',
    country: 'BA',
  },
  {
    code: '+388',
    country: 'EU', // ! Европейская система телефонной нумерации
  },
  {
    code: '+389',
    country: 'MK',
  },
  {
    code: '+39',
    country: 'IT',
  },
  {
    code: '+39',
    country: 'VA',
  },
  {
    code: '+40',
    country: 'RO',
  }, {
    code: '+41',
    country: 'CH',
  },
  {
    code: '+420',
    country: 'CZ',
  },
  {
    code: '+421',
    country: 'SK',
  }, {
    code: '+423',
    country: 'LI',
  },
  {
    code: '+43',
    country: 'AT',
  },
  {
    code: '+44',
    country: 'UK',
  }, {
    code: '+44',
    country: 'GG',
  },
  {
    code: '+44',
    country: 'IM',
  },
  {
    code: '+44',
    country: 'JE',
  }, {
    code: '+45',
    country: 'DK',
  },
  {
    code: '+46',
    country: 'SE',
  },
  {
    code: '+47',
    country: 'NO',
  }, {
    code: '+47',
    country: 'SJ',
  },
  {
    code: '+47',
    country: 'BV',
  },
  {
    code: '+48',
    country: 'PL',
  }, {
    code: '+49',
    country: 'DE',
  },
  {
    code: '+500',
    country: 'FK',
  },
  {
    code: '+500',
    country: 'GS',
  },
  {
    code: '+501',
    country: 'BZ',
  }, {
    code: '+502',
    country: 'GT',
  },
  {
    code: '+503',
    country: 'SV',
  },
  {
    code: '+504',
    country: 'HN',
  }, {
    code: '+505',
    country: 'NI',
  },
  {
    code: '+506',
    country: 'CR',
  },
  {
    code: '+507',
    country: 'PA',
  }, {
    code: '+508',
    country: 'PM',
  },
  {
    code: '+509',
    country: 'HT',
  },
  {
    code: '+51',
    country: 'PE',
  },
  {
    code: '+52',
    country: 'MX',
  },
  {
    code: '+53',
    country: 'CU',
  },
  {
    code: '+54',
    country: 'AR',
  }, {
    code: '+55',
    country: 'BR',
  },
  {
    code: '+56',
    country: 'CL',
  },
  {
    code: '+57',
    country: 'CO',
  }, {
    code: '+58',
    country: 'VE',
  },
  {
    code: '+590',
    country: 'GP',
  },
  {
    code: '+590',
    country: 'BL',
  }, {
    code: '+590',
    country: 'MF',
  },
  {
    code: '+591',
    country: 'BO',
  },
  {
    code: '+592',
    country: 'GY',
  }, {
    code: '+593',
    country: 'EC',
  },
  {
    code: '+594',
    country: 'GF',
  },
  {
    code: '+595',
    country: 'PY',
  }, {
    code: '+596',
    country: 'MQ',
  },
  {
    code: '+597',
    country: 'SR',
  },
  {
    code: '+598',
    country: 'UY',
  }, {
    code: '+599',
    country: 'BQ',
  },
  {
    code: '+599',
    country: 'CW',
  },
  {
    code: '+60',
    country: 'MY',
  }, {
    code: '+61',
    country: 'AU',
  },
  {
    code: '+61',
    country: 'CX',
  },
  {
    code: '+61',
    country: 'CC',
  }, {
    code: '+62',
    country: 'ID',
  },
  {
    code: '+63',
    country: 'PH',
  },
  {
    code: '+64',
    country: 'NZ',
  }, {
    code: '+64',
    country: 'PN',
  },
  {
    code: '+65',
    country: 'SG',
  },
  {
    code: '+66',
    country: 'TH',
  }, {
    code: '+670',
    country: 'TL',
  },
  {
    code: '+672',
    country: 'AQ',
  },
  {
    code: '+672',
    country: 'NF',
  },
  {
    code: '+672',
    country: 'HM',
  },
  {
    code: '+673',
    country: 'BN',
  },
  {
    code: '+674',
    country: 'NR',
  }, {
    code: '+675',
    country: 'PG',
  },
  {
    code: '+676',
    country: 'TO',
  },
  {
    code: '+677',
    country: 'SB',
  }, {
    code: '+678',
    country: 'VU',
  },
  {
    code: '+679',
    country: 'FJ',
  },
  {
    code: '+680',
    country: 'PW',
  }, {
    code: '+681',
    country: 'WF',
  },
  {
    code: '+682',
    country: 'CK',
  },
  {
    code: '+683',
    country: 'NU',
  }, {
    code: '+685',
    country: 'WS',
  },
  {
    code: '+686',
    country: 'KI',
  },
  {
    code: '+687',
    country: 'NC',
  }, {
    code: '+688',
    country: 'TV',
  },
  {
    code: '+689',
    country: 'PF',
  },
  {
    code: '+690',
    country: 'TK',
  }, {
    code: '+691',
    country: 'FM',
  },
  {
    code: '+692',
    country: 'MH',
  },
  {
    code: '+7',
    country: 'RU',
  },
  {
    code: '+7',
    country: 'KZ',
  },
  {
    code: '+81',
    country: 'JP',
  },
  {
    code: '+82',
    country: 'KR',
  },
  {
    code: '+84',
    country: 'VN',
  },
  {
    code: '+850',
    country: 'KP',
  },
  {
    code: '+852',
    country: 'HK',
  },
  {
    code: '+853',
    country: 'MO',
  }, {
    code: '+855',
    country: 'KH',
  },
  {
    code: '+856',
    country: 'LA',
  },
  {
    code: '+86',
    country: 'CH',
  },
  {
    code: '+880',
    country: 'BD',
  },
  {
    code: '+886',
    country: 'TW',
  }, {
    code: '+90',
    country: 'TR',
  },
  {
    code: '+91',
    country: 'IN',
  },
  {
    code: '+92',
    country: 'PK',
  },
  {
    code: '+93',
    country: 'AF',
  }, {
    code: '+94',
    country: 'LK',
  },
  {
    code: '+95',
    country: 'MM',
  },
  {
    code: '+960',
    country: 'MV',
  }, {
    code: '+961',
    country: 'LB',
  },
  {
    code: '+962',
    country: 'JO',
  },
  {
    code: '+963',
    country: 'SY',
  },
  {
    code: '+964',
    country: 'IQ',
  }, {
    code: '+965',
    country: 'KW',
  },
  {
    code: '+966',
    country: 'SA',
  },
  {
    code: '+967',
    country: 'YE',
  }, {
    code: '+968',
    country: 'OM',
  },
  {
    code: '+970',
    country: 'PS',
  },
  {
    code: '+971',
    country: 'AE',
  },
  {
    code: '+972',
    country: 'IL',
  }, {
    code: '+973',
    country: 'BH',
  },
  {
    code: '+974',
    country: 'QA',
  },
  {
    code: '+975',
    country: 'BT',
  }, {
    code: '+976',
    country: 'MN',
  },
  {
    code: '+977',
    country: 'NP',
  },
  {
    code: '+98',
    country: 'IR',
  },
  {
    code: '+992',
    country: 'TJ',
  },
  {
    code: '+993',
    country: 'TM',
  }, {
    code: '+994',
    country: 'AZ',
  },
  {
    code: '+995',
    country: 'GE',
  },
  {
    code: '+996',
    country: 'KG',
  },
  {
    code: '+997',
    country: 'KZ',
  }, {
    code: '+998',
    country: 'UZ',
  },
];

export type CountryPhone = {
  phoneCode: string;
  countryCode: string;
  label: string;
}

const KE_PHONE_CODE: CountryPhone = {
  'phoneCode': '254',
  'countryCode': 'KE',
  'label': 'Kenya'
};

const RU_PHONE_CODE: CountryPhone = {
  'phoneCode': '7',
  'countryCode': 'RU',
  'label': 'Russian Federation'
};

export const defaultCountryCode: CountryPhone = AppFeature.KES_PROJECT
  ? KE_PHONE_CODE
  : RU_PHONE_CODE;

export const countryCodes: CountryPhone[] = [
  {
    'phoneCode': '1',
    'countryCode': 'AG',
    'label': 'Antigua and Barbuda'
  },
  {
    'phoneCode': '1',
    'countryCode': 'AI',
    'label': 'Anguilla'
  },
  {
    'phoneCode': '1',
    'countryCode': 'AS',
    'label': 'American Samoa'
  },
  {
    'phoneCode': '1',
    'countryCode': 'BB',
    'label': 'Barbados'
  },
  {
    'phoneCode': '1',
    'countryCode': 'BM',
    'label': 'Bermuda'
  },
  {
    'phoneCode': '1',
    'countryCode': 'BS',
    'label': 'Bahamas'
  },
  {
    'phoneCode': '1',
    'countryCode': 'CA',
    'label': 'Canada'
  },
  {
    'phoneCode': '1',
    'countryCode': 'DM',
    'label': 'Dominica'
  },
  {
    'phoneCode': '1',
    'countryCode': 'DO',
    'label': 'Dominican Republic'
  },
  {
    'phoneCode': '1',
    'countryCode': 'GD',
    'label': 'Grenada'
  },
  {
    'phoneCode': '1',
    'countryCode': 'GU',
    'label': 'Guam'
  },
  {
    'phoneCode': '1',
    'countryCode': 'JM',
    'label': 'Jamaica'
  },
  {
    'phoneCode': '1',
    'countryCode': 'KN',
    'label': 'Saint Kitts and Nevis'
  },
  {
    'phoneCode': '1',
    'countryCode': 'KY',
    'label': 'Cayman Islands'
  },
  {
    'phoneCode': '1',
    'countryCode': 'LC',
    'label': 'Saint Lucia'
  },
  {
    'phoneCode': '1',
    'countryCode': 'MP',
    'label': 'Northern Mariana Islands'
  },
  {
    'phoneCode': '1',
    'countryCode': 'MS',
    'label': 'Montserrat'
  },
  {
    'phoneCode': '1',
    'countryCode': 'PR',
    'label': 'Puerto Rico'
  },
  {
    'phoneCode': '1',
    'countryCode': 'SX',
    'label': 'Sint Maarten (Dutch part)'
  },
  {
    'phoneCode': '1',
    'countryCode': 'TC',
    'label': 'Turks and Caicos Islands'
  },
  {
    'phoneCode': '1',
    'countryCode': 'TT',
    'label': 'Trinidad and Tobago'
  },
  {
    'phoneCode': '1',
    'countryCode': 'US',
    'label': 'United States'
  },
  {
    'phoneCode': '1',
    'countryCode': 'VC',
    'label': 'Saint Vincent and the Grenadines'
  },
  {
    'phoneCode': '1',
    'countryCode': 'VG',
    'label': 'Virgin Islands, British'
  },
  {
    'phoneCode': '1',
    'countryCode': 'VI',
    'label': 'Virgin Islands, U.S.'
  },
  {
    'phoneCode': '20',
    'countryCode': 'EG',
    'label': 'Egypt'
  },
  {
    'phoneCode': '211',
    'countryCode': 'SS',
    'label': 'South Sudan'
  },
  {
    'phoneCode': '212',
    'countryCode': 'EH',
    'label': 'Western Sahara'
  },
  {
    'phoneCode': '212',
    'countryCode': 'MA',
    'label': 'Morocco'
  },
  {
    'phoneCode': '213',
    'countryCode': 'DZ',
    'label': 'Algeria'
  },
  {
    'phoneCode': '216',
    'countryCode': 'TN',
    'label': 'Tunisia'
  },
  {
    'phoneCode': '218',
    'countryCode': 'LY',
    'label': 'Libya'
  },
  {
    'phoneCode': '220',
    'countryCode': 'GM',
    'label': 'Gambia'
  },
  {
    'phoneCode': '221',
    'countryCode': 'SN',
    'label': 'Senegal'
  },
  {
    'phoneCode': '222',
    'countryCode': 'MR',
    'label': 'Mauritania'
  },
  {
    'phoneCode': '223',
    'countryCode': 'ML',
    'label': 'Mali'
  },
  {
    'phoneCode': '224',
    'countryCode': 'GN',
    'label': 'Guinea'
  },
  {
    'phoneCode': '225',
    'countryCode': 'CI',
    'label': 'Côte d\'Ivoire'
  },
  {
    'phoneCode': '226',
    'countryCode': 'BF',
    'label': 'Burkina Faso'
  },
  {
    'phoneCode': '227',
    'countryCode': 'NE',
    'label': 'Niger'
  },
  {
    'phoneCode': '228',
    'countryCode': 'TG',
    'label': 'Togo'
  },
  {
    'phoneCode': '229',
    'countryCode': 'BJ',
    'label': 'Benin'
  },
  {
    'phoneCode': '230',
    'countryCode': 'MU',
    'label': 'Mauritius'
  },
  {
    'phoneCode': '231',
    'countryCode': 'LR',
    'label': 'Liberia'
  },
  {
    'phoneCode': '232',
    'countryCode': 'SL',
    'label': 'Sierra Leone'
  },
  {
    'phoneCode': '233',
    'countryCode': 'GH',
    'label': 'Ghana'
  },
  {
    'phoneCode': '234',
    'countryCode': 'NG',
    'label': 'Nigeria'
  },
  {
    'phoneCode': '235',
    'countryCode': 'TD',
    'label': 'Chad'
  },
  {
    'phoneCode': '236',
    'countryCode': 'CF',
    'label': 'Central African Republic'
  },
  {
    'phoneCode': '237',
    'countryCode': 'CM',
    'label': 'Cameroon'
  },
  {
    'phoneCode': '238',
    'countryCode': 'CV',
    'label': 'Cabo Verde'
  },
  {
    'phoneCode': '239',
    'countryCode': 'ST',
    'label': 'Sao Tome and Principe'
  },
  {
    'phoneCode': '240',
    'countryCode': 'GQ',
    'label': 'Equatorial Guinea'
  },
  {
    'phoneCode': '241',
    'countryCode': 'GA',
    'label': 'Gabon'
  },
  {
    'phoneCode': '242',
    'countryCode': 'CG',
    'label': 'Congo'
  },
  {
    'phoneCode': '243',
    'countryCode': 'CD',
    'label': 'Congo, Democratic Republic of the'
  },
  {
    'phoneCode': '244',
    'countryCode': 'AO',
    'label': 'Angola'
  },
  {
    'phoneCode': '245',
    'countryCode': 'GW',
    'label': 'Guinea-Bissau'
  },
  {
    'phoneCode': '246',
    'countryCode': 'IO',
    'label': 'British Indian Ocean Territory'
  },
  // {
  //   'phoneCode': '247',
  //   'countryCode': 'AC'
  // },
  {
    'phoneCode': '248',
    'countryCode': 'SC',
    'label': 'Seychelles'
  },
  {
    'phoneCode': '249',
    'countryCode': 'SD',
    'label': 'Sudan'
  },
  {
    'phoneCode': '250',
    'countryCode': 'RW',
    'label': 'Rwanda'
  },
  {
    'phoneCode': '251',
    'countryCode': 'ET',
    'label': 'Ethiopia'
  },
  {
    'phoneCode': '252',
    'countryCode': 'SO',
    'label': 'Somalia'
  },
  {
    'phoneCode': '253',
    'countryCode': 'DJ',
    'label': 'Djibouti'
  },
  {
    'phoneCode': '254',
    'countryCode': 'KE',
    'label': 'Kenya'
  },
  {
    'phoneCode': '255',
    'countryCode': 'TZ',
    'label': 'Tanzania, United Republic of'
  },
  {
    'phoneCode': '256',
    'countryCode': 'UG',
    'label': 'Uganda'
  },
  {
    'phoneCode': '257',
    'countryCode': 'BI',
    'label': 'Burundi'
  },
  {
    'phoneCode': '258',
    'countryCode': 'MZ',
    'label': 'Mozambique'
  },
  {
    'phoneCode': '260',
    'countryCode': 'ZM',
    'label': 'Zambia'
  },
  {
    'phoneCode': '261',
    'countryCode': 'MG',
    'label': 'Madagascar'
  },
  {
    'phoneCode': '262',
    'countryCode': 'RE',
    'label': 'Réunion'
  },
  {
    'phoneCode': '262',
    'countryCode': 'YT',
    'label': 'Mayotte'
  },
  {
    'phoneCode': '263',
    'countryCode': 'ZW',
    'label': 'Zimbabwe'
  },
  {
    'phoneCode': '264',
    'countryCode': 'NA',
    'label': 'Namibia'
  },
  {
    'phoneCode': '265',
    'countryCode': 'MW',
    'label': 'Malawi'
  },
  {
    'phoneCode': '266',
    'countryCode': 'LS',
    'label': 'Lesotho'
  },
  {
    'phoneCode': '267',
    'countryCode': 'BW',
    'label': 'Botswana'
  },
  {
    'phoneCode': '268',
    'countryCode': 'SZ',
    'label': 'Eswatini'
  },
  {
    'phoneCode': '269',
    'countryCode': 'KM',
    'label': 'Comoros'
  },
  {
    'phoneCode': '27',
    'countryCode': 'ZA',
    'label': 'South Africa'
  },
  {
    'phoneCode': '290',
    'countryCode': 'SH',
    'label': 'Saint Helena, Ascension and Tristan da Cunha'
  },
  // {
  //   'phoneCode': '290',
  //   'countryCode': 'TA'
  // },
  {
    'phoneCode': '291',
    'countryCode': 'ER',
    'label': 'Eritrea'
  },
  {
    'phoneCode': '297',
    'countryCode': 'AW',
    'label': 'Aruba'
  },
  {
    'phoneCode': '298',
    'countryCode': 'FO',
    'label': 'Faroe Islands'
  },
  {
    'phoneCode': '299',
    'countryCode': 'GL',
    'label': 'Greenland'
  },
  {
    'phoneCode': '30',
    'countryCode': 'GR',
    'label': 'Greece'
  },
  {
    'phoneCode': '31',
    'countryCode': 'NL',
    'label': 'Netherlands'
  },
  {
    'phoneCode': '32',
    'countryCode': 'BE',
    'label': 'Belgium'
  },
  {
    'phoneCode': '33',
    'countryCode': 'FR',
    'label': 'France'
  },
  {
    'phoneCode': '34',
    'countryCode': 'ES',
    'label': 'Spain'
  },
  {
    'phoneCode': '350',
    'countryCode': 'GI',
    'label': 'Gibraltar'
  },
  {
    'phoneCode': '351',
    'countryCode': 'PT',
    'label': 'Portugal'
  },
  {
    'phoneCode': '352',
    'countryCode': 'LU',
    'label': 'Luxembourg'
  },
  {
    'phoneCode': '353',
    'countryCode': 'IE',
    'label': 'Ireland'
  },
  {
    'phoneCode': '354',
    'countryCode': 'IS',
    'label': 'Iceland'
  },
  {
    'phoneCode': '355',
    'countryCode': 'AL',
    'label': 'Albania'
  },
  {
    'phoneCode': '356',
    'countryCode': 'MT',
    'label': 'Malta'
  },
  {
    'phoneCode': '357',
    'countryCode': 'CY',
    'label': 'Cyprus'
  },
  {
    'phoneCode': '358',
    'countryCode': 'AX',
    'label': 'Åland Islands'
  },
  {
    'phoneCode': '358',
    'countryCode': 'FI',
    'label': 'Finland'
  },
  {
    'phoneCode': '359',
    'countryCode': 'BG',
    'label': 'Bulgaria'
  },
  {
    'phoneCode': '36',
    'countryCode': 'HU',
    'label': 'Hungary'
  },
  {
    'phoneCode': '370',
    'countryCode': 'LT',
    'label': 'Lithuania'
  },
  {
    'phoneCode': '371',
    'countryCode': 'LV',
    'label': 'Latvia'
  },
  {
    'phoneCode': '372',
    'countryCode': 'EE',
    'label': 'Estonia'
  },
  {
    'phoneCode': '373',
    'countryCode': 'MD',
    'label': 'Moldova, Republic of'
  },
  {
    'phoneCode': '374',
    'countryCode': 'AM',
    'label': 'Armenia'
  },
  {
    'phoneCode': '375',
    'countryCode': 'BY',
    'label': 'Belarus'
  },
  {
    'phoneCode': '376',
    'countryCode': 'AD',
    'label': 'Andorra'
  },
  {
    'phoneCode': '377',
    'countryCode': 'MC',
    'label': 'Monaco'
  },
  {
    'phoneCode': '378',
    'countryCode': 'SM',
    'label': 'San Marino'
  },
  {
    'phoneCode': '380',
    'countryCode': 'UA',
    'label': 'Ukraine'
  },
  {
    'phoneCode': '381',
    'countryCode': 'RS',
    'label': 'Serbia'
  },
  {
    'phoneCode': '382',
    'countryCode': 'ME',
    'label': 'Montenegro'
  },
  // {
  //   'phoneCode': '383',
  //   'countryCode': 'XK'
  // },
  {
    'phoneCode': '385',
    'countryCode': 'HR',
    'label': 'Croatia'
  },
  {
    'phoneCode': '386',
    'countryCode': 'SI',
    'label': 'Slovenia'
  },
  {
    'phoneCode': '387',
    'countryCode': 'BA',
    'label': 'Bosnia and Herzegovina'
  },
  {
    'phoneCode': '389',
    'countryCode': 'MK',
    'label': 'North Macedonia'
  },
  {
    'phoneCode': '39',
    'countryCode': 'IT',
    'label': 'Italy'
  },
  {
    'phoneCode': '39',
    'countryCode': 'VA',
    'label': 'Holy See'
  },
  {
    'phoneCode': '40',
    'countryCode': 'RO',
    'label': 'Romania'
  },
  {
    'phoneCode': '41',
    'countryCode': 'CH',
    'label': 'Switzerland'
  },
  {
    'phoneCode': '420',
    'countryCode': 'CZ',
    'label': 'Czechia'
  },
  {
    'phoneCode': '421',
    'countryCode': 'SK',
    'label': 'Slovakia'
  },
  {
    'phoneCode': '423',
    'countryCode': 'LI',
    'label': 'Liechtenstein'
  },
  {
    'phoneCode': '43',
    'countryCode': 'AT',
    'label': 'Austria'
  },
  {
    'phoneCode': '44',
    'countryCode': 'GB',
    'label': 'United Kingdom'
  },
  {
    'phoneCode': '44',
    'countryCode': 'GG',
    'label': 'Guernsey'
  },
  {
    'phoneCode': '44',
    'countryCode': 'IM',
    'label': 'Isle of Man'
  },
  {
    'phoneCode': '44',
    'countryCode': 'JE',
    'label': 'Jersey'
  },
  {
    'phoneCode': '45',
    'countryCode': 'DK',
    'label': 'Denmark'
  },
  {
    'phoneCode': '46',
    'countryCode': 'SE',
    'label': 'Sweden'
  },
  {
    'phoneCode': '47',
    'countryCode': 'NO',
    'label': 'Norway'
  },
  {
    'phoneCode': '47',
    'countryCode': 'SJ',
    'label': 'Svalbard and Jan Mayen'
  },
  {
    'phoneCode': '48',
    'countryCode': 'PL',
    'label': 'Poland'
  },
  {
    'phoneCode': '49',
    'countryCode': 'DE',
    'label': 'Germany'
  },
  {
    'phoneCode': '500',
    'countryCode': 'FK',
    'label': 'Falkland Islands (Malvinas)'
  },
  {
    'phoneCode': '501',
    'countryCode': 'BZ',
    'label': 'Belize'
  },
  {
    'phoneCode': '502',
    'countryCode': 'GT',
    'label': 'Guatemala'
  },
  {
    'phoneCode': '503',
    'countryCode': 'SV',
    'label': 'El Salvador'
  },
  {
    'phoneCode': '504',
    'countryCode': 'HN',
    'label': 'Honduras'
  },
  {
    'phoneCode': '505',
    'countryCode': 'NI',
    'label': 'Nicaragua'
  },
  {
    'phoneCode': '506',
    'countryCode': 'CR',
    'label': 'Costa Rica'
  },
  {
    'phoneCode': '507',
    'countryCode': 'PA',
    'label': 'Panama'
  },
  {
    'phoneCode': '508',
    'countryCode': 'PM',
    'label': 'Saint Pierre and Miquelon'
  },
  {
    'phoneCode': '509',
    'countryCode': 'HT',
    'label': 'Haiti'
  },
  {
    'phoneCode': '51',
    'countryCode': 'PE',
    'label': 'Peru'
  },
  {
    'phoneCode': '52',
    'countryCode': 'MX',
    'label': 'Mexico'
  },
  {
    'phoneCode': '53',
    'countryCode': 'CU',
    'label': 'Cuba'
  },
  {
    'phoneCode': '54',
    'countryCode': 'AR',
    'label': 'Argentina'
  },
  {
    'phoneCode': '55',
    'countryCode': 'BR',
    'label': 'Brazil'
  },
  {
    'phoneCode': '56',
    'countryCode': 'CL',
    'label': 'Chile'
  },
  {
    'phoneCode': '57',
    'countryCode': 'CO',
    'label': 'Colombia'
  },
  {
    'phoneCode': '58',
    'countryCode': 'VE',
    'label': 'Venezuela, Bolivarian Republic of'
  },
  {
    'phoneCode': '590',
    'countryCode': 'BL',
    'label': 'Saint Barthélemy'
  },
  {
    'phoneCode': '590',
    'countryCode': 'GP',
    'label': 'Guadeloupe'
  },
  {
    'phoneCode': '590',
    'countryCode': 'MF',
    'label': 'Saint Martin (French part)'
  },
  {
    'phoneCode': '591',
    'countryCode': 'BO',
    'label': 'Bolivia, Plurinational State of'
  },
  {
    'phoneCode': '592',
    'countryCode': 'GY',
    'label': 'Guyana'
  },
  {
    'phoneCode': '593',
    'countryCode': 'EC',
    'label': 'Ecuador'
  },
  {
    'phoneCode': '594',
    'countryCode': 'GF',
    'label': 'French Guiana'
  },
  {
    'phoneCode': '595',
    'countryCode': 'PY',
    'label': 'Paraguay'
  },
  {
    'phoneCode': '596',
    'countryCode': 'MQ',
    'label': 'Martinique'
  },
  {
    'phoneCode': '597',
    'countryCode': 'SR',
    'label': 'Suriname'
  },
  {
    'phoneCode': '598',
    'countryCode': 'UY',
    'label': 'Uruguay'
  },
  {
    'phoneCode': '599',
    'countryCode': 'BQ',
    'label': 'Bonaire, Sint Eustatius and Saba'
  },
  {
    'phoneCode': '599',
    'countryCode': 'CW',
    'label': 'Curaçao'
  },
  {
    'phoneCode': '60',
    'countryCode': 'MY',
    'label': 'Malaysia'
  },
  {
    'phoneCode': '61',
    'countryCode': 'AU',
    'label': 'Australia'
  },
  {
    'phoneCode': '61',
    'countryCode': 'CC',
    'label': 'Cocos (Keeling) Islands'
  },
  {
    'phoneCode': '61',
    'countryCode': 'CX',
    'label': 'Christmas Island'
  },
  {
    'phoneCode': '62',
    'countryCode': 'ID',
    'label': 'Indonesia'
  },
  {
    'phoneCode': '63',
    'countryCode': 'PH',
    'label': 'Philippines'
  },
  {
    'phoneCode': '64',
    'countryCode': 'NZ',
    'label': 'New Zealand'
  },
  {
    'phoneCode': '65',
    'countryCode': 'SG',
    'label': 'Singapore'
  },
  {
    'phoneCode': '66',
    'countryCode': 'TH',
    'label': 'Thailand'
  },
  {
    'phoneCode': '670',
    'countryCode': 'TL',
    'label': 'Timor-Leste'
  },
  {
    'phoneCode': '672',
    'countryCode': 'NF',
    'label': 'Norfolk Island'
  },
  {
    'phoneCode': '673',
    'countryCode': 'BN',
    'label': 'Brunei Darussalam'
  },
  {
    'phoneCode': '674',
    'countryCode': 'NR',
    'label': 'Nauru'
  },
  {
    'phoneCode': '675',
    'countryCode': 'PG',
    'label': 'Papua New Guinea'
  },
  {
    'phoneCode': '676',
    'countryCode': 'TO',
    'label': 'Tonga'
  },
  {
    'phoneCode': '677',
    'countryCode': 'SB',
    'label': 'Solomon Islands'
  },
  {
    'phoneCode': '678',
    'countryCode': 'VU',
    'label': 'Vanuatu'
  },
  {
    'phoneCode': '679',
    'countryCode': 'FJ',
    'label': 'Fiji'
  },
  {
    'phoneCode': '680',
    'countryCode': 'PW',
    'label': 'Palau'
  },
  {
    'phoneCode': '681',
    'countryCode': 'WF',
    'label': 'Wallis and Futuna'
  },
  {
    'phoneCode': '682',
    'countryCode': 'CK',
    'label': 'Cook Islands'
  },
  {
    'phoneCode': '683',
    'countryCode': 'NU',
    'label': 'Niue'
  },
  {
    'phoneCode': '685',
    'countryCode': 'WS',
    'label': 'Samoa'
  },
  {
    'phoneCode': '686',
    'countryCode': 'KI',
    'label': 'Kiribati'
  },
  {
    'phoneCode': '687',
    'countryCode': 'NC',
    'label': 'New Caledonia'
  },
  {
    'phoneCode': '688',
    'countryCode': 'TV',
    'label': 'Tuvalu'
  },
  {
    'phoneCode': '689',
    'countryCode': 'PF',
    'label': 'French Polynesia'
  },
  {
    'phoneCode': '690',
    'countryCode': 'TK',
    'label': 'Tokelau'
  },
  {
    'phoneCode': '691',
    'countryCode': 'FM',
    'label': 'Micronesia, Federated States of'
  },
  {
    'phoneCode': '692',
    'countryCode': 'MH',
    'label': 'Marshall Islands'
  },
  {
    'phoneCode': '7',
    'countryCode': 'KZ',
    'label': 'Kazakhstan'
  },
  {
    'phoneCode': '7',
    'countryCode': 'RU',
    'label': 'Russian Federation'
  },
  {
    'phoneCode': '81',
    'countryCode': 'JP',
    'label': 'Japan'
  },
  {
    'phoneCode': '82',
    'countryCode': 'KR',
    'label': 'Korea, Republic of'
  },
  {
    'phoneCode': '84',
    'countryCode': 'VN',
    'label': 'Viet Nam'
  },
  {
    'phoneCode': '850',
    'countryCode': 'KP',
    'label': 'Korea, Democratic People\'s Republic of'
  },
  {
    'phoneCode': '852',
    'countryCode': 'HK',
    'label': 'Hong Kong'
  },
  {
    'phoneCode': '853',
    'countryCode': 'MO',
    'label': 'Macao'
  },
  {
    'phoneCode': '855',
    'countryCode': 'KH',
    'label': 'Cambodia'
  },
  {
    'phoneCode': '856',
    'countryCode': 'LA',
    'label': 'Lao People\'s Democratic Republic'
  },
  {
    'phoneCode': '86',
    'countryCode': 'CN',
    'label': 'China'
  },
  {
    'phoneCode': '880',
    'countryCode': 'BD',
    'label': 'Bangladesh'
  },
  {
    'phoneCode': '886',
    'countryCode': 'TW',
    'label': 'Taiwan, Province of China'
  },
  {
    'phoneCode': '90',
    'countryCode': 'TR',
    'label': 'Turkey'
  },
  {
    'phoneCode': '91',
    'countryCode': 'IN',
    'label': 'India'
  },
  {
    'phoneCode': '92',
    'countryCode': 'PK',
    'label': 'Pakistan'
  },
  {
    'phoneCode': '93',
    'countryCode': 'AF',
    'label': 'Afghanistan'
  },
  {
    'phoneCode': '94',
    'countryCode': 'LK',
    'label': 'Sri Lanka'
  },
  {
    'phoneCode': '95',
    'countryCode': 'MM',
    'label': 'Myanmar'
  },
  {
    'phoneCode': '960',
    'countryCode': 'MV',
    'label': 'Maldives'
  },
  {
    'phoneCode': '961',
    'countryCode': 'LB',
    'label': 'Lebanon'
  },
  {
    'phoneCode': '962',
    'countryCode': 'JO',
    'label': 'Jordan'
  },
  {
    'phoneCode': '963',
    'countryCode': 'SY',
    'label': 'Syrian Arab Republic'
  },
  {
    'phoneCode': '964',
    'countryCode': 'IQ',
    'label': 'Iraq'
  },
  {
    'phoneCode': '965',
    'countryCode': 'KW',
    'label': 'Kuwait'
  },
  {
    'phoneCode': '966',
    'countryCode': 'SA',
    'label': 'Saudi Arabia'
  },
  {
    'phoneCode': '967',
    'countryCode': 'YE',
    'label': 'Yemen'
  },
  {
    'phoneCode': '968',
    'countryCode': 'OM',
    'label': 'Oman'
  },
  {
    'phoneCode': '970',
    'countryCode': 'PS',
    'label': 'Palestine, State of'
  },
  {
    'phoneCode': '971',
    'countryCode': 'AE',
    'label': 'United Arab Emirates'
  },
  {
    'phoneCode': '972',
    'countryCode': 'IL',
    'label': 'Israel'
  },
  {
    'phoneCode': '973',
    'countryCode': 'BH',
    'label': 'Bahrain'
  },
  {
    'phoneCode': '974',
    'countryCode': 'QA',
    'label': 'Qatar'
  },
  {
    'phoneCode': '975',
    'countryCode': 'BT',
    'label': 'Bhutan'
  },
  {
    'phoneCode': '976',
    'countryCode': 'MN',
    'label': 'Mongolia'
  },
  {
    'phoneCode': '977',
    'countryCode': 'NP',
    'label': 'Nepal'
  },
  {
    'phoneCode': '98',
    'countryCode': 'IR',
    'label': 'Iran, Islamic Republic of'
  },
  {
    'phoneCode': '992',
    'countryCode': 'TJ',
    'label': 'Tajikistan'
  },
  {
    'phoneCode': '993',
    'countryCode': 'TM',
    'label': 'Turkmenistan'
  },
  {
    'phoneCode': '994',
    'countryCode': 'AZ',
    'label': 'Azerbaijan'
  },
  {
    'phoneCode': '995',
    'countryCode': 'GE',
    'label': 'Georgia'
  },
  {
    'phoneCode': '996',
    'countryCode': 'KG',
    'label': 'Kyrgyzstan'
  },
  {
    'phoneCode': '998',
    'countryCode': 'UZ',
    'label': 'Uzbekistan'
  }
].sort((a, b) => a.label.localeCompare(b.label))
  .filter((item) => !AppFeature.KES_PROJECT
    ? item
    : item.countryCode === defaultCountryCode.countryCode);

export const countryPhonesByCountry = countryCodes.reduce((acc, item) => {
  acc[item.countryCode] = item;

  return acc;
}, {} as Record<CountryPhone['countryCode'], CountryPhone>);

export const getPhoneCodeByCountry = (countryCode: CountryPhone['countryCode']): CountryPhone | null => countryPhonesByCountry[countryCode] ?? null;
