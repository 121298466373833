type Props = {
  gameName: string;
  className?: string;
};

export const ResultGameName = (props: Props) => {
  const { gameName, className } = props;
  const names = gameName.replace(']]', ']').split('[[');

  return (
    <span className={className}>{names[0]} <br /> [{names[1]}</span>
  );
};
