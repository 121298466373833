import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconStatus from '../icon-status/icon-status';
import { getAmountString, roundNumber } from '@shared/utils/helpers/common';
import GradientIcon from '@shared/components/gradient-icon/gradient-icon';
import Loader from '@/shared/components/loader/loader';
import { Coupon } from '../../model/types';
import { couponIconColorByTheme, couponTypeLabel } from '../../helpers/const';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '@/modules/theme/@x/coupons';
import { useAppSelector } from '@/shared/hooks';
import { getCouponLogotype } from '@/shared/store/app/selectors';
import { AppFeature } from '@/app-config';
import TaxInfoTooltip from '@/shared/components/tax-info-tooltip/tax-info-tooltip';
import './coupon-card-detailed.scss';

const b = bemCn('coupon-card-detailed');

type CouponCardDetailedProps = {
  className?: string;
  coupon?: Coupon;
  loading?: boolean;
}

const CouponLoader = () => (
  <div className={b({ loading: true })}>
    <div className={b('result')}>
    </div>
    <div className={b('content')}>
      <Loader className={b('loader')} />
    </div>
  </div>
);

const CouponCardDetailed = ({
  className,
  coupon,
  loading,
}: CouponCardDetailedProps) => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const logotype = useAppSelector(getCouponLogotype);

  if (loading || !coupon) {
    return <CouponLoader />;
  }

  const {
    id,
    created,
    rate,
    betAmount,
    winAmount,
    currency,
    bets,
    status,
    isLive,
    type,
    taxInfo,
  } = coupon;

  const iconColor = couponIconColorByTheme[theme] ?? couponIconColorByTheme.default;


  return (
    <div className={b(null, className)}>
      <div className={b('result', { status })}>
        <p className={b('result-title')}>
          {status === 'refund' && t('bet-history.refund', 'Возврат')}
          {status === 'placed' && t('bet-history.placed', 'Ожидание')}
          {status === 'win' && t('bet-history.win', 'Выигрыш')}
          {status === 'loss' && t('bet-history.loss', 'Проигрыш')}
        </p>
        <IconStatus className={b('result-status')} status={status} />
      </div>
      <div className={b('content')}>
        <div className={b('info')}>
          <div className={b('labels')}>
            <img className={b('label')} src={logotype} />
            {/* {label && <p className={b('label')}>{label}</p>} */}
            {isLive && <p className={b('label', { live: true })}>Live</p>}
          </div>
          <div className={b('info-top')}>
            <div>
              <p className={b('type')}>{couponTypeLabel[type]}</p>
              <p className={b('number')}>№: {id}</p>
            </div>
            <p className={b('date')}>
              {dayjsCustom(created).format('DD.MM.YYYY / HH:mm')}
            </p>
            <p className={b('rate')}>{roundNumber(rate)}</p>
            <IconStatus className={b('status')} status={status} />
          </div>
          <p className={b('prop', { amount: true })}>
            <span className={b('prop-label')}>
              {t('bet-history.bet', 'Ставка')}:
            </span>
            <span className={b('prop-diver')} />
            <span className={b('prop-value')}>
              {getAmountString(betAmount, currency)}
            </span>
          </p>
          <p className={b('prop', { status, amount: true })}>
            <span className={b('prop-label')}>
              {status === 'refund' && t('bet-history.refund', 'Возврат')}
              {status === 'placed' && t('bet-history.possible-prize', 'Возможный выигрыш')}
              {status !== 'placed' && status !== 'refund' && t('bet-history.win', 'Выигрыш')}
            </span>
            <span className={b('prop-diver')} />
            <span className={b('prop-value')}>
              {status === 'placed' && getAmountString(betAmount * rate, currency)}
              {status !== 'placed' && getAmountString(winAmount ?? 0, currency)}
            </span>
          </p>
          {AppFeature.KES_TAXES && (
            <TaxInfoTooltip className={b('tax-info')}
              {...taxInfo}
              type={status}
              currency={currency}
              position='right-bottom'
            />
          )}
        </div>

        <div className={b('list')}>
          {bets.map((bet) => (
            <div className={b('item')} key={bet.id}>
              <div className={b('outcome')}>
                <p className={b('outcome-title')}>
                  {bet.subGameName && `${bet.subGameName}: `}{bet.outcomeName}
                </p>
                <p className={b('rate', b('item-rate'))}>
                  {roundNumber(bet.rate)}
                </p>
                <IconStatus className={b('item-status')} status={bet.status} />
              </div>
              <div className={b('item-meta')}>
                <p className={b('item-tournament')}>
                  {bet.sportName} / {bet.tournamentName}
                </p>
                <p className={b('item-date')}>
                  {dayjsCustom(bet.gameDate).format('DD.MM.YYYY / HH:mm')}
                </p>
              </div>
              <div className={b('item-wrapper')}>
                <GradientIcon className={b('item-icon')}
                  color={iconColor}
                  iconName={bet.sportIcon}
                />
                <p className={b('item-teams')}>
                  <span className={b('item-team')}>{bet.team1}</span>
                  <span className={b('item-team')}>{bet.team2}</span>
                </p>
              </div>
              {bet.gameScore ? (
                <p className={b('prop')}>
                  <span className={b('prop-label')}>
                    {t('bet-history.final-score', 'Итоговый счёт')}
                  </span>
                  <span className={b('prop-diver')} />
                  <span className={b('prop-value')}>{bet.gameScore}</span>
                </p>
              ) : null}
              {bet.betScore ? (
                <p className={b('prop')}>
                  <span className={b('prop-label')}>
                    {t('bet-history.bet-score', 'Поставлено при счете')}
                  </span>
                  <span className={b('prop-diver')} />
                  <span className={b('prop-value')}>{bet.betScore}</span>
                </p>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { CouponCardDetailed };
