import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { getAmountString } from '@shared/utils/helpers/common';
import NotificationText from '../notification-text/notification-text';

import type { NotificationItem } from '../../model/types';
import './notification-card.scss';

type NotificationCardProps = {
  className?: string;
  item: NotificationItem;
}

const b = bemCn('notification-card');

const NotificationCard = ({ className, item }: NotificationCardProps) => {
  const { title, description, type, amount, date, message, currency, read } = item;
  const { t } = useTranslation();
  const [prevReaded] = useState(read);

  return (
    <div className={b({ type }, className)}>
      <div className={b('head')}>
        <div className={b('head-top')}>
          <h2 className={b('title')}>{title}</h2>
          {!prevReaded && <p className={b('not-read')}>{t('not-read', 'Не прочитано')}</p>}
          {/* <p className={b('not-read')}>{t('not-read', 'Не прочитано')}</p> */}
        </div>

        {!!description && <p className={b('description')}>{description}</p>}
      </div>
      <div className={b('payload')}>
        {!!amount && !!currency && (
          <p className={b('amount')}>
            {type === 'error' && '- '}
            {type === 'success' && '+ '}
            {getAmountString(amount, currency)}
          </p>
        )}
        <p className={b('date')}>{date}</p>
        {!!message && (
          <p className={b('message')}>
            <NotificationText text={message} />
          </p>
        )}
      </div>
    </div>
  );
};

export default NotificationCard;
