import { adaptePartnerStats } from '../lib/adapter';
import { Subpartner, PartnerLink, PartnerProject, PartnerPromocode, PartnerStatsServer, PlayerStats } from './types';

const mockPartnerStatServer: PartnerStatsServer = {
  'deposits': 18500,
  'admin_deposits': 22275,
  'bets_placed': 73073.086925,
  'bets_winnings': 33298.086925,
  'bets_losses': 56610.294425,
  'bets_ingame': 0,
  'bets_income': 39775,
  'inbet_placed': 0,
  'inbet_winnings': 0,
  'inbet_income': 0,
  'vegas_placed': 0,
  'vegas_winnings': 0,
  'vegas_income': 0,
  'tvbet_placed': 0,
  'tvbet_winnings': 0,
  'tvbet_income': 0,

  'pragmatic_placed': 0,
  'pragmatic_winnings': 0,
  'pragmatic_income': 0,

  'mascot_placed': 0,
  'mascot_winnings': 0,
  'mascot_income': 0,

  'betgames_placed': 0,
  'betgames_winnings': 0,
  'betgames_income': 0,

  'smartsoft_placed': 0,
  'smartsoft_winnings': 123,
  'smartsoft_income': 0,

  'agt_placed': 0,
  'agt_winnings': 0,
  'agt_income': 0,

  'aviatrix_placed': 0,
  'aviatrix_winnings': 0,
  'aviatrix_income': 0,

  'fivemen_placed': 0,
  'fivemen_winnings': 0,
  'fivemen_income': 0,

  'endorphina_placed': 0,
  'endorphina_winnings': 0,
  'endorphina_income': 0,

  'gosh_placed': 0,
  'gosh_winnings': 0,
  'gosh_income': 0,

  'income': 39775,
  'num_clicks': 1504,
  'num_registrations': 89,
  'first_deposits': 9,
  'revshare': 1988.75,
  'current_income': 1988.75,
};

export const mockPartnerStat = adaptePartnerStats(mockPartnerStatServer);

export const mockPlayerStats: PlayerStats = {
  deposits: 0,
  adminDeposits: 0,
  betsPlaced: 0,
  betsWinnings: 0,
  betsLosses: 0,
  betsIngame: 0,
  betsIncome: 0,
  inbetPlaced: 0,
  inbetWinnings: 0,
  inbetIncome: 0,
  vegasPlaced: 0,
  vegasWinnings: 0,
  vegasIncome: 0,
  tvbetPlaced: 0,
  tvbetWinnings: 0,
  tvbetIncome: 0,
  pragmaticPlaced: 0,
  pragmaticWinnings: 0,
  pragmaticIncome: 0,
  mascotPlaced: 0,
  mascotWinnings: 0,
  mascotIncome: 0,
  betgamesPlaced: 0,
  betgamesWinnings: 0,
  betgamesIncome: 0,
  smartsoftPlaced: 0,
  smartsoftWinnings: 0,
  smartsoftIncome: 0,
  agtPlaced: 0,
  agtWinnings: 0,
  agtIncome: 0,
  aviatrixPlaced: 0,
  aviatrixWinnings: 0,
  aviatrixIncome: 0,
  fivemenPlaced: 0,
  fivemenWinnings: 0,
  fivemenIncome: 0,
  endorphinaPlaced: 0,
  endorphinaWinnings: 0,
  endorphinaIncome: 0,
  goshPlaced: 0,
  goshWinnings: 0,
  goshIncome: 0,
  income: 0,
  playerId: 1234,
  playerName: 'User 1234',
};

export const mockPartnerLink: PartnerLink = {
  refCode: 'ref_codeasdasdasd',
  created: new Date().toISOString(),
  projectId: 1234,
  projectName: 'название проекта',
  link: 'http://refasdasd',
  title: 'Заголовок ссылки',
};

export const mockPromocode: PartnerPromocode = {
  id: 123,
  created: new Date().toISOString(),
  name: 'название промокода',
  scriptId: 66666,
  scriptDescription: 'Описание скрипта',
  userId: 33333,
};

export const mockManagerPartner: Subpartner = {
  id: 123456,
  login: 'User login',
  email: 'user@mail.com',
  phone: '88005553535',
  phoneCountry: 'RU',
  name: 'User Name',
  role: 'partner',
  created: new Date().toISOString(),
};

export const mockPartnerProject: PartnerProject = {
  id: 123456,
  created: new Date().toISOString(),
  name: 'Название проекта 123'
};
