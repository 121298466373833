import { sortByTopEntries } from '@/shared/utils/helpers/common';
import { CasinoGame } from '../model/types';
import { topCrashGames } from './top-crash-games';


export const sortCrashGamesByTop = (games: CasinoGame[]) => sortByTopEntries(
  games,
  topCrashGames,
  (game) => game.link
);
