import { bemCn } from '@shared/utils/helpers/bem-cn';
import { Basket } from '@/modules/basket';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import Container from '@shared/components/container/container';
import { useAppSelector } from '@/shared/hooks';
import { getCasinoGamesByProvider } from '@/modules/casino/@x/line';
import EventsFilterDesktop from '../events-filter-desktop/events-filter-desktop';
import { FC, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useScrollToTop } from '../../../../shared/hooks/use-scrolltotop';
import EventsFilterContext from '../../model/events-filter-context';
import type { TEventsFilter } from '../../model/types';
import { DataType } from '../../model/types';
import { withEventsFilterItems } from '../../model/with-events-filter-items';
import SidebarGames from '../sidebar-games/sidebar-games';
import type { CasinoGame } from '@/modules/casino';
import { useThemeContext } from '@/modules/theme/@x/line';
import './line-layout-desktop.scss';

const EventsFilterWithItems = withEventsFilterItems(EventsFilterDesktop);

const b = bemCn('line-layout-desktop');

type LineLayoutDesktopProps = {
  className?: string;
  dataType: DataType;
}

const initialFilter: TEventsFilter = {
  sport: '0',
  country: '0',
  tournament: '0'
};

const LineLayoutDesktop: FC<LineLayoutDesktopProps> = ({
  className,
  dataType,
}) => {
  useScrollToTop();
  const navigator = useNavigate();
  const { isDark } = useThemeContext();
  const [filter, setFilter] = useState<TEventsFilter>(initialFilter);

  const resetFilter = () => setFilter(initialFilter);

  const handleFilterChange = (newFilter: Partial<TEventsFilter>) => {
    setFilter({
      ...filter,
      ...newFilter,
    });
    navigator('', { replace: true });
  };

  useEffect(() => {
    resetFilter();
  }, [dataType]);

  const sidebarGames: CasinoGame[] = useAppSelector((state) => getCasinoGamesByProvider(state, 'pragmatic'));

  return (
    <EventsFilterContext.Provider value={{ filter, setFilter, resetFilter }}>
      <div className={b(null, className)}>
        <Container className={b('wrapper')}>
          <Breadcrumbs className={b('breadcrumbs')} />
          <div className={b('layout')}>
            <div className={b('left-column')}>
              <EventsFilterWithItems key={dataType}
                onFilteChange={handleFilterChange}
                filter={filter}
                dataType={dataType}
                isLiveEvents={dataType === 'live'}
              />
            </div>

            <div className={b('content')}>
              <Outlet />
            </div>

            <div className={b('right-column')}>
              <Basket className={b('basket')} theme={isDark ? 'dark' : 'light'} />
              <SidebarGames items={sidebarGames} className={b('sidebar-games')} />
            </div>
          </div>
        </Container>
      </div>
    </EventsFilterContext.Provider>
  );
};

export default LineLayoutDesktop;
