import { CasinoBonusItem } from '../store/types';

export const mergeCasinoBonuses = (bonuses: CasinoBonusItem[]): CasinoBonusItem[] => {
  const providerMap = bonuses.reduce((acc, current) => {
    const existing = acc.get(current.provider);

    if (existing) {
      // Суммируем fsAmount и wager для существующего провайдера
      existing.fsAmount += current.fsAmount;
      existing.wager += current.wager;
    } else {
      // Создаем новый объект для избежания мутации исходных данных
      acc.set(current.provider, { ...current });
    }

    return acc;
  }, new Map<string, CasinoBonusItem>());

  return Array.from(providerMap.values());
};
