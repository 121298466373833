import { HandledError, handleQueryError } from '@/shared/utils/helpers/handle-query-error';
import { PasswordResetByPhoneFormError } from '../components/password-reset-by-phone-form/password-reset-by-phone-form';


export const handleResetPassByPhoneError = (error?: HandledError): PasswordResetByPhoneFormError | undefined => {
  const queryError = handleQueryError(error);

  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;


    if (typeof detail === 'string') {
      const errors: PasswordResetByPhoneFormError = {};
      switch (detail) {
        default:
          errors.root = detail;
      }

      return errors;
    }

    if (Array.isArray(detail)) {
      const errors: PasswordResetByPhoneFormError = {};
      detail.forEach((err) => {
        const location = err.loc?.[1];
        const message = err.msg;

        switch (location) {
          case 'phone':
            errors.phone = message;
            break;
          default:
            errors.root = message;
        }
      });

      return errors;
    }
  }
};
