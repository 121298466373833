import i18n from '@/shared/i18n/i18n';

export const statsTitle = {
  numClicks: i18n.t('stats.titles.numClicks', 'Переходы'),
  numRegistrations: i18n.t('stats.titles.numRegistrations', 'Регистрации'),
  deposits: i18n.t('stats.titles.deposits', 'Пополнения'),
  adminDeposits: i18n.t('stats.titles.adminDeposits', 'Пополнения администратором'),
  firstDeposits: i18n.t('stats.titles.firstDeposits', 'Первые депозиты'),
  betsPlaced: i18n.t('stats.titles.betsPlaced', 'Сумма ставок'),
  betsWinnings: i18n.t('stats.titles.betsWinnings', 'Выигрыш'),
  betsLosses: i18n.t('stats.titles.betsLosses', 'Проигрыш'),
  betsIngame: i18n.t('stats.titles.betsIngame', 'Не рассчитано'),
  betsIncome: i18n.t('stats.titles.betsIncome', 'Прибыль ставки'),

  inbetPlaced: i18n.t('stats.titles.inbetPlaced', 'Ставки Inbet'),
  inbetWinnings: i18n.t('stats.titles.inbetWinnings', 'Выигрыш Inbet'),
  inbetIncome: i18n.t('stats.titles.inbetIncome', 'Прибыль Inbet'),
  betgamesPlaced: i18n.t('stats.titles.betgamesPlaced', 'Ставки Betgames'),
  betgamesWinnings: i18n.t('stats.titles.betgamesWinnings', 'Выигрыш Betgames'),
  betgamesIncome: i18n.t('stats.titles.betgamesIncome', 'Прибыль Betgames'),
  vegasPlaced: i18n.t('stats.titles.vegasPlaced', 'Ставки Vegas'),
  vegasWinnings: i18n.t('stats.titles.vegasWinnings', 'Выигрыш Vegas'),
  vegasIncome: i18n.t('stats.titles.vegasIncome', 'Прибыль Vegas'),
  pragmaticPlaced: i18n.t('stats.titles.pragmaticPlaced', 'Ставки Pragmatic'),
  pragmaticWinnings: i18n.t('stats.titles.pragmaticWinnings', 'Выигрыш Pragmatic'),
  pragmaticIncome: i18n.t('stats.titles.pragmaticIncome', 'Прибыль Pragmatic'),
  tvbetPlaced: i18n.t('stats.titles.tvbetPlaced', 'Ставки TVbet'),
  tvbetWinnings: i18n.t('stats.titles.tvbetWinnings', 'Выигрыш TVbet'),
  tvbetIncome: i18n.t('stats.titles.tvbetIncome', 'Прибыль TVbet'),
  mascotPlaced: i18n.t('stats.titles.mascotPlaced', 'Ставки Mascot'),
  mascotWinnings: i18n.t('stats.titles.mascotWinnings', 'Выигрыш Mascot'),
  mascotIncome: i18n.t('stats.titles.mascotIncome', 'Прибыль Mascot'),
  agtPlaced: i18n.t('stats.titles.agtPlaced', 'Ставки AGT'),
  agtWinnings: i18n.t('stats.titles.agtWinnings', 'Выигрыш AGT'),
  agtIncome: i18n.t('stats.titles.agtIncome', 'Прибыль AGT'),
  smartsoftPlaced: i18n.t('stats.titles.smartsoftPlaced', 'Ставки Smartsoft'),
  smartsoftWinnings: i18n.t('stats.titles.smartsoftWinnings', 'Выигрыш Smartsoft'),
  smartsoftIncome: i18n.t('stats.titles.smartsoftIncome', 'Прибыль Smartsoft'),
  aviatrixPlaced: i18n.t('stats.titles.aviatrixPlaced', 'Ставки Aviatrix'),
  aviatrixWinnings: i18n.t('stats.titles.aviatrixWinnings', 'Выигрыш Aviatrix'),
  aviatrixIncome: i18n.t('stats.titles.aviatrixIncome', 'Прибыль Aviatrix'),
  endorphinaPlaced: i18n.t('stats.titles.endorphinaPlaced', 'Ставки Endorphina'),
  endorphinaWinnings: i18n.t('stats.titles.endorphinaWinnings', 'Выигрыш Endorphina'),
  endorphinaIncome: i18n.t('stats.titles.endorphinaIncome', 'Прибыль Endorphina'),
  fivemenPlaced: i18n.t('stats.titles.fivemenPlaced', 'Ставки 5MEN'),
  fivemenWinnings: i18n.t('stats.titles.fivemenWinnings', 'Выигрыш 5MEN'),
  fivemenIncome: i18n.t('stats.titles.fivemenIncome', 'Прибыль 5MEN'),
  goshPlaced: i18n.t('stats.titles.goshPlaced', 'Ставки Gosh'),
  goshWinnings: i18n.t('stats.titles.goshWinnings', 'Выигрыш Gosh'),
  goshIncome: i18n.t('stats.titles.goshIncome', 'Прибыль Gosh'),

  income: i18n.t('stats.titles.income', 'Общий доход по балансам'),
  revshare: i18n.t('stats.titles.revshare', 'Личный доход'),
  subpartnersRevshare: i18n.t('stats.titles.subpartnersRevshare', 'Ревшара субпартнёров'),
  currentIncome: i18n.t('stats.titles.currentIncome', 'Текущий партнёрский баланс'),

  playerId: i18n.t('stats.titles.playerId', 'ID реферала'),
  playerName: i18n.t('stats.titles.playerName', 'Имя реферала'),
};

// export const statsTitle = {
//   numClicks: 'Переходы',
//   numRegistrations: 'Регистрации',
//   deposits: 'Пополнения',
//   adminDeposits: 'Пополнения администратором',
//   firstDeposits: 'Первые депозиты',
//   betsPlaced: 'Сумма ставок',
//   betsWinnings: 'Выигрыш',
//   betsLosses: 'Проигрыш',
//   betsIngame: 'Не рассчитано',
//   betsIncome: 'Прибыль ставки',

//   inbetPlaced: 'Ставки Inbet',
//   inbetWinnings: 'Выигрыш Inbet',
//   inbetIncome: 'Прибыль Inbet',
//   betgamesPlaced: 'Ставки Betgames',
//   betgamesWinnings: 'Выигрыш Betgames',
//   betgamesIncome: 'Прибыль Betgames',
//   vegasPlaced: 'Ставки Vegas',
//   vegasWinnings: 'Выигрыш Vegas',
//   vegasIncome: 'Прибыль Vegas',
//   pragmaticPlaced: 'Ставки Pragmatic',
//   pragmaticWinnings: 'Выигрыш Pragmatic',
//   pragmaticIncome: 'Прибыль Pragmatic',
//   tvbetPlaced: 'Ставки TVbet',
//   tvbetWinnings: 'Выигрыш TVbet',
//   tvbetIncome: 'Прибыль TVbet',
//   mascotPlaced: 'Ставки Mascot',
//   mascotWinnings: 'Выигрыш Mascot',
//   mascotIncome: 'Прибыль Mascot',

//   smartsoftPlaced: 'Ставки Smartsoft',
//   smartsoftWinnings: 'Выигрыш Smartsoft',
//   smartsoftIncome: 'Прибыль Smartsoft',

//   agtPlaced: 'Ставки AGT',
//   agtWinnings: 'Выигрыш AGT',
//   agtIncome: 'Прибыль AGT',


//   income: 'Общий доход по балансам',
//   revshare: 'Личный доход',
//   currentIncome: 'Прибыль за текущий период',

//   playerId: 'ID реферала',
//   playerName: 'Имя реферала',
// };
