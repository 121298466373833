import { bemCn } from '@/shared/utils/helpers/bem-cn';
import Form from '@/shared/components/form/form';
import Button from '@/shared/components/button/button';
import { useCountdown } from 'usehooks-ts';
import { FC, useEffect } from 'react';
import * as yup from 'yup';
import i18n from '@/shared/i18n/i18n';
import { useRepeatSmsMutation, useVerifyPhoneMutation } from '../../model/auth-api';
import { useAppSelector } from '@/shared/hooks';
import { selectVerifyPhone } from '../../model/selectors';
import { handlePhoneVerificationError } from '../../utils/handle-phone-verification-error';
import { handleResendSmsError } from '../../utils/handle-resend-sms-error';
import { useTranslation } from 'react-i18next';
import './phone-verification-form.scss';

const b = bemCn('phone-verification-form');

type PhoneVerificationFormData = {
  smsCode: string;
}

// type PhoneVerificationFormError = {
//   root?: string;
//   smsCode?: string;
// }

const schema = yup.object({
  smsCode: yup.string()
    .length(4, i18n.t('confirm-phone.errors.wrong-length'))
    .required(i18n.t('confirm-phone.errors.empty-code', 'Введите код')),
}).required();

const defaultValues: PhoneVerificationFormData = {
  smsCode: '',
};

type PhoneVerificationFormProps = {
  className?: string;
  // onFormSubmit?: SubmitHandler<PhoneVerificationFormData>;
  // error?: PhoneVerificationFormError;
  // loading?: boolean;
}

const PhoneVerificationForm: FC<PhoneVerificationFormProps> = ({ className, }) => {
  const { t } = useTranslation();
  const phoneNumber = useAppSelector(selectVerifyPhone);

  const [count, { startCountdown, resetCountdown, }] =
    useCountdown({ countStart: 60, intervalMs: 1000, });

  const [verifySmsCode, { verificationError, isLoading }] = useVerifyPhoneMutation({
    selectFromResult: (result) => ({
      ...result,
      verificationError: handlePhoneVerificationError(result.error)
    })
  });
  const [repeateSms, { isLoading: isResendLoading, resendSmsError }] = useRepeatSmsMutation({
    selectFromResult: (result) => ({
      ...result,
      resendSmsError: handleResendSmsError(result.error)
    })
  });

  const isResendAble = Boolean(phoneNumber)
    && !isLoading
    && !isResendLoading
    && count <= 0;

  useEffect(() => {
    if (phoneNumber) {
      resetCountdown();
      startCountdown();

      if (phoneNumber) {
        repeateSms(phoneNumber);
      }
    }
  }, [phoneNumber]);

  const onFormSubmit = (formData: PhoneVerificationFormData) => {
    if (formData.smsCode && phoneNumber) {
      verifySmsCode(formData.smsCode);
    }
  };

  const handleResendClick = () => {
    if (phoneNumber) {
      resetCountdown();
      startCountdown();
      repeateSms(phoneNumber);
    }
  };


  return (
    <Form<PhoneVerificationFormData> className={b(null, className)}
      form={{
        schema,
        defaultValues,
        error: verificationError || resendSmsError
      }}
      onSubmit={onFormSubmit}
    >
      <Form.RootError className={b('error')} />
      <Form.TextField<PhoneVerificationFormData> className={b('field')}
        name='smsCode'
        placeholder='XXXX'
        disabled={isLoading}
      />
      <Button className={b('button')}
        variant="text"
        disabled={!isResendAble}
        onClick={handleResendClick}
      >
        {t('confirm-phone.resend-code')}
        {!!count && count !== 60 && ` (${count})`}
      </Button>
      <Button className={b('button')}
        type="submit"
        disabled={isLoading || isResendLoading}
      >
        {t('confirm-phone.send-request', 'Отправить')}
      </Button>
    </Form>
  );
};

export default PhoneVerificationForm;
