import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { TransactionItem } from '../../store/types';
import TransactionCard from '../transaction-item/transaction-item';
import Loader from '@/shared/components/loader/loader';
import './transactions-list.scss';
import { EmptyPlaceholder } from '@/shared/components/empty-placeholder/empty-placeholder';
import { useTranslation } from 'react-i18next';

const b = bemCn('transactions-list');

type TransactionsListProps = {
  className?: string;
  transactions: TransactionItem[];
  inverted?: boolean;
  loading?: boolean;
}

const TransactionsList = ({
  className,
  transactions,
  inverted,
  loading,
}: TransactionsListProps) => {
  const { t } = useTranslation();
  const isEmpty = transactions.length === 0 && !loading;

  return (
    <div className={b({ loading }, className)}>
      {loading && <Loader className={b('spiner')} />}
      {isEmpty && (
        <EmptyPlaceholder className={b('empty')}
          title={t('empty-message.transactions.title')}
          description={t('empty-message.transactions.desc')}
        />
      )}
      {transactions.map((transaction) => (
        <TransactionCard
          className={b('item')}
          key={transaction.id}
          transaction={transaction}
          inverted={inverted}
        />
      ))}
    </div>
  );
};

export default TransactionsList;
