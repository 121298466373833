import i18n from '@/shared/i18n/i18n';
import type { TransacitonType } from '../store/types';

export const TRANSACTIONS_PER_PAGE = 10;

export const transactionTitles: Record<TransacitonType, string> = {
  'bet_placement': i18n.t('transactions.type.bet-placement', 'Постановка ставки'),
  'bet_placement_error': i18n.t('transactions.type.bet-placement-error', 'Ошибка постановки ставки'),
  'bet_recalc': i18n.t('transactions.type.bet-recalc', 'Перерасчёт ставки'),
  'bet_refund': i18n.t('transactions.type.bet-refund', 'Возврат ставки'),
  'bet_win': i18n.t('transactions.type.bet-win', 'Выигрыш по ставке'),
  'admin_refill': i18n.t('transactions.type.admin-refill', 'Начисление администратором'),
  'admin_withdrawal': i18n.t('transactions.type.admin-withdrawal', 'Списание администратором'),
  'admin_refill_bonus': i18n.t('transactions.type.admin_refill_bonus', 'Начисление администратором (бонусный)'),
  'admin_withdrawal_bonus': i18n.t('transactions.type.admin_withdrawal_bonus', 'Списание администратором (бонусный)'),
  'admin_refill_line_cashback': i18n.t('transactions.type.admin_refill_line_cashback', 'Начисление администратором Кэшбэка (спорт)'),
  'admin_withdrawal_line_cashback': i18n.t('transactions.type.admin_withdrawal_line_cashback', 'Списание администратором Кэшбэка (спорт)'),
  'admin_refill_casino_cashback': i18n.t('transactions.type.admin_refill_casino_cashback', 'Начисление администратором Кэшбэка (казино)'),
  'admin_withdrawal_casino_cashback': i18n.t('transactions.type.admin_withdrawal_casino_cashback', 'Списание администратором Кэшбэка (казино)'),
  'admin_refill_casino_bonus': i18n.t('transactions.type.admin_refill_casino_bonus', 'Начисление администратором Бонуса (казино)'),
  'admin_withdrawal_casino_bonus': i18n.t('transactions.type.admin_withdrawal_casino_bonus', 'Списание администратором Бонуса (казино)'),
  'user_deposit': i18n.t('transactions.type.user-deposit', 'Пополнение'),
  'user_withdrawal': i18n.t('transactions.type.user-withdrawal', 'Вывод'),
  'withdrawal_cancel': i18n.t('transactions.type.withdrawal-cancel', 'Отмена вывода'),
  'user_user_transfer': i18n.t('transactions.type.user-user-transfer', 'Перевод'),
  'user_self_transfer': i18n.t('transactions.type.user-self-transfer', 'Перевод между счетами'),
  'casino_bet': i18n.t('transactions.type.casino_bet', 'Постановка ставки (казино)'),
  'casino_win': i18n.t('transactions.type.casino_win', 'Выигрыш по ставке (Казино)'),
  'casino_cashback_conversion': i18n.t('transactions.type.casino_cashback_conversion', 'Начисление кэшбэка на основной счёт (казино)'),
  'casino_cashback_accrual': i18n.t('transactions.type.casino_cashback_accrual', 'Начисление кэшбэка на бонусный счёт (казино)'),
  'casino_refund': i18n.t('transactions.type.casino_refund', 'Возврат ставки (казино)'),
  'casino_return_deposit': i18n.t('transactions.type.casino_return_deposit', 'Возврат депозита (казино)'),
  'casino_bet_bonus': i18n.t('transactions.type.casino_bet_bonus', 'Бонусная ставка (казино)'),
  'casino_win_bonus': i18n.t('transactions.type.casino_win_bonus', 'Бонусный выигрыш (казино)'),
  'casino_refund_bonus': i18n.t('transactions.type.casino_refund_bonus', 'Возврат бонуса (казино)'),
  'casino_return_deposit_bonus': i18n.t('transactions.type.casino_return_deposit_bonus', 'Возврат бонусного депозита (казино)'),
  'casino_promo_win': i18n.t('transactions.type.casino_promo_win', 'Выигрыш по промокоду (казино)'),
  'casino_combo_bet': i18n.t('transactions.type.casino_combo_bet', 'Постановка комбинированной ставки (казино)'),
  'casino_combo_win': i18n.t('transactions.type.casino_combo_win', 'Комбинированный выигрыш (казино)'),
  'casino_bonus_conversion': i18n.t('transactions.type.casino_bonus_conversion', 'Начисление бонуса на основной счёт (казино)'),
  'line_cashback_conversion': i18n.t('transactions.type.line_cashback_conversion', 'Начисление кэшбэка на основной счёт (спорт)'),
  'line_cashback_accrual': i18n.t('transactions.type.line_cashback_accrual', 'Начисление кэшбэка на бонусный счёт (спорт)'),
  'bet_placement_bonus': i18n.t('transactions.type.bet_placement_bonus', 'Постановка ставки (бонусный)'),
  'bet_recalc_bonus': i18n.t('transactions.type.bet_recalc_bonus', 'Перерасчёт ставки (бонусный)'),
  'bet_refund_bonus': i18n.t('transactions.type.bet_refund_bonus', 'Возврат ставки (бонусный)'),
  'bet_win_bonus': i18n.t('transactions.type.bet_win_bonus', 'Выигрыш по ставке (бонусный)'),
  'bet_placement_error_bonus': i18n.t('transactions.type.bet_placement_error_bonus', 'Ошибка постановки ставки (бонусный)'),
  'promocode_refill_bonus': i18n.t('transactions.type.promocode_refill_bonus', 'Начисление по промокоду (бонус)'),
  'partner_income_accrual': i18n.t('transactions.type.partner_income_accrual', 'Начисление партнёрской прибыли'),
  'partner_income_conversion': i18n.t('transactions.type.partner_income_conversion', 'Начисление партнёрской прибыли на основной счёт'),
};

export const balaceDiffType: Record<string, TransacitonType[]> = {
  increase: [
    'user_deposit',
    'withdrawal_cancel',
    'admin_refill',
    'admin_refill_bonus',
    'admin_refill_casino_bonus',
    'admin_refill_casino_cashback',
    'admin_refill_line_cashback',
    'bet_placement_error',
    'bet_placement_error_bonus',
    'bet_refund',
    'bet_refund_bonus',
    'bet_win',
    'bet_win_bonus',
    'line_cashback_conversion',
    'line_cashback_accrual',
    'casino_win',
    'casino_refund',
    'casino_cashback_conversion',
    'casino_cashback_accrual',
    'casino_bonus_conversion',
    'casino_return_deposit',
    'casino_win_bonus',
    'casino_refund_bonus',
    'casino_return_deposit_bonus',
    'casino_combo_win',
    'casino_promo_win',
    'promocode_refill_bonus',
    'partner_income_accrual',
    'partner_income_conversion',
  ],
  decrease: [
    'user_withdrawal',
    'admin_withdrawal',
    'admin_withdrawal_bonus',
    'admin_withdrawal_casino_bonus',
    'admin_withdrawal_casino_cashback',
    'admin_withdrawal_line_cashback',
    'bet_placement',
    'bet_placement_bonus',
    'bet_recalc',
    'bet_recalc_bonus',
    'casino_bet',
    'casino_bet_bonus',
    'casino_combo_bet',
  ],
  same: [
    'user_self_transfer',
    'user_user_transfer',
  ]
};

export const getTransactionDiff = (type: TransacitonType) => {
  let result = 'same';
  for (const key in balaceDiffType) {
    if (balaceDiffType[key].includes(type)) {
      result = key;
    }
  }
  return result;
};

export const getTransactionTitle = (transactionType: TransacitonType) =>
  transactionTitles[transactionType] ?? transactionType;
