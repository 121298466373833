import { useEffect } from 'react';
import { useLoginByPhoneMutation, useLoginMutation } from '../../model/auth-api';
import LoginForm from '../login-form/login-form';
import AppModal from '@shared/components/app-modal/app-modal';
import { useDispatchedActions } from '../../../../shared/hooks';
import { useTranslation } from 'react-i18next';
import { handleLoginByPhoneError, handleLoginError } from '../../utils/handle-login-error';
import LoginFormByPhone, { LoginFormByPhoneData } from '../login-form-by-phone/login-form-by-phone';
import { AppFeature } from '@/app-config';
import { clearPhoneNumber } from '../../utils/clear-phone';

type LoginModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}


const LoginModal = ({ isOpen, onClose }: LoginModalProps) => {
  const { t } = useTranslation();
  const { changeAuthModal: chengeAuthModal, setVerifyPhone } = useDispatchedActions();

  const [login, { isSuccess, isLoading, loginError }] = useLoginMutation({
    selectFromResult: ({ error, ...result }) => ({
      ...result,
      loginError: handleLoginError(error)
    })
  });
  const [loginByPhone, {
    isSuccess: isLoginByPhoneSuccess,
    isLoading: isLoginByPhoneLoading,
    loginByPhoneError,
  }] = useLoginByPhoneMutation({
    selectFromResult: ({ error, ...result }) => ({
      ...result,
      loginByPhoneError: handleLoginByPhoneError(error)
    })
  });

  const handleRegisterClick = () => chengeAuthModal('registration');
  const handleResetPasswordClick = () => chengeAuthModal('password-reset');
  const handleResetClickByPhone = () => chengeAuthModal('password-reset-by-phone');

  useEffect(() => {
    if (isSuccess || isLoginByPhoneSuccess) {
      onClose?.();
    }
  }, [isSuccess, isLoginByPhoneSuccess]);


  const handleLoginByPhoneSubmit = (formData: LoginFormByPhoneData) => {
    const phone = formData.phone;
    setVerifyPhone(clearPhoneNumber(phone.phone, phone.countryCode));
    loginByPhone(formData);
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('auth.modal.title', 'Вход')}
      description={t('auth.modal.description', 'Введите логин и пароль, чтобы войти')}
    >
      {AppFeature.KES_PROJECT ? (
        <LoginFormByPhone
          onFormSubmit={handleLoginByPhoneSubmit}
          error={loginByPhoneError}
          loading={isLoginByPhoneLoading}
          onRegisterClick={handleRegisterClick}
          onResetPasswordClick={handleResetClickByPhone}
        />
      ) : (
        <LoginForm
          onFormSubmit={login}
          loading={isLoading}
          error={loginError}
          onRegisterClick={handleRegisterClick}
          onResetPasswordClick={handleResetPasswordClick}
        />
      )}
    </AppModal>
  );
};

export default LoginModal;
