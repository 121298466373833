import { useEffect, useState } from 'react';
import i18n from '@/shared/i18n/i18n';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useUpdateUserMutation, getUserMainData } from '@/modules/user';
import { useAppSelector } from '@/shared/hooks';
import { useDispatchedActions } from '@/shared/hooks';
import { handleChangeTelegramError } from '../utils/helpers';

export type TelegramChangeFormData = {
  telegramNickname: string;
};

const schema: yup.ObjectSchema<TelegramChangeFormData> = yup
  .object({
    telegramNickname: yup.string()
      .trim()
      .min(5, i18n.t('settings.errors.tg-not-enough-characters', 'Недостаточно символов'))
      .matches(/^[A-Za-z\d_]{5,32}$/, i18n.t('settings.errors.tg-invalid-format', 'Не верный формат'))
      .required(i18n.t('settings.enter-telegram', 'Введите ваш Telegram')),
  })
  .required();

export const useTelegramChange = () => {
  const [isEdit, setIsEdit] = useState(false);
  const userMainData = useAppSelector(getUserMainData);
  const { changeAuthModal } = useDispatchedActions();

  const [updateUser, { isLoading, error, isSuccess }] = useUpdateUserMutation();
  const telegramChangeError = handleChangeTelegramError(error);

  const { control, handleSubmit, setError, watch, formState: { errors } } = useForm<TelegramChangeFormData>({
    defaultValues: {
      telegramNickname: userMainData?.telegramNickname ?? '',
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (telegramChangeError?.root) {
      setError('root', { message: telegramChangeError?.root });
    }
    if (telegramChangeError?.telegram) {
      setError('telegramNickname', { message: telegramChangeError?.telegram });
    }
  }, [telegramChangeError?.root, telegramChangeError?.telegram, setError]);

  useEffect(() => {
    if (isSuccess && userMainData) {
      if (userMainData.telegramNickname) {
        changeAuthModal('telegram-change-success');
      } else {
        changeAuthModal('telegram-add-success');
      }
    }
  }, [isSuccess, userMainData]);

  const telegramValue = watch('telegramNickname');

  const handleFormSubmit = async (formData: TelegramChangeFormData) => {
    await updateUser(formData);
    setIsEdit(false);
  };

  return {
    userMainData,
    telegramValue,
    isLoading,
    control,
    errors,
    isEdit,
    setIsEdit,
    handleSubmit,
    handleFormSubmit,
  };
};
