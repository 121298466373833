import { AVAILABLE_THEMES } from '@/app-config';
import { ThemeContext } from '../../model/theme-context';
import { PropsWithChildren, useEffect, useState } from 'react';
import { getTheme, saveTheme } from '@/shared/utils/helpers/theme';


const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [theme, setTheme] = useState(getTheme());

  useEffect(() => {
    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${theme}`);
    saveTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        availableThemes: AVAILABLE_THEMES
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
