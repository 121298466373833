import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { type IFavoriteMatch } from '@/modules/user/@x/line';
import { memo, useMemo } from 'react';
import FavoriteEventCard from '../favorite-event-card/favorite-event-card';
import './favorites-events-list.scss';

const MemoizedFavoriteEventCard = memo(FavoriteEventCard);

const b = bemCn('favorites-events-list');

type FavoritesEventsListProps = {
  className?: string;
  items: IFavoriteMatch[];
}

const FavoritesEventsList = ({
  className,
  items
}: FavoritesEventsListProps) => {

  const memoizedFavoriteEvents = useMemo(
    () => items.map((item) => (
      <MemoizedFavoriteEventCard
        className={b('item')}
        key={item.id}
        item={item}
      />
    )),
    [items]
  );

  return (
    <div className={b(null, className)}>
      {memoizedFavoriteEvents}
    </div>
  );
};

export default FavoritesEventsList;
