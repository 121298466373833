import { bemCn } from '@shared/utils/helpers/bem-cn';
import { PropsWithChildren, useEffect, useState } from 'react';
import { MaybeArray } from '@shared/types/common';
import './tabs.scss';
import i18n from '@/shared/i18n/i18n';

type TabItem = {
  id: string;
  title: string;
  active?: boolean;
  disabled?: boolean;
}

type ChildrenElement = React.ReactElement<{
  id: string;
  title: string;
  active?: boolean;
  disabled?: boolean;
}>

type TabsProps = {
  className?: string;
  classNameBar?: string;
  items?: TabItem[];
  children?: MaybeArray<ChildrenElement> | null;
}

const b = bemCn('tabs');

const Tabs = ({ className, items, children, classNameBar }: TabsProps) => {
  const [tabsItems, setTabsItems] = useState<TabItem[]>(items ?? []);
  const [activeTab, setActiveTab] = useState<string | null>(null);

  useEffect(() => {
    if (children) {
      if (Array.isArray(children)) {
        const tabs = children.filter((item) => !item.props.disabled).map((item, i) => ({
          id: item.props.id ?? `${i}`,
          title: item.props.title ?? item.props.id,
          active: item.props.active,
          disabled: item.props.disabled
        }));

        const activeItem = tabs.find((item) => item.active)?.id ?? tabs[0].id;
        setTabsItems(tabs);
        setActiveTab(activeItem);
      } else {
        setTabsItems([{
          id: children.props.id ?? '0',
          title: children.props.title ?? children.props.id,
          active: children.props.active,
          disabled: children.props.disabled
        }]);
        setActiveTab(children.props.id);
      }
    }
  }, []);

  return (
    <div className={b(null, className)}>
      <div className={b('list', classNameBar)}>
        {tabsItems?.map((tab) => (
          <button className={b('item', { active: tab.id === activeTab })}
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.title}
          </button>
        ))}
        <button className={b('item')}
          key="phone"
          disabled
          type='button'
        >
          {i18n.t('auth.modal.phone', 'Телефон')}
        </button>
        <button className={b('item')}
          key="one-click"
          disabled
          type='button'
        >
          {i18n.t('auth.modal.one-click', 'В 1 клик')}
        </button>
      </div>
      {Array.isArray(children)
        ? children.filter((item, i) => item.props.id
          ? item.props.id === activeTab
          : `${i}` === activeTab)
        : children}
    </div>
  );
};

type TabProps = PropsWithChildren & {
  id?: string;
  title: string;
  active?: boolean;
  disabled?: boolean;
}

const Tab = ({ children }: TabProps) => children;

Tabs.Tab = Tab;

export default Tabs;
