import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Container from '@shared/components/container/container';
import { useScrollToTop } from '../../shared/hooks/use-scrolltotop';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import LoaderCenter from '@/shared/components/loader-center/loader-center';
import { DEFAULT_LANG } from '@/app-config';
import {
  // ResultsControlsMobile,
  ResultsGameMobile,
  useGetResultsGameListQuery,
} from '@/modules/results';

import './results-games-screen-mobile.scss';

const b = bemCn('results-games-screen-mobile');

const ResultsGamesScreenMobile = () => {
  const { t } = useTranslation();
  useScrollToTop();
  const {
    lang = DEFAULT_LANG,
    date = 0,
    sportId = 1,
    tournamentId = 0,
  } = useParams();

  const { data: gamesData, isLoading } = useGetResultsGameListQuery({
    lang,
    date: Number(date),
    sportId: Number(sportId),
    champId: Number(tournamentId),
  });

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        <h1 className={b('title')}>
          {t('main-nav.results', 'Результаты')}
        </h1>
        {/* <ResultsControlsMobile className={b('controlls')} /> */}

        {isLoading && <LoaderCenter />}
        {!isLoading && gamesData?.map((game) => (
          <ResultsGameMobile
            key={game.id}
            sportId={sportId.toString()}
            game={game}
          />
        ))}
      </Container>
    </div>
  );
};

export default ResultsGamesScreenMobile;
