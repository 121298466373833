import { bemCn } from '@shared/utils/helpers/bem-cn';
import { NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import AviatrixLogo from '@assets/provider-logos/aviatrix-logo-flat.svg?react';
import JetxLogo from '@assets/provider-logos/jetx-logo.svg?react';
import { useTranslation } from 'react-i18next';
import IconSvg, { AvailableIcon } from '@/shared/components/icon-svg/icon-svg';
import { AppFeature } from '@/app-config';
import './site-nav.scss';

const b = bemCn('site-nav');

type MenuItem = {
  id: string;
  title: string;
  i18nKey?: string;
  link: string;
  end?: boolean;
  icon?: AvailableIcon;
  disabled?: boolean;
  img?: React.ReactNode;
}

const menuItems: MenuItem[] = [
  {
    id: 'home',
    i18nKey: 'main-nav.home',
    title: 'Главная',
    link: '/',
    icon: 'home',
    end: true,
  },
  {
    id: 'crash-games',
    title: 'Crash Games',
    link: '/crash-games',
    icon: 'rocket',
    end: true,
    disabled: !AppFeature.KES_PROJECT,
  },
  {
    id: 'highlights',
    title: 'Highlights',
    link: '/highlights',
    icon: 'fireball',
    disabled: !AppFeature.KES_PROJECT || AppFeature.DISABLE_SPORTBOOK,
  },
  {
    id: 'live',
    i18nKey: 'main-nav.live',
    title: 'Live',
    link: '/live',
    icon: 'live',
    disabled: AppFeature.DISABLE_SPORTBOOK,
  },
  {
    id: 'line',
    i18nKey: 'main-nav.line',
    title: 'Линия',
    link: '/line',
    icon: 'football',
    disabled: AppFeature.DISABLE_SPORTBOOK,
  },

  {
    id: 'bonuses',
    title: 'Promotions',
    link: '/bonuses',
    icon: 'fire',
    disabled: !AppFeature.KES_PROJECT,
  },
  {
    id: 'casino',
    i18nKey: 'main-nav.casino',
    title: 'Казино',
    link: '/games',
    icon: 'coin',
  },
  {
    id: 'results',
    i18nKey: 'main-nav.results',
    title: 'Результаты',
    link: '/results',
    icon: 'cup',
    disabled: AppFeature.DISABLE_SPORTBOOK,
  },
  {
    id: 'aviator',
    title: 'Aviator',
    link: '/games/vegas/aviator_spribe',
    icon: 'aviator',
    disabled: !AppFeature.KES_PROJECT,
  },
  {
    id: 'jetx',
    title: 'JetX',
    link: '/games/smartsoft/JetX/JetX',
    img: (
      <JetxLogo className={b('item-img', { type: 'jetx' })}
        width={35}
        height={15}
      />
    ),
    disabled: AppFeature.KES_PROJECT,
  },
  {
    id: 'aviatrix',
    title: 'Aviatrix',
    link: '/games/aviatrix',
    img: (
      <AviatrixLogo className={b('item-img', { type: 'aviatrix' })}
        width={59}
        height={15}
      />
    ),
    disabled: AppFeature.KES_PROJECT,
  },
  {
    id: 'download-app',
    title: 'Download app',
    link: '#',
    icon: 'iphone',
    end: true,
    disabled: !AppFeature.KES_PROJECT,
  },
  // {
  //   id: 'streams',
  //   title: 'Трансляции',
  //   link: '/streams',
  // },
];

type SiteNavProps = {
  className?: string;
}


const SiteNav = ({ className }: SiteNavProps) => {
  const [activeItem, setActiveItem] = useState(menuItems[0].id);
  const listRef = useRef<HTMLUListElement>(null);
  const activeItemRef = useRef<HTMLLIElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (listRef.current && activeItemRef.current) {
      const listRect = listRef.current.getBoundingClientRect();
      const activeItemRect = activeItemRef.current.getBoundingClientRect();

      if (activeItemRect.right > listRect.right) {
        activeItemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'end',
        });
      }

      if (activeItemRect.left < listRect.left) {
        activeItemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }
  }, [activeItem]);

  return (
    <nav className={b({ 'with-icons': AppFeature.KES_PROJECT }, className)}>
      <ul className={b('list', 'no-scrollbar')} ref={listRef}>
        {menuItems.filter((item) => !item.disabled).map((item) => (
          <li className={b('item')}
            key={item.id}
            ref={item.id === activeItem ? activeItemRef : null}
          >
            <NavLink
              className={({ isActive }) => b('link', { active: isActive && item.id !== 'download-app' })}
              to={item.link}
              onClick={() => setActiveItem(item.id)}
              end={item.end}
            >
              {item.icon && (
                <IconSvg className={b('icon')}
                  width={20}
                  height={20}
                  name={item.icon}
                />
              )}
              {item.img && item.img}
              <span className={b('title', item.img ? 'visually-hidden' : '')}>
                {item.i18nKey ? t(item.i18nKey, item.title) : item.title}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SiteNav;
