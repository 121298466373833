import { useActivatePromocodeMutation } from '@/modules/user/@x/promocodes';
import PromocodeActivateForm, { PromocodeActivateFormProps } from './promocode-activate-form';
import { handlePromocodeError } from '../../utils/handle-promocode-error';
import { useEffect, useState } from 'react';
import PromocodeSuccessModal from '../promocode-success-modal/promocode-success-modal';

type Props = Omit<PromocodeActivateFormProps, 'onSubmit' | 'loading' | 'error'>

const PromocodeActivateFormConnected = (props: Props) => {
  const [activate, { isLoading, error, isSuccess }] = useActivatePromocodeMutation();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setShowSuccessModal(true);
    }
  }, [isSuccess, isLoading]);

  return (
    <>
      <PromocodeActivateForm
        {...props}
        error={handlePromocodeError(error)}
        onSubmit={({ promocode }) => activate(promocode)}
        loading={isLoading}
      />
      <PromocodeSuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      />
    </>
  );
};

export default PromocodeActivateFormConnected;
