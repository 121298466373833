import AppModal from '@/shared/components/app-modal/app-modal';
import { useTranslation } from 'react-i18next';
import { useInitPasswordResetPhoneMutation } from '../../model/auth-api';
import PasswordResetByPhoneForm from '../password-reset-by-phone-form/password-reset-by-phone-form';
import { FormData } from '../password-reset-by-phone-form/password-reset-by-phone-form';
import { handleResetPassByPhoneError } from '../../utils/handle-reset-pass-by-phone-error';


type PasswordResetModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const PasswordResetByPhoneModal = ({ isOpen, onClose }: PasswordResetModalProps) => {
  const { t } = useTranslation();

  const [initReset, { isLoading, initError }] =
    useInitPasswordResetPhoneMutation({
      selectFromResult: (result) => ({
        ...result,
        initError: handleResetPassByPhoneError(result.error)
      })
    });

  const handleSubmit = async (formData: FormData) => {
    await initReset(formData);
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('reset-pass.title', 'Восстановление пароля')}
      description={t('reset-pass.phone-description')}
    >
      <PasswordResetByPhoneForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        error={initError}
      />
    </AppModal>
  );
};

export default PasswordResetByPhoneModal;
