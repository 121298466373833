import { bemCn } from '@shared/utils/helpers/bem-cn';
import { ITopupMethodsItem } from '@shared/store/app/types';
import Button from '@shared/components/button/button';
import { TextFieldControlled } from '@/shared/components/text-field/text-field';
import { getAmountString } from '@shared/utils/helpers/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PaymentMethod from '@/modules/user-wallet/components/payment-method/payment-method';
import { useTranslation } from 'react-i18next';
import i18n from '@/shared/i18n/i18n';
import { useInitPaymentMutation } from '@/modules/user/@x/user-wallet';
import { handleQueryError } from '@/shared/utils/helpers/handle-query-error';
import './topup-form.scss';


const paymantInitErrorTitle: Record<string, string> = {
  'Payment timeout': i18n.t('backend-errors.payment.payment-timeout', 'Попробуйте через минуту')
};

type TopupFormProps = {
  method: ITopupMethodsItem;
}

type TopupFormData = {
  amount: number;
}

const schema = (minAmount: number, maxAmount: number, currency: string) => yup.object({
  amount: yup
    .number()
    .min(minAmount, `${i18n.t('topups.modal.errors.min-amount')} - ${getAmountString(minAmount, currency)}`)
    .max(maxAmount, `${i18n.t('topups.modal.errors.max-amount')} - ${getAmountString(maxAmount, currency)}`)
    .required(i18n.t('topups.modal.errors.empty-amount'))
    .typeError(i18n.t('topups.modal.errors.empty-amount')),
}).required();

const b = bemCn('topup-form');

const TopupForm = ({ method }: TopupFormProps) => {
  const { t } = useTranslation();
  const {
    title,
    icons,
    minAmount,
    maxAmount,
    currency,
    system,
    name,
  } = method;

  const [initPayment, { isLoading, error, isError }] = useInitPaymentMutation();
  const queryError = handleQueryError(error);
  let rootError = '';

  if (isError && queryError) {
    if (typeof queryError === 'string') {
      rootError = paymantInitErrorTitle[queryError] ?? queryError;
    } else if (typeof queryError.detail === 'string') {
      rootError = paymantInitErrorTitle[queryError.detail] ?? queryError;
    } else {
      rootError = t('common.unknown-error', 'Неизвестная ошибка');
    }
  }

  const { control, handleSubmit } =
    useForm<TopupFormData>({
      defaultValues: { amount: 0 },
      mode: 'all',
      resolver: yupResolver(schema(minAmount, maxAmount, currency)),
    });


  const handleFormSubmit = ({ amount }: TopupFormData) => {
    if (amount) {
      initPayment({
        amount: amount,
        systemName: system,
        method: name,
        'pay_with': name,
      });
    }

    console.log({
      amount: amount,
      systemName: system,
      method: name,
      'pay_with': name,
    });
  };

  return (
    <form className={b()} action="" onSubmit={handleSubmit(handleFormSubmit)}>
      {isError && rootError && (
        <p className={b('error')}>{rootError}</p>
      )}
      <PaymentMethod className={b('method')}
        title={title}
        icons={icons}
        active
        disabled
      />
      <TextFieldControlled className={b('field')}
        control={control}
        type='number'
        id='amount'
        name='amount'
        placeholder={t('topups.modal.amount', 'Сумма')}
        defaultValue={0}
        disabled={isLoading}
      />
      <p className={b('desc')}>
        <span className={b('desc-item')}>
          {t('topups.modal.limit-description', 'Сумма одного депозита')}
        </span>
        <span className={b('desc-item')}>
          {t('from')} {getAmountString(minAmount, currency)} {t('to', 'to')} {getAmountString(maxAmount, currency)}
        </span>
      </p>
      <Button className={b('btn')} type='submit' loading={isLoading}>
        {t('topups.modal.submit-btn', 'Пополнить')}
      </Button>
    </form>
  );
};

export default TopupForm;
