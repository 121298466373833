import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthorizationStatus, NameSpace } from '../../../shared/utils/constants/common';
import authApi from './auth-api';
import userApi from '../../user/store/user-api';
import { dropToken, saveToken } from '../utils/token';
import { AuthState, AuthModal } from './types';
import { dropRefCode } from '../utils/ref-code';
import { handleQueryError } from '@/shared/utils/helpers/handle-query-error';
import browserHistory from '@/browser-history';
import { isEmailVerifyingError } from '../utils/is-email-verification-error';

const initialState: AuthState = {
  authorizationStatus: AuthorizationStatus.Unknown,
  activeModal: null,
  passwordResetId: null,
  verifyPhone: null,
  verifyEmail: null,
};

const authSlice = createSlice({
  name: NameSpace.Auth,
  initialState,
  reducers: {
    changeAuthModal: (state, action: PayloadAction<AuthModal>) => {
      state.activeModal = action.payload;
    },
    closeAuthModal: (state) => {
      state.activeModal = null;
    },
    openLoginModal: (state) => {
      state.activeModal = 'login';
    },
    openRegisterModal: (state) => {
      state.activeModal = 'registration';
    },
    openPasswordChangeModal: (state, action: PayloadAction<string>) => {
      state.passwordResetId = action.payload;
      state.activeModal = 'password-change';
    },
    logout: (state) => {
      state.authorizationStatus = AuthorizationStatus.NoAuth;
      dropToken();
    },
    setVerifyPhone: (state, action: PayloadAction<string | null>) => {
      state.verifyPhone = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(authApi.endpoints.login.matchPending, (state, action) => {
        const args = action.meta.arg.originalArgs;
        state.verifyEmail = args.login;
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state) => {
        state.verifyEmail = null;
        state.authorizationStatus = AuthorizationStatus.Auth;
        if (state.activeModal === 'login') {
          state.activeModal = null;
        }
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        const error = action.payload;

        if (isEmailVerifyingError(error)) {
          state.activeModal = 'email-verification-error';
        }
        state.authorizationStatus = AuthorizationStatus.NoAuth;
      })
      .addMatcher(authApi.endpoints.loginByPhone.matchFulfilled, (state) => {
        state.authorizationStatus = AuthorizationStatus.Auth;
        if (state.activeModal === 'login') {
          state.activeModal = null;
          state.verifyPhone = null;
        }
      })
      .addMatcher(authApi.endpoints.loginByPhone.matchRejected, (state, action) => {
        const queryError = handleQueryError(action.payload);
        if (queryError && typeof queryError !== 'string') {
          const { detail } = queryError;
          if (detail === 'Phone verification needed') {
            state.activeModal = 'phone-confirm';
          }
        }
        state.authorizationStatus = AuthorizationStatus.NoAuth;
      })

      .addMatcher(authApi.endpoints.registerByEmail.matchFulfilled, (state, action) => {
        const isVerification = action.payload.verification;

        if (isVerification) {
          state.activeModal = 'email-confirm';
        } else {
          state.activeModal = 'registration-success';
        }
      })
      .addMatcher(authApi.endpoints.registerByPhone.matchFulfilled, (state, action) => {
        const isVerification = action.payload.verification;

        if (isVerification) {
          state.verifyPhone = action.payload.phoneNumber;
          state.activeModal = 'phone-confirm';
        } else {
          state.activeModal = 'registration-success';
          state.verifyPhone = null;
        }
      })
      .addMatcher(authApi.endpoints.registerByTelegram.matchFulfilled, (state, action) => {
        const vereficationUrl = action.payload;
        if (vereficationUrl) {
          window.open(vereficationUrl, '_self');
        }
        state.activeModal = 'registration-success';
      })

      .addMatcher(authApi.endpoints.verifyEmail.matchRejected, (state) => {
        state.activeModal = 'verify-error';
      })
      .addMatcher(authApi.endpoints.verifyEmail.matchFulfilled, (state, action) => {
        state.activeModal = 'registration-success';
        const token = action.payload;

        if (token) {
          saveToken(token);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .addMatcher(authApi.endpoints.verifyPhone.matchFulfilled, (state, action) => {
        state.activeModal = 'registration-success';
        state.verifyPhone = null;
        const token = action.payload;

        if (token) {
          saveToken(token);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })

      .addMatcher(authApi.endpoints.changePassword.matchFulfilled, (state) => {
        state.activeModal = 'password-reset-success';
      })

      .addMatcher(authApi.endpoints.resetPassword.matchRejected, (state) => {
        state.activeModal = 'password-reset-error';
      })

      .addMatcher(userApi.endpoints.checkAuth.matchFulfilled, (state) => {
        state.authorizationStatus = AuthorizationStatus.Auth;
      })
      .addMatcher(userApi.endpoints.checkAuth.matchRejected, (state) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth;
        dropToken();
      })
      .addMatcher(authApi.endpoints.clickRefCode.matchRejected, () => {
        dropRefCode();
      })
      .addMatcher(authApi.endpoints.initPasswordResetPhone.matchFulfilled, (state) => {
        state.activeModal = 'password-reset-by-phone-confirm';
      })
      .addMatcher(authApi.endpoints.resetPaswordSmsCode.matchFulfilled, (state, action) => {
        state.activeModal = null;
        const resetId = action.payload;

        if (resetId) {
          browserHistory.push(`/password/reset/${resetId}`);
        }
      })
      .addMatcher(authApi.endpoints.sendEmailVerification.matchFulfilled, (state) => {
        if (state.activeModal === 'email-verification-error') {
          state.activeModal = null;
        }
      });
  },
});

export const {
  logout,
  openPasswordChangeModal,
} = authSlice.actions;

export default authSlice;
