import { bemCn } from '@/shared/utils/helpers/bem-cn';
import Form from '@/shared/components/form/form';
import * as yup from 'yup';
import i18n from '@/shared/i18n/i18n';
import { useTranslation } from 'react-i18next';
import Button from '@/shared/components/button/button';
import { FC } from 'react';
import './password-reset-by-phone-confirm-form.scss';

const b = bemCn('password-reset-by-phone-confirm-form');

export type FormData = {
  smsCode: string;
}

export type PasswordResetByPhoneConfirmFormError = {
  root?: string;
  smsCode?: string;
}

const schema = yup.object({
  smsCode: yup.string()
    .length(4, i18n.t('confirm-phone.errors.wrong-length'))
    .required(i18n.t('confirm-phone.errors.empty-code', 'Введите код')),
}).required();

const defaultValues: FormData = {
  smsCode: '',
};

type Props = {
  className?: string;
  isLoading?: boolean;
  onFormSubmit?: (formData: FormData) => void;
  error?: PasswordResetByPhoneConfirmFormError;
}

const PasswordResetByPhoneConfirmForm: FC<Props> = ({
  className,
  isLoading,
  onFormSubmit,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <Form<FormData> className={b(null, className)}
      form={{
        schema,
        defaultValues,
        error,
      }}
      onSubmit={onFormSubmit}
    >
      <Form.RootError className={b('error')} />
      <Form.TextField<FormData> className={b('field')}
        name='smsCode'
        placeholder='XXXX'
        disabled={isLoading}
      />
      <Button className={b('button')}
        type="submit"
        disabled={isLoading}
      >
        {t('confirm-phone.send-request', 'Отправить')}
      </Button>
    </Form>
  );
};

export default PasswordResetByPhoneConfirmForm;
