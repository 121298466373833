import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../../shared/hooks';
import { getIsAuth } from '@/modules/auth';
import { getBasket } from '../../models/selectors';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DataType } from '@/modules/line';
import { useDeleteFromBasketMutation, useGetBasketQuery, usePlageBetMutation } from '../../api/basket-api';
import { handleBasketError } from '../../models/handle-basket-error';
import { useState, useEffect } from 'react';
import i18n from '@/shared/i18n/i18n';
import { Lang } from '../../models/types';
import { useBettingTaxInfo } from '@/shared/hooks/use-betting-tax-info';

export type BasketForm = {
  amount: number;
  agree: boolean;
  bonusBalance: boolean;
}

export type BasketFormError = {
  root?: string;
  amount?: string;
  agree?: string;
}

const getSchema = (
  minAmount: number,
  maxAmount: number,
  mainBalanceAmount: number,
  bonusBalanceAmount: number,
) => yup
  .object().shape({
    bonusBalance: yup.bool()
      .oneOf([true, false],)
      .required(),
    amount: yup.number()
      .typeError(i18n.t('cart.errors.empty-amount', 'Введите сумму ставки'))
      .min(minAmount, `${i18n.t('cart.errors.min-amount', 'Минимальная сумма ставки - ')}${minAmount}`)
      .max(maxAmount, `${i18n.t('cart.errors.max-amount', 'Максимальная сумма ставки - ')}${maxAmount}`)
      .when('bonusBalance', (bonusBalance, schema) => {
        const balance = bonusBalance[0]
          ? bonusBalanceAmount
          : mainBalanceAmount;

        return schema.max(balance, i18n.t('cart.errors.balance'));
      })
      .required(i18n.t('cart.errors.empty-amount', 'Введите сумму ставки')),
    agree: yup.bool()
      .oneOf([true], i18n.t('cart.errors.check-agree', 'Необходимо согласие'))
      .required(),
  })
  .required();

export const useBasket = () => {
  const isAuth = useAppSelector(getIsAuth);
  const [placeBet, { isLoading: isPlaceBetLoading, error, isSuccess: isBetPlaceSuccess }] = usePlageBetMutation();
  const [deleteBet, { isLoading: isDeleting }] = useDeleteFromBasketMutation();
  const { isLoading: isBasketLoading } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    pollingInterval: 10000
  });

  const placeError = handleBasketError(error);

  const {
    items,
    currency,
    minBet,
    maxBet,
    totalRate,
    isEmpty,
    walletAmount,
    bonusAmount,
  } = useAppSelector(getBasket);

  const { control, handleSubmit, setError, watch } = useForm<BasketForm>({
    defaultValues: {
      agree: true,
      amount: 0,
      bonusBalance: false,
    },
    resolver: yupResolver(getSchema(minBet, maxBet, walletAmount, bonusAmount)),
  });


  const handleDeleteClickBet = async (betId: string, dataType: DataType) => {
    await deleteBet({
      dataType,
      betId,
    });
  };

  const handleFormSubmit = async ({ amount, agree, bonusBalance }: BasketForm) => {
    if (amount) {
      await placeBet({
        amount,
        agree,
        lang: i18n.resolvedLanguage as Lang,
        balanceType: bonusBalance ? 'bonus' : 'real',
      });
    }
  };

  useEffect(() => {
    if (placeError?.agree) {
      setError('agree', {
        message: placeError?.agree
      });
    }

    if (placeError?.amount) {
      setError('amount', {
        message: placeError?.amount
      });
    }


  }, [placeError?.agree, placeError?.amount]);

  const betAmount = watch('amount') ?? 0;
  const taxInfo = useBettingTaxInfo(betAmount, totalRate);

  const [betPlaceSuccess, setBetPlaceSuccess] = useState(false);

  useEffect(() => {
    if (isBetPlaceSuccess) {
      setBetPlaceSuccess(true);
    }
  }, [isBetPlaceSuccess]);

  const handleBetSuccessModalClose = (onClose?: () => void) => {
    setBetPlaceSuccess(false);
    onClose?.();
  };

  return {
    isBasketLoading,
    isDeleting,
    isPlaceBetLoading,
    handleDeleteClickBet,
    prizeAmount: totalRate * betAmount,
    taxInfo,
    items,
    currency,
    minBet,
    maxBet,
    totalRate,
    isEmpty,
    control,
    handleSubmit: handleSubmit(handleFormSubmit),
    placeError,
    isBetPlaceSuccess,
    betPlaceSuccess,
    handleBetSuccessModalClose,
  };
};
