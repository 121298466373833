import { bemCn } from '@/shared/utils/helpers/bem-cn';
import Container from '@/shared/components/container/container';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import WithdrawalsList from './withdrawals-list/withdrawals-list';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@/shared/components/breadcrumbs/breadcrumbs';
import './withdrawals-page.scss';

const b = bemCn('withdrawals-page');

const WithdrawalsPage = () => {
  useScrollToTop();
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <Container className={b('container')}>
        <Breadcrumbs />
        <div className={b('head')}>
          <h1 className={b('title')}>
            {t('profile.nav.withdrawal-requests', 'Заявки на вывод')}
          </h1>
        </div>
        <WithdrawalsList />
      </Container>
    </div>
  );

};


export default WithdrawalsPage;
