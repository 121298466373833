import { AuthModal } from './types';
import { AuthorizationStatus, NameSpace } from '../../../shared/utils/constants/common';

const getAuthState = (state: RootState) => state[NameSpace.Auth];

export const getAuthorizationStatus = (state: RootState): AuthorizationStatus =>
  getAuthState(state).authorizationStatus;

export const getAuthModal = (state: RootState): AuthModal =>
  getAuthState(state).activeModal;

export const getIsAuth = (state: RootState): boolean =>
  getAuthState(state).authorizationStatus === AuthorizationStatus.Auth;

export const getPasswordResetId = (state: RootState): string | null =>
  getAuthState(state).passwordResetId;

export const selectVerifyPhone = (state: RootState) =>
  getAuthState(state).verifyPhone;

export const selectVerifyEmail = (state: RootState) => getAuthState(state).verifyEmail;
