import { getSportIconName } from '@/shared/utils/helpers/common';
import { Coupon, CouponBet, CouponBetServer, Coupons, CouponServer, GetCouponsRes } from './types';
import { BettingTaxInfo } from '@/shared/hooks/use-betting-tax-info';

const adapteBet = (bet: CouponBetServer): CouponBet => ({
  id: bet.barcode,
  updated: bet.updated,
  rate: bet.rate,
  status: bet.status,
  team1: bet.metainfo.team1,
  team2: bet.metainfo.team2,
  sportIcon: getSportIconName(String(bet.metainfo.sport_id)),
  sportName: bet.metainfo.sport_name,
  tournamentName: bet.metainfo.tournament_name,
  gameDate: bet.metainfo.game_date,
  outcomeName: bet.metainfo.outcome_name,
  subGameName: bet.metainfo.sub_game_name,
  betScore: bet.metainfo.bet_score,
  gameScore: bet.metainfo.game_score,
});

const calcCouponExcise = (coupon: CouponServer): BettingTaxInfo => {
  const exciseTax = coupon.excise ?? 0;
  const stakeAfterTax = coupon.bet_amount - exciseTax;
  const possibleWin = stakeAfterTax * coupon.rate;
  const winnings = possibleWin - stakeAfterTax;
  const withHoldingTax = coupon.withhold ?? 0;
  const finalPay = coupon.win_amount ?? 0;

  return ({
    exciseTax,
    stakeAfterTax,
    possibleWin,
    winnings,
    withHoldingTax,
    finalPay
  });
};

export const adapteCoupon = (coupon: CouponServer): Coupon => ({
  id: String(coupon.id),
  created: coupon.created,
  type: coupon.type,
  currency: coupon.currency,
  status: coupon.status,
  betAmount: coupon.bet_amount,
  winAmount: coupon.win_amount,
  isLive: coupon.is_live,
  rate: coupon.rate,
  balanceType: coupon.balance_type,
  excise: coupon.excise,
  withhold: coupon.withhold,
  bets: coupon.bets.map(adapteBet),
  taxInfo: calcCouponExcise(coupon),
});

export const adapteCoupons = (res: GetCouponsRes): Coupons => ({
  count: res.count,
  items: res.items.map(adapteCoupon)
});
