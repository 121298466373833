import { createContext, useContext } from 'react';

interface IRegisterFormContext {
  loading?: boolean;
  selectedCurrency?: string | null;
  setSelectedCurrency?: (currency: string | null) => void;
}

export const RegisterFormContext = createContext<IRegisterFormContext | undefined>(undefined);

export const useRegisterFormContext = () => {
  const context = useContext(RegisterFormContext);

  if (!context) {
    throw new Error('useRegisterFormContext must be used within RegisterForm');
  }

  return context;
};
