// Общие утилиты без категории

import { AvailableIcon } from '@shared/components/icon-svg/icon-svg';
import { currenciesTitles } from '../constants/currency';
import { methodIcons } from '../constants/payment-icons';
import { detect } from 'detect-browser';

export const getAmountString = (amount: number | string, currency: string, hideValue = false) => {
  const symbol = currenciesTitles[currency]?.symbol ?? currency;

  return `${hideValue ? '**.**' : amount} ${symbol}`;
};


export const roundNumber = (number: number, digits: number | undefined = 2) => {
  if (digits <= 0) {
    return number;
  }
  const divider = 10 * (digits ?? 2);
  return Math.floor((number * divider)) / divider;
};


export const scrollToTop = (shouldBeSmooth?: boolean) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: shouldBeSmooth ? 'smooth' : 'auto'
  });
};


export const getPaymentMethodIcons = (method: string): string[] =>
  methodIcons[method.toLocaleLowerCase()] ?? [];

const sportIconNames: Record<string, AvailableIcon> = {
  0: 'cup',
  1: 'football',
  2: 'hockey',
  3: 'basketball',
  4: 'tennis',
  5: 'baseball',
  6: 'volleyball',
  7: 'rugby',
  8: 'handball',
  9: 'boxing',
  10: 'table-tennis',
  11: 'chess',
  13: 'rugby',
  14: 'futsal',
  16: 'badminton',
  19: 'biathlon',
  21: 'darts',
  22: 'alpine-skiing',
  24: 'skiing',
  26: 'f1',
  29: 'beach-volleyball',
  31: 'motosport',
  36: 'bicycle-racing',
  40: 'games',
  41: 'golf-1',
  56: 'mma',
  57: 'athletics',
  62: 'rowing',
  66: 'cricket',
  138: 'surfing',
  189: 'mma'
};

export const getSportIconName = (sportId: string): AvailableIcon =>
  sportIconNames[sportId] || 'cup';


export const sortByTopEntries = <T, K>(items: T[], entries: K[], cb: (item: T) => K): T[] => {
  if (entries.length <= 0) {
    return items;
  }
  const temp1: T[] = [];
  const temp2: T[] = [];

  items.forEach((item) => {
    if (entries.includes(cb(item))) {
      temp1.push(item);
    } else {
      temp2.push(item);
    }
  });

  return temp1.sort((a, b) => entries.indexOf(cb(a)) - entries.indexOf(cb(b))).concat(temp2);
};

export const filterByEntries = <T, K>(items: T[], entries: K[], cb: (item: T) => K): T[] =>
  items.filter((item) => entries.includes(cb(item)));

export const extractInitials = (username: string): string => {
  const words = username.trim().split(/\s+/);
  const filteredWords = words.filter((word) => word !== '').slice(0, 2);
  const initials = filteredWords.map((word) => word[0].toUpperCase());
  return initials.join('');
};

export const getKey = (...values: Array<string | number>) => values.join('-');

export const getBodyScrollTop = () => (document.documentElement && document.documentElement.scrollTop) || (document.body && document.body.scrollTop);

export const existVerticalScroll = () => document.body.offsetHeight > window.innerHeight;

const mobileOs = ['iOS', 'Android OS', 'BlackBerry OS', 'Windows Mobile'];


export const checkIsMobileDevice = () => {
  const userOs = detect()?.os;
  return (mobileOs.some((os) => userOs === os));
};

export const roundToCent = (num: number) => Math.round(num * 100) / 100;
