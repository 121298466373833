import LoaderBig from '@/shared/components/loader-big/loader-big';
import { useGetAppSportsQuery, useGetBannersQuery, useGetCurrenciesQuery, useGetStatusQuery, useGetTaxesQuery } from '@shared/store/app/app-api';
import { useCheckAuthQuery, useGetUserGeoQuery, useGetUserWalletsQuery, useGetCasinoBonusesQuery, useGetFavoriteMatchesQuery, useGetFavoritesGamesQuery } from '@/modules/user';
import { PropsWithChildren, useEffect, useState } from 'react';
import { SiteMaintenance } from '@/widgets/site-maintenance';
import { useGetUnreadNotificationsCountQuery } from '@/modules/notifications';
import { useFetchGames, useGetLastwinsQuery } from '@/modules/casino';
import { useAppSelector } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';
import { AppFeature } from '@/app-config';

const AppLoader = ({ children }: PropsWithChildren) => {
  const isAuth = useAppSelector(getIsAuth);
  const { isLoading: isStatusLoading, data: status } = useGetStatusQuery();
  const { isLoading: isAuthLoading, isSuccess: isAuthSuccess } =
    useCheckAuthQuery(undefined, { skip: !status?.system });
  const { isLoading: isUserGeoLoading } = useGetUserGeoQuery();
  useGetBannersQuery(undefined, { skip: !status?.system });
  useGetCurrenciesQuery(undefined, { skip: !status?.system });
  useGetAppSportsQuery(undefined, { skip: AppFeature.DISABLE_SPORTBOOK });
  const { isLoading: isLaswWinsLoading } = useGetLastwinsQuery(undefined, { skip: !status?.system });

  const { isLoading: isCasinoLoading } = useFetchGames();
  const { isLoading: isTaxesLoading } = useGetTaxesQuery();

  useGetCasinoBonusesQuery(undefined, {
    skip: !status?.system || !isAuthSuccess || !isAuth,
    // pollingInterval: 10000
  });
  const { isLoading: isWalletLoading } = useGetUserWalletsQuery(undefined, {
    skip: !status?.system || !isAuthSuccess || !isAuth,
    pollingInterval: 10000
  });
  useGetUnreadNotificationsCountQuery(undefined, {
    pollingInterval: 60000,
    skip: !status?.system || !isAuthSuccess || !isAuth,
  });
  useGetFavoriteMatchesQuery(undefined, { skip: !status?.system || !isAuthSuccess || !isAuth || AppFeature.DISABLE_SPORTBOOK, });
  useGetFavoritesGamesQuery(undefined, { skip: !status?.system || !isAuthSuccess, });

  const [isAppLoaded, setAppLoaded] = useState(false);

  const isLoading = isStatusLoading
    || isAuthLoading
    || isUserGeoLoading
    || isWalletLoading
    || isCasinoLoading
    || isLaswWinsLoading
    || isTaxesLoading;

  useEffect(() => {
    if (!isLoading) {
      setAppLoaded(true);
    }
  }, [isLoading]);

  if (!isAppLoaded) {
    return <LoaderBig />;
  }

  if (!status?.system) {
    return <SiteMaintenance />;
  }


  return children;
};


export default AppLoader;
