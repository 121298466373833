import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import { useAppSelector } from '@/shared/hooks';
import { getCasinoGamesByCategory } from '@/modules/casino';
import Breadcrumbs from '@/shared/components/breadcrumbs/breadcrumbs';
import { CasinoGameCard } from '@/modules/casino';

import Container from '@/shared/components/container/container';
import './crash-games-screen.scss';

const b = bemCn('crash-games-screen');

const CrashGamesScreen = () => {
  useScrollToTop();
  const games = useAppSelector((state) => getCasinoGamesByCategory(state, 'crash games'));

  if (games.length <= 0) {
    return null;
  }

  return (
    <section className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs />
        <div className={b('head')}>
          <h2 className={b('title')}>Crash Games</h2>
        </div>

        <div className={b('list')}>
          {games.map((game) => (
            <CasinoGameCard
              className={b('item')}
              key={game.id + game.link}
              name={game.name}
              link={game.link}
              preview={game.preview}
              gameProvider={game.provaider}
              isFavorite={game.isFavorite}
              game={game}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default CrashGamesScreen;
