import Button from '@shared/components/button/button';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import './not-found-screen.scss';
import { useTranslation } from 'react-i18next';

const b = bemCn('not-found-screen');

const NotFoundScreen = () => {
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <h1 className={b('title')}>
          {t('not-found.title', 'Страница не найдена')}
        </h1>
        <p className={b('message')}>
          {t('not-found.text-1', 'Упс...Что-то пошло не так.')}<br />
          {t('not-found.text-2', 'Страница, которую вы искали, не найдена')}
        </p>
        <Button className={b('button')} to="/">
          {t('main-nav.home', 'На главную')}
        </Button>
      </div>
    </div>
  );
};

export default NotFoundScreen;
