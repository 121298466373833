import { HandledError, handleQueryError } from '@/shared/utils/helpers/handle-query-error';


export const isEmailVerifyingError = (error?: HandledError): boolean => {
  const queryError = handleQueryError(error);

  if (queryError) {
    if (typeof queryError === 'string') {
      return false;
    }
    const { detail } = queryError;

    if (detail === 'Email verification needed') {
      return true;
    }
  }

  return false;
};
