import { bemCn } from '@shared/utils/helpers/bem-cn';
import UserNav from '../user-nav/user-nav';
import SiteNav from '../site-nav/site-nav';
import Logotype from '@/shared/components/logotype/logotype';
import ButtonIcon from '@/shared/components/button-icon/button-icon';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { AppFeature } from '@/app-config';
import ButtonMenu from '@/shared/components/button-menu/button-menu';
import Mobile from '@/shared/components/responsive/mobile';
import './page-header.scss';

type locationState = null | {
  returnUrl: string;
}

type PageHeaderProps = {
  className?: string;
  hideNav?: boolean;
  showReturnButton?: boolean;
}

const b = bemCn('page-header');

const PageHeader = (props: PageHeaderProps) => {
  const { className, hideNav, showReturnButton } = props;
  const location = useLocation();
  const returnUrl = (location?.state as locationState)?.returnUrl ?? '/games';
  const isAuth = useAppSelector(getIsAuth);
  const { isDesktop } = useResponsive();

  return (
    <header className={b({
      sticky: AppFeature.KES_PROJECT,
      'bottom-nav': AppFeature.KES_PROJECT,
    }, className)}
    >
      <div className={b('wrapper')}>
        <div className={b('top-wrapper')}>
          {AppFeature.KES_PROJECT && (
            <Mobile><ButtonMenu className={b('menu-btn')} /></Mobile>
          )}
          {showReturnButton ? (
            <ButtonIcon
              className={b('return-button')}
              icon='chevron-right'
              variant='secondary'
              to={returnUrl}
            />
          ) : (
            <Logotype className={b('logo')} showFlag={isDesktop || !isAuth} />
          )}
          <UserNav className={b('user-nav')} />
        </div>

        {!hideNav && (
          <SiteNav className={b('site-nav')} />
        )}
      </div>
    </header>
  );
};

export default PageHeader;
