import { bemCn } from '@/shared/utils/helpers/bem-cn';
import Container from '@/shared/components/container/container';
import Breadcrumbs from '@/shared/components/breadcrumbs/breadcrumbs';
import { UserSettings } from '@/widgets/settings';
import { useTranslation } from 'react-i18next';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import { useThemeContext } from '@/modules/theme';
import './user-settings-page.scss';

const b = bemCn('user-settings-page');

const UserSettingsPage = () => {
  useScrollToTop();
  const { t } = useTranslation();
  const { isDark } = useThemeContext();

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        <h1 className={b('title')}>
          {t('settings.title', 'Настройки')}
        </h1>
        <UserSettings inverted={isDark} />
      </Container>
    </div>
  );
};

export default UserSettingsPage;
