

import operatorIcon from '@assets/payment-systems/operator.svg';
import visaIcon from '@assets/payment-systems/visa.svg';
import mastercardIcon from '@assets/payment-systems/mastercard-colored.svg';
import qiwiIcon from '@assets/payment-systems/qiwi.svg';
import webmoneyIcon from '@assets/payment-systems/webmoney.svg';
import umoneyIcon from '@assets/payment-systems/umoney.svg';
import sbpIcon from '@assets/payment-systems/sbp.svg';
import privatBankIcon from '@assets/payment-systems/privatbank.svg';
import simImg from '@assets/payment-systems/sim12.png';
import vsibankiImg from '@assets/payment-systems/vsi-banki.png';
import bybitImg from '@assets/payment-systems/bybit-logo.svg';
import payeerImg from '@assets/payment-systems/logo-payeer.png';
import binanceImg from '@assets/payment-systems/binance-logo.svg';
import paypalImg from '@assets/payment-systems/logo-paypal.png';
import mirImg from '@assets/payment-systems/logo-mir.png';
import skrill from '@assets/payment-systems/logo-skrill.svg';
import payseraImg from '@assets/payment-systems/1_Paysera logo for light background.png';
import westernunion from '@assets/payment-systems/logo-western-union.png';
import advcashImg from '@assets/payment-systems/advcash-logo.svg';
import tetherLogo from '@assets/payment-systems/tether-logo.svg';
import humoLogo from '@assets/payment-systems/humo-logo.svg';
import sepaLogo from '@assets/payment-systems/sepa-logo.com.svg';
import m10Logo from '@assets/payment-systems/m10-logo.svg';
import betatransferLogo from '@assets/payment-systems/betatransfer-logo.svg';
import uzcardImg from '@assets/payment-systems/uzcard-logo.png';
import eripImg from '@assets/payment-systems/erip-logo.png';
import sberPay from '@assets/payment-systems/SberPay.png';
import mpessaImg from '@assets/payment-systems/M-PESA-logo.png';


// const iconsComponents: Record<string, string> = {
//   visa: visaIcon,
//   mastercard: mastercardIcon,
//   qiwi: qiwiIcon,
//   mobile: simImg,
//   webmoney: webmoneyIcon,
//   umoney: umoneyIcon,
//   operator: operatorIcon,
//   sbp: sbpIcon,
//   privat: privatBankIcon,
//   vsibanki: vsibankiImg,
//   bybit: bybitImg,
//   payeer: payeerImg,
//   binance: binanceImg,
//   paypal: paypalImg,
//   cardmir: mirImg,
//   skrill: skrill,
//   paysera: payseraImg,
//   westernunion: westernunion,
//   advcash: advcashImg,
//   tether: tetherLogo,
//   humo: humoLogo,
//   sepa: sepaLogo,
//   m10: m10Logo,
//   betatransfer: betatransferLogo,
//   uzcard: uzcardImg,
//   erip: eripImg,
//   sberPay: sberPay,
// };


export const methodIcons: Record<string, string[]> = {
  'card': [visaIcon, mastercardIcon, mirImg],
  'card_byn': [visaIcon, mastercardIcon],
  'card_azn': [visaIcon, mastercardIcon],
  'card_kgs': [visaIcon, mastercardIcon],
  'card_uah_200': [visaIcon, mastercardIcon],
  'card_aifory': [visaIcon, mastercardIcon],
  'card_aifory_sng': [visaIcon, mastercardIcon],
  'uzcard': [visaIcon, mastercardIcon],
  'card_uzs': [uzcardImg, humoLogo],
  'qiwi': [qiwiIcon],
  'mobile': [simImg],
  'webmoney': [webmoneyIcon],
  'umoney': [umoneyIcon],
  'operator': [operatorIcon],
  'card_kzt': [visaIcon, mastercardIcon],
  'card_uah': [visaIcon, mastercardIcon],
  'card_paycos': [visaIcon, mastercardIcon],
  'sbp': [sbpIcon],
  'card_aifory_sbp': [sbpIcon],
  'fps_key': [sbpIcon],
  'p2psbp': [sbpIcon],
  'card_uah_1000': [privatBankIcon, vsibankiImg],
  'bybit': [bybitImg],
  'payeer': [payeerImg],
  'binance': [binanceImg],
  'paypal': [paypalImg],
  'cardmir': [mirImg],
  'mir': [mirImg],
  'skrill': [skrill],
  'paysera': [payseraImg],
  'westernunion': [westernunion],
  'advcash': [advcashImg],
  'usdt': [tetherLogo],
  'usdtbep20': [tetherLogo],
  'usdt_withdrawal': [tetherLogo],
  'aifory_USDTBEP': [tetherLogo],
  'aifory_USDTBSC': [tetherLogo],
  'aifory_USDTTRC': [tetherLogo],
  'aifory_USDTERC': [tetherLogo],
  'betatransfer': [betatransferLogo],
  'sepa': [sepaLogo],
  'm10': [m10Logo],
  'humo': [humoLogo],
  'erip': [eripImg],
  'p2pcard': [visaIcon, mastercardIcon, mirImg],
  'sber_pay': [sberPay],
  'card_aifory_sberpay': [sberPay],
  'mpesa': [mpessaImg],
};
