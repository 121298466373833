import { bemCn } from '@shared/utils/helpers/bem-cn';
import { TextFieldControlled } from '@/shared/components/text-field/text-field';
import Button from '@shared/components/button/button';
import { useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Loader from '@/shared/components/loader/loader';
import { type IPromocodeError } from '@/modules/user/@x/promocodes';
import { useTranslation } from 'react-i18next';
import './promocode-activate-form.scss';

type FormData = {
  promocode: string;

}

const b = bemCn('promocode-activate-form');

const schema = yup
  .object({
    promocode: yup.string().required('Введите промокод'),
  })
  .required();

export interface PromocodeActivateFormProps {
  className?: string;
  onSubmit?: (data: FormData) => void;
  loading?: boolean;
  inverted?: boolean;
  error?: IPromocodeError;
}

const PromocodeActivateForm: FC<PromocodeActivateFormProps> = ({
  className,
  onSubmit = () => undefined,
  loading,
  error,
  inverted
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setError } = useForm<FormData>({
    defaultValues: { promocode: '' },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (error?.root) {
      setError('promocode', { message: error?.root });
    }
    if (error?.promocode) {
      setError('promocode', { message: error?.promocode });
    }
  }, [error, setError]);

  return (
    <form className={b({ loading }, className)} onSubmit={(handleSubmit(onSubmit))}>
      {loading && <Loader className={b('spinner')} />}
      <TextFieldControlled className={b('field')}
        type='text'
        name='promocode'
        id="promocode"
        label={t('promocodes.enter-promocode')}
        placeholder='xxxxxxxxx'
        control={control}
        inverted={inverted}
        disabled={loading}
      />
      <Button className={b('submit-btn')} type='submit' disabled={loading}>
        {t('promocodes.activate.title', 'Активация промокода')}
      </Button>
    </form>
  );
};


export default PromocodeActivateForm;
