import { Link } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg, { AvailableIcon } from '@/shared/components/icon-svg/icon-svg';
import { useAppSelector } from '@/shared/hooks';
import { getUseRole, type UserRole } from '@/modules/user/@x/profile';
import { getNewNotificationsCount } from '@/modules/notifications';
import i18n from '@/shared/i18n/i18n';
import { AppFeature, SUPPORT_LINK } from '@/app-config';
import './profile-nav.scss';

type TProfileNavItem = {
  id: string;
  title: string;
  desc?: string;
  icon: AvailableIcon;
  link?: string;
  onClick?: () => void;
  counter?: number;
  disabled?: boolean;
}

const getItems = (newCount?: number): TProfileNavItem[] => ([
  {
    id: 'notifications',
    title: i18n.t('profile.nav.notifications', 'Уведомления'),
    desc: i18n.t('profile.nav.notifications-description', 'Важная информация, которую мы вам присылаем'),
    icon: 'bell',
    link: '/profile/notifications',
    counter: newCount,
  },
  {
    id: 'settings',
    title: i18n.t('profile.nav.settings', 'Настройки'),
    desc: i18n.t('profile.nav.settings-description', 'Возможность скрыть баланс и отредактировать личные данные'),
    icon: 'settings',
    link: '/profile/settings'
  },
  {
    id: 'bets-history',
    title: i18n.t('profile.nav.bet-history', 'История ставок'),
    desc: i18n.t('profile.nav.bet-history-description', 'История всех ставок'),
    icon: 'coupon',
    link: '/bets-history',
    disabled: AppFeature.DISABLE_SPORTBOOK,
  },
  {
    id: 'promocodes',
    title: i18n.t('profile.nav.promo-codes', 'Промокоды'),
    desc: i18n.t('profile.nav.promo-codes-description', 'Активация промокода и история активации промокодов'),
    icon: 'coupon',
    link: '/profile/promocodes'
  },
  {
    id: 'transactions',
    title: i18n.t('profile.nav.transactions', 'Детализация'),
    desc: i18n.t('profile.nav.transactions-description', 'Все операции, что повлияли на изменение баланса'),
    icon: 'settings',
    link: '/profile/transactions'
  },
  {
    id: 'withdrawals',
    title: i18n.t('profile.nav.withdrawals', 'Вывод'),
    desc: i18n.t('profile.nav.withdrawals-description', 'При выборе определенного метода клиент вводит сумму и реквизиты для вывода'),
    icon: 'settings',
    link: '/profile/withdrawals'
  },
  {
    id: 'partner-program',
    title: i18n.t('profile.nav.partner-program', 'Партнёрская программа'),
    desc: i18n.t('profile.nav.partner-program-description', 'Управление реферальными ссылками и статистикой'),
    icon: 'stats',
    link: '/partner-program'
  },
  {
    id: 'support',
    title: i18n.t('profile.nav.support', 'Служба поддержки'),
    desc: i18n.t('profile.nav.support-description', 'Если у вас возникли вопросы, мы обязательно вам поможем'),
    icon: 'support',
    link: SUPPORT_LINK
  },
]);

const externalLinkProps = {
  target: '_blank',
  rel: 'noreferrer'
};

const checkExternalLink = (link?: string) =>
  /^(http|https):\/\//.test(link ?? '');


type ProfileNavProps = {
  className?: string;
}

const b = bemCn('profile-nav');

const parcePartnerLink = (navItem: TProfileNavItem, role?: UserRole) => {
  if (navItem.id === 'partner-program') {
    if (role === 'partner') {
      return '/partner-program/partner/stats';
    }
    if (role === 'manager') {
      return '/partner-program/manager/stats';
    }
  }

  return navItem.link ?? '';
};

const isShowPartnerLink = (linkId: string, role?: UserRole) => {
  if (linkId === 'partner-program') {
    if (role === 'manager' || role === 'partner') {
      return true;
    }

    return false;
  }

  return true;
};

const ProfileNav = ({ className }: ProfileNavProps) => {
  const userRole = useAppSelector(getUseRole);
  const newNotificationsCount = useAppSelector(getNewNotificationsCount);
  const items = getItems(newNotificationsCount).filter((item) => !item.disabled);

  return (
    <div className={b(null, className)}>
      <ul className={b('list')}>
        {items.map((item) => {
          // ? Надо придумать более понятный способ подменять обёртку
          const Tag = item.link ? Link : 'div';
          if (!isShowPartnerLink(item.id, userRole)) {
            return null;
          }

          return (
            <li className={b('item')} key={item.title}>
              <Tag className={b('item-link')}
                to={parcePartnerLink(item, userRole)}
                {...(checkExternalLink(item.link) && externalLinkProps)}
              >
                <div className={b('item-wrapper')}>
                  <IconSvg className={b('item-icon')} name={item.icon} />
                  <p className={b('item-title')}>{item.title}</p>
                  {!!item.counter && (
                    <p className={b('item-counter')}>{item.counter}</p>
                  )}
                </div>
                {!!item.desc && <p className={b('item-desc')}>{item.desc}</p>}
              </Tag>
            </li>
          );
        })}

      </ul>
    </div>
  );
};

export default ProfileNav;
