import AppModal from '@/shared/components/app-modal/app-modal';
import { useTranslation } from 'react-i18next';
import PhoneVerificationForm from '../phone-verification-form/phone-verification-form';


type PhoneConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const PhoneConfirmModal = ({ isOpen, onClose }: PhoneConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('confirm-phone.title')}
      description={t('confirm-phone.description')}
    >
      <PhoneVerificationForm />
    </AppModal>
  );
};

export default PhoneConfirmModal;
