import { bemCn } from '@shared/utils/helpers/bem-cn';
import BetsGroup from '../components/bets-group/bets-group';
import EventBoard from '../components/event-board/event-board';
import EventMeta from '../components/event-meta/event-meta';
import Button from '@shared/components/button/button';
import { memo, useEffect, useMemo, useState } from 'react';
import EventStats from '../components/event-stats/event-stats';
// import Loader from '@shared/components/loader/loader';
import EventStream from '../components/event-stream/event-stream';
import { TEventBetItem, TEventDetailed } from '../../../model/line';
import { useTranslation } from 'react-i18next';
import EventSubgamesTabs from '../components/event-subgames-tabs/event-subgames-tabs';
import { useToggleFavoriteMatches } from '@/modules/user/@x/line';
import { useAppSelector } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';
import './event-card-big.scss';

const MemoizedBetsGroup = memo(BetsGroup);


type EventCardBigProps = {
  className?: string;
  event: TEventDetailed;
  isBesLoading?: boolean;
  activeSugbameId: string;
  onSubgameChange: (subgameId: string) => void;
  onBetCLick: (bet: TEventBetItem) => void;
  currentEventId: string;
}

type InfoTabId = 'comands' | 'stream' | 'stats'

const b = bemCn('event-card-big');

const EventCardBig = ({
  className,
  event,
  onSubgameChange,
  // isBesLoading,
  activeSugbameId,
  onBetCLick,
  currentEventId
}: EventCardBigProps) => {
  const { t } = useTranslation();
  const [infoTab, setInfoTab] = useState<InfoTabId>('comands');
  const isAuth = useAppSelector(getIsAuth);
  const [isBetsOpened, setIsBetsOpened] = useState(false);
  const { toggleFavorite, isFavorite } = useToggleFavoriteMatches(event, currentEventId);
  const {
    mainId,
    sport,
    tournament,
    team1,
    team2,
    timer,
    // isFavorite,
    betsGroups,
    isLive,
    periodName,
    gameDesk,
    scoreTotal,
    scorePeriod,
    subgames,
    startDate,
    statList,
    streamUrl,
    isAvailable
  } = event;

  useEffect(() => {
    if (activeSugbameId !== mainId) {
      const isSubgameValid = subgames.some((item) =>
        item.id === activeSugbameId
      );
      if (!isSubgameValid) {
        onSubgameChange(mainId);
      }
    }
  }, [subgames, activeSugbameId, mainId, onSubgameChange]);

  const isStatsEmpty = !statList || statList.length === 0;

  const handleFavoriteClick = (newValue: boolean) => {
    console.log(newValue);
    if (isAuth) {
      if (isFavorite || isAvailable) {
        toggleFavorite();
      }
    }
  };

  const memoizedBetsGroups = useMemo(() => betsGroups.map((item) => (
    <MemoizedBetsGroup className={b('bets-group')}
      key={item.id + activeSugbameId}
      name={item.name}
      bets={item.bets}
      onBetClick={onBetCLick}
      isOpened={isBetsOpened}
    />
  )), [betsGroups, onBetCLick, activeSugbameId, isBetsOpened]);

  return (
    <div className={b(null, className)}>
      <div className={b('top-controls')}>
        {(streamUrl || !isStatsEmpty) && (
          <Button className={b('top-controls-item')}
            variant={infoTab === 'comands' ? 'primary' : 'secondary'}
            onClick={() => setInfoTab('comands')}
          >
            {t('top-events-widget.teams', 'Команды')}
          </Button>
        )}

        {streamUrl && (
          <Button className={b('top-controls-item')}
            variant={infoTab === 'stream' ? 'primary' : 'secondary'}
            onClick={() => setInfoTab('stream')}
          >
            {t('event.broadcast', 'Трансляция')}
          </Button>
        )}

        {!isStatsEmpty && (
          <Button className={b('top-controls-item')}
            variant={infoTab === 'stats' ? 'primary' : 'secondary'}
            onClick={() => setInfoTab('stats')}
          >
            {t('event.statistics', 'Статистика')}
          </Button>
        )}
      </div>

      {infoTab === 'comands' && (
        <div className={b('main-info')}>
          <EventMeta className={b('meta')}
            sportName={sport.name}
            tournamentName={tournament.name}
            isFavorite={isFavorite}
            period={gameDesk}
            isLive={isLive}
            isAvailable={isAvailable}
            gameStart={startDate}
            onFavoriteClick={handleFavoriteClick}
          />
          <EventBoard className={b('board')}
            team1Name={team1.name}
            team1Logo={team1.icon}
            team2Name={team2.name}
            team2Logo={team2.icon}
            totalScore={scoreTotal}
            periodsScore={scorePeriod}
            period={periodName}
            isLive={isLive}
            timer={timer}
          />
        </div>
      )}
      {infoTab === 'stream' && streamUrl && (
        <EventStream
          className={b('stream')}
          sportName={sport.name}
          torunamentName={tournament.name}
          date={startDate}
        />
      )}
      {infoTab === 'stats' && !isStatsEmpty && (
        <EventStats className={b('stats')}
          stats={statList}
          team1={team1.name}
          team2={team2.name}
        />
      )}

      {isAvailable && (
        <div className={b('bets')}>
          <EventSubgamesTabs className={b('subgames')}
            subgames={subgames}
            onSubgameChange={onSubgameChange}
            activeSugbameId={activeSugbameId}
            onToggleClick={() => setIsBetsOpened(!isBetsOpened)}
          />
          <div className={b('bets-list')}>
            {/* {isBesLoading && <Loader className={b('bets-loader')} />} */}
            {memoizedBetsGroups}
          </div>
        </div>
      )}

    </div>
  );
};

export default EventCardBig;
