import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useAppSelector } from '@/shared/hooks';
import {
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
  getUserMainData,
  type TgNotifications,
} from '@/modules/user';
// import LoaderCenter from '@/shared/components/loader-center/loader-center';

import './telegram-notifications.scss';

const b = bemCn('telegram-notifications');

type TelegramNotificationsProps = {
  className?: string;
  inverted?: boolean;
}

const TelegramNotifications = ({ className, inverted }: TelegramNotificationsProps) => {
  const { t } = useTranslation();
  const userMainData = useAppSelector(getUserMainData);
  const { data: userSettings } = useGetUserSettingsQuery();
  const [updateUserSettings, { isLoading: isUpdateSettingLoading }] =
    useUpdateUserSettingsMutation();

  useEffect(() => {
    const shouldResetTogglers = !!userMainData && !!userSettings
      && !userMainData.telegramVerified
      && Object.values(userSettings.notifications).some((item) => item);

    if (shouldResetTogglers) {
      updateUserSettings({
        ...userSettings,
        notifications: {
          betResults: false,
          withdrawals: false,
          notifications: false,
        }
      });
    }
  }, [userMainData, userSettings]);

  const isTogglesDisabled = !userSettings
    || !userMainData?.telegramVerified
    || isUpdateSettingLoading;

  const isResultsEnabled = !!userSettings?.notifications?.betResults;
  const isWithdrawalsEnabled = !!userSettings?.notifications?.withdrawals;
  const isNotificationsEnabled = !!userSettings?.notifications?.notifications;

  const handleToggles = (value: Partial<TgNotifications>) => {
    if (!isTogglesDisabled) {
      updateUserSettings({
        ...userSettings,
        notifications: {
          ...userSettings.notifications,
          ...value,
        }
      });
    }
  };

  return (
    <div className={b({ inverted }, className)}>
      <h2 className={b('title')}>
        {t('settings.telegram-notifications', 'Уведомления в Telegram')}
      </h2>

      {!userMainData?.telegramVerified && (
        <p className={b('is-not-verified')}>{t('settings.is-not-verified')}</p>
      )}

      {!!userSettings && (
        <div className={b('btns')}>
          <button
            className={b('btn', { active: isResultsEnabled })}
            type='button'
            onClick={() => handleToggles({ betResults: !isResultsEnabled })}
            disabled={isTogglesDisabled}
          >
            <span className={b('btn-text')}>
              {t('settings.bet-results', 'Результаты ставок')}
            </span>
            <span className={b('btn-toggler')}></span>
          </button>

          <button
            className={b('btn', { active: isWithdrawalsEnabled })}
            type='button'
            onClick={() => handleToggles({ withdrawals: !isWithdrawalsEnabled })}
            disabled={isTogglesDisabled}
          >
            <span className={b('btn-text')}>
              {t('settings.withdrawals', 'Статусы заявок на вывод')}
            </span>
            <span className={b('btn-toggler')}></span>
          </button>

          <button
            className={b('btn', { active: isNotificationsEnabled })}
            type='button'
            onClick={() => handleToggles({ notifications: !isNotificationsEnabled })}
            disabled={isTogglesDisabled}
          >
            <span className={b('btn-text')}>
              {t('settings.admin-notifications', 'Уведомления от администрации')}
            </span>
            <span className={b('btn-toggler')}></span>
          </button>
        </div>
      )}

    </div>
  );
};

export default TelegramNotifications;
